import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { onLinkOrButtonClicked } from 'utils/amplitude';

const useAttachAmplitudeClickEvents = (querySelector: string) => {
  const router = useRouter();

  const excludeDuplicateLogging = (link: Element) => {
    const dataEventsToExclude: Array<string> = [
      'click_focus_search_bar',
      'Consumer_Search_Map-Location',
      'click_search_recommended',
      'search_fullscreen_toggle',
      'click_search_near_me',
      'click_search_result',
    ];
    const dataEvent = link.getAttribute('data-event') || '';

    if (dataEventsToExclude.includes(dataEvent)) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (!querySelector) return;
    const parentNode = document.querySelector(querySelector);
    if (!parentNode) return;

    setTimeout(() => {
      parentNode
        ?.querySelectorAll(
          'a, button, input[type=checkbox], input[type=radio], [data-event]'
        )
        .forEach((link: Element) => {
          if (excludeDuplicateLogging(link)) {
            link.addEventListener('click', onLinkOrButtonClicked, true);
          }
        });
    }, 700);

    return () => {
      parentNode
        .querySelectorAll(
          'a, button, input[type=checkbox], input[type=radio], [data-event]'
        )
        .forEach((link: Element) =>
          link.removeEventListener('click', onLinkOrButtonClicked, true)
        );
    };
  }, [querySelector, router]);
};

export default useAttachAmplitudeClickEvents;
