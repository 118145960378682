import React from 'react';

import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import AppButtonLink from 'components/AppButton/AppButtonLink';
import AppModal from 'components/AppModal/AppModal';

import styles from './styles/ClaimFormSuccessModal.module.scss';

// import { useRouter } from 'next/router';

export interface ClaimFormSuccessModalProps {
  className?: string;
}

export const ClaimFormSuccessModal: React.FC<ClaimFormSuccessModalProps> = ({
  className,
}: ClaimFormSuccessModalProps) => {
  // const router = useRouter();

  // const cgSlug = router.query.slug;

  return (
    <AppModal
      type="claim-success"
      className={`${styles['claim-form-success']} ${className}`}
      disableClose
      padded={false}
      content={
        <>
          <div className={styles['claim-form-success__text']}>
            <h2 className={styles['claim-form-success__title']}>
              {/* Thanks for making it official. */}
              You&apos;re on the Waitlist
            </h2>
            <img
              className={styles['claim-form-success__image']}
              srcSet={`${PUBLIC_ASSETS_PATH}/assets/images/claim/success.jpg, ${PUBLIC_ASSETS_PATH}/assets/images/claim/success@2x.jpg 2x`}
              src={`${PUBLIC_ASSETS_PATH}/assets/images/claim/success.jpg`}
              alt="Two hands hold flags while waving them. One flag is green and says 'Good Job', while the other is blue with a smiley face."
            />
            {/* <p>You’ve successfully claimed your listing on The Dyrt.</p> */}

            <p>
              Due to overwhelming popularity on The Dyrt, we will be onboarding
              new campgrounds in the new year and will reach out to you then.
              Happy camping.
            </p>
          </div>

          <div className={styles['claim-form-success__footer']}>
            {/* <AppButtonLink
              className={styles['claim-form-success__link']}
              path={`/manager/campgrounds/${cgSlug}`}
              label="Start Exploring"
              dataEvent="click_claim-success-modal"
            /> */}

            <AppButtonLink
              className={styles['claim-form-success__link']}
              path={'/'}
              label="Close"
              dataEvent="click_claim-success-modal"
            />
          </div>
        </>
      }
    />
  );
};

export default ClaimFormSuccessModal;
