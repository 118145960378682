import {
  CAMPGROUND_AMENITIES,
  CAMPGROUND_VEHICLE_AMENITIES,
} from 'constants/campground';

const onlyOnCampground = (
  item: CampgroundAmenities[number] | CampgroundVehicleAmenities[number],
  campground: CampgroundModel
) => {
  return campground[item.attribute];
};

const applyFunctionalLabels = (
  item: CampgroundAmenities[number] | CampgroundVehicleAmenities[number]
) => {
  return { label: item.label, icon: item.icon };
};

export const getCampgroundAttributes = (campground: CampgroundModel) => {
  return CAMPGROUND_AMENITIES.filter((item) =>
    onlyOnCampground(item, campground)
  ).map((item) => applyFunctionalLabels(item));
};

export const getVehicleAttributes = (campground: CampgroundModel) => {
  return CAMPGROUND_VEHICLE_AMENITIES.filter((item) =>
    onlyOnCampground(item, campground)
  ).map((item) => applyFunctionalLabels(item));
};

// for amplitude
export const getCampgroundEventProperties = (
  campground: CampgroundOrLocationModel
): CampgroundEventProperties => ({
  'accommodation type':
    'accommodationTypes' in campground ? campground.accommodationTypes : '',
  'campground booking type': campground.bookingMethod,
  'campground id': campground.id,
  'campground name': campground.name,
  city: 'city' in campground ? campground.city : '',
  'pro discount program': campground.partner || '',
  state: campground.regionName,
});

// gets list of strings for google ads targeting
export const campgroundFeatureNames = (campground: CampgroundModel) => {
  const names = [
    ...(campground.accommodationTypes || []),
    ...(campground.accessTypes || []),
  ];
  CAMPGROUND_AMENITIES.forEach((item) => {
    if (campground[item.attribute]) {
      names.push(item.label);
    }
  });
  CAMPGROUND_VEHICLE_AMENITIES.forEach((item) => {
    if (campground[item.attribute]) {
      names.push(item.label);
    }
  });
  return names;
};

// for google ads targeting
export const campgroundPropertyTypes = (campground: CampgroundModel) => {
  const propertyTypes = [] as string[];
  if (campground.claimable) {
    propertyTypes.push('Claimable');
  }
  if (campground.claimed) {
    propertyTypes.push('Claimed');
  }
  if (campground.bookable) {
    propertyTypes.push('Bookable');
  }
  return propertyTypes;
};
