export const openPopupWindow = (url: string, title: string) => {
  const screenLeft = window.screenLeft ?? window.screenX;
  const screenTop = window.screenTop ?? window.screenY;

  const width = 700;
  const height = 800;

  const left = width / 2 + screenLeft;
  const top = 200 + screenTop;

  const newWindow = window.open(
    url,
    title,
    `width=${width},height=${height},top=${top},left=${left}`
  );

  newWindow?.focus();

  return newWindow;
};
