import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import * as amplitude from '@amplitude/analytics-browser';

import { FILTER_SELECT } from 'constants/amplitude';

import AppDropdown, { AppDropdownProps } from 'components/AppInput/AppDropdown';

type AppFormDropdownProps<
  T,
  L extends string,
  V extends string
> = FormInputProps<T> & Omit<AppDropdownProps<L, V>, 'onSelect'>;

function AppFormDropdown<
  T = Record<string, unknown>,
  L extends string = string,
  V extends string = string
>(props: AppFormDropdownProps<T, L, V>): React.ReactElement {
  const { name, validation, className } = props;

  const { formState, control } = useFormContext();

  const { errors } = formState || {};

  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field: { onChange, ref, value, name } }) => {
        return (
          <AppDropdown
            inputRef={ref}
            {...props}
            onSelect={(selectedValue) => {
              amplitude.track(FILTER_SELECT, {
                'filter name': name,
                'filter value': selectedValue,
              });
              onChange(selectedValue);
            }}
            error={errors[name]?.message}
            value={value}
            className={className}
          />
        );
      }}
    />
  );
}

export default AppFormDropdown;
