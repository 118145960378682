import React from 'react';
import Image from 'next/image';

import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import { useOverlay } from 'hooks/useOverlayContext';

import AppButtonLink from 'components/AppButton/AppButtonLink';
import AppModal from 'components/AppModal/AppModal';

import styles from './styles/LocationEditSuccessModal.module.scss';

export const LocationEditSuccessModal: React.FC = () => {
  const overlay = useOverlay<LocationEditSuccessOverlay>(
    'location-edit-success'
  );

  const { locationName, locationPath } = overlay || {};

  return (
    <AppModal
      type="location-edit-success"
      className={`${styles['edit-success']} `}
      disableClose
      padded={false}
      fullScreenMobile={false}
      content={
        <>
          <div className={styles['edit-success__text']}>
            <h2 className={styles['edit-success__title']}>
              Thank you for your insights!
            </h2>
            <Image
              className={styles['edit-success__image']}
              src={`${PUBLIC_ASSETS_PATH}/assets/images/campground/Campground_success@2x.png`}
              alt="Two hands hold flags while waving them. One flag is green and says 'Good Job', while the other is blue with a smiley face."
              width={200}
              height={200}
            />
            <p>
              Thank you for editing {locationName}! The Dyrt team will verify
              this information before updating.
            </p>
          </div>

          <div className={styles['edit-success__footer']}>
            <AppButtonLink
              className={styles['edit-success__link']}
              path={locationPath}
              label="Done"
              dataEvent="click_location-edit-success-modal"
            />
          </div>
        </>
      }
    />
  );
};

export default LocationEditSuccessModal;
