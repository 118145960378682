import React from 'react';
import classNames from 'classnames/bind';

import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/AppStarRating.module.scss';

const cx = classNames.bind(styles);
export interface AppStarRatingProps {
  rating: number;
  size?: 'sm' | 'md' | 'lg' | 'xxl';
  onClick?: (rating: number) => void;
  className?: string;
  onMouseOver?: (rating: number) => void;
  onMouseLeave?: () => void;
}

export const AppStarRating: React.FC<AppStarRatingProps> = ({
  rating,
  size,
  onClick,
  className,
  onMouseOver,
  onMouseLeave,
}: AppStarRatingProps) => {
  const starRatingClassNames = cx({
    'star-rating': true,
    'star-rating--is-one': Math.round(rating || 0) === 1,
    'star-rating--is-two': Math.round(rating || 0) === 2,
    'star-rating--is-three': Math.round(rating || 0) === 3,
    'star-rating--is-four': Math.round(rating || 0) === 4,
    'star-rating--is-five': Math.round(rating || 0) === 5,
    'star-rating--is-sm': size === 'sm',
    'star-rating--is-lg': size === 'lg' || size === 'xxl',
    'star-rating--is-xxl': size === 'xxl',
  });

  const starBoxes = [];

  for (let rating = 1; rating < 6; rating++) {
    starBoxes.push(
      <AppIcon
        key={rating}
        classNameWrapper={styles['star-rating__box']}
        classNameSvg={[styles['star-rating__star']]}
        icon="star"
        onClick={onClick ? () => onClick(rating) : undefined}
        dataEvent={`click_star_rating_${rating}`}
        onMouseOver={onMouseOver ? () => onMouseOver(rating) : undefined}
      />
    );
  }

  return (
    <div
      className={`${starRatingClassNames} ${className}`}
      onMouseLeave={onMouseLeave}
    >
      {starBoxes}
    </div>
  );
};

export default AppStarRating;
