import React from 'react';
import { useWatch } from 'react-hook-form';

import useAppFormInput from 'hooks/useFormInput';

import AppInputCheckbox, {
  AppInputCheckboxProps,
} from 'components/AppInput/AppInputCheckbox';

type AppFormInputCheckboxProps<T> = FormInputProps<T> &
  Omit<AppInputCheckboxProps, 'onChange' | 'isChecked'>;

function AppFormInputCheckbox<T = Record<string, unknown>>(
  props: AppFormInputCheckboxProps<T>
): React.ReactElement {
  const isChecked = useWatch({ name: props.name });
  return useAppFormInput<AppInputCheckboxProps, { isChecked: boolean }>(
    AppInputCheckbox,
    props,
    () => ({
      isChecked,
    })
  );
}

export default AppFormInputCheckbox;
