import React, { useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import classNames from 'classnames/bind';

import { CAMPGROUND_SAVED_TO_LIST } from 'constants/amplitude';

import { useCampgroundsSavedToLists } from 'contexts/campgroundsSavedToLists';
import { useUser } from 'contexts/currentUser';

import { getCampgroundEventProperties } from 'utils/campgroundFeatures';
import { createRecord, loadRecords } from 'utils/JSONAPIAdapter';

import useAuthenticationHeader from 'hooks/useAuthenticationHeaders';
import useMountEffect from 'hooks/useMountEffect';
import { useOverlay, useOverlayContext } from 'hooks/useOverlayContext';

import AppButton from 'components/AppButton/AppButton';
import AppInputText from 'components/AppInput/AppInputText';
import AppModal from 'components/AppModal/AppModal';

import SaveList from './_SaveList';

import styles from './styles/AppModal.module.scss';

const cx = classNames.bind(styles);

export const SaveModal: React.FC = () => {
  const { close } = useOverlayContext<{
    campground: CampgroundOrLocationModel;
  }>();

  const overlay = useOverlay<CampgroundSaveToListOverlay>(
    'save'
  ) as CampgroundSaveToListOverlay;

  const campground = overlay?.campground;
  let campgroundId = '';
  if (campground?.type === 'location-search-results') {
    const location = campground as LocationSearchResultModel;
    campgroundId = String(location.locationId);
  } else {
    campgroundId = campground?.id;
  }

  const currentUserContext = useUser();

  const currentUser = currentUserContext?.user;
  const { syncCampgroundsSavedToLists } = useCampgroundsSavedToLists();

  const [listFormActive, toggleListForm] = useState(false);
  const inputDefault = '';
  const [titleInput, setTitleInput] = useState(inputDefault);
  const [error, setError] = useState('');
  const [listsArray, setLists] = useState([] as ListModel[]);
  const sortedLists = listsArray.sort(function (a, b) {
    if (a.createdAt < b.createdAt) return 1;
    if (a.createdAt > b.createdAt) return -1;
    return 0;
  });

  const authenticationHeader = useAuthenticationHeader();

  useMountEffect(() => {
    fetchUserLists();
  });

  const ListFormClassNames = cx({
    'save-modal__list-form': true,
    'save-modal__list-form--is-active': listFormActive,
  });

  const InputClassNames = cx({
    'save-modal__name-input': true,
    'save-modal__name-input--has-error': error,
  });

  const ButtonsClassNames = cx({
    'save-modal__control-buttons': true,
    'save-modal__control-buttons--hide': listFormActive,
  });

  const closeListForm = (
    e: ButtonClickEvent | React.KeyboardEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    toggleListForm(false);
    setError('');
  };
  const openListForm = () => toggleListForm(true);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitleInput(event.target.value);
  };

  async function fetchUserLists() {
    const { data: listData, included: itemsIncluded } = await loadRecords<
      ListModel,
      ListItemModel
    >(`users/${currentUser?.id}/lists`, {
      sort: '-created-at',
      include: 'items',
      time: Date.now(),
    });

    if (Array.isArray(itemsIncluded)) {
      listData.forEach((list) => {
        list.items = itemsIncluded.filter((model): model is ListItemModel => {
          return model.type === 'list-items' && model.listId == list.id;
        });
      });
    } else {
      listData.forEach((list) => {
        list.items = [];
      });
    }

    setLists(listData);
  }

  async function createNewList(title: string): Promise<ListModel> {
    const newList: Partial<ListModel> = {
      title: title,
      type: 'lists',
    };
    const { data: listData } = await createRecord<ListModel>(
      newList,
      {},
      authenticationHeader
    );
    return listData;
  }

  async function createItem(list: ListModel): Promise<ListItemModel> {
    const { data: listItem } = await createRecord<ListItemModel>(
      {
        campgroundId,
        listId: list.id,
        type: 'list-items',
      },
      {},
      authenticationHeader
    );

    syncCampgroundsSavedToLists();
    fetchUserLists();

    const eventProperties = getCampgroundEventProperties(campground);
    amplitude.track(CAMPGROUND_SAVED_TO_LIST, eventProperties);

    window.dataLayer.push({
      event: 'saved_to_list',
      subjectName: campground.name,
    });

    return listItem;
  }

  async function validate(value: string) {
    if (!value.length) {
      setError('List name must be filled out');
      return false;
    }
    setError('');
    return true;
  }

  async function submitForm(
    e: ButtonClickEvent | React.KeyboardEvent<HTMLInputElement>
  ) {
    e.preventDefault();
    e.stopPropagation();
    const isValid = await validate(titleInput);
    if (isValid) {
      const newList = await createNewList(titleInput);
      const newItem = await createItem(newList);
      newList.items = [newItem];
      setLists([...listsArray, newList]);
      setTitleInput(inputDefault);
      closeListForm(e);
      setError('');
    }
  }

  function listenForEnter(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      submitForm(e);
    }
  }

  return (
    <AppModal
      type="save"
      padded={false}
      content={
        <div className={styles['save-modal']}>
          <div className={styles['save-modal__content']}>
            <h2 className={styles['save-modal__title']}>Save To List</h2>
            <p className={'m-t-xs'}>{campground.name}</p>
            <ul
              className={styles['save-modal__lists']}
              key={sortedLists?.length}
            >
              {sortedLists?.map((list) => (
                <SaveList
                  key={list.id}
                  list={list}
                  campgroundId={campgroundId}
                  fetchUserLists={fetchUserLists}
                  createListItem={createItem}
                />
              ))}
              {!sortedLists?.length && (
                <span>Create a new list to save this campground.</span>
              )}
            </ul>
          </div>
          <div className={styles['save-modal__footer']}>
            <div className={ButtonsClassNames}>
              <AppButton
                type={'button'}
                className={styles['save-modal__create-list-button']}
                label="Create New List"
                ghost={true}
                onClick={() => openListForm()}
                dataEvent={`click_save_modal_new_list`}
              />
              <AppButton
                type={'button'}
                className={styles['details__save-modal-buttons__done']}
                label="Done"
                onClick={close}
                dataEvent={`click_save_modal_done`}
              />
            </div>
            <div className={ListFormClassNames}>
              <form>
                <AppInputText
                  className={InputClassNames}
                  value={titleInput}
                  label="List Name"
                  name="lastName"
                  placeholder="list name"
                  onChange={onChange}
                  onKeyUp={listenForEnter}
                  error={error}
                  required={true}
                />
                <div className={styles['save-modal__list-form__buttons-row']}>
                  <AppButton
                    type={'button'}
                    className={styles['save-modal__list-form__cancel']}
                    label="Cancel"
                    ghost={true}
                    borderless={true}
                    onClick={closeListForm}
                    dataEvent={`click_save_modal_form_cancel`}
                  />
                  <AppButton
                    type={'submit'}
                    className={styles['save-modal__list-form__create']}
                    label="Create"
                    onClick={submitForm}
                    dataEvent={`click_save_modal_form_submit`}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default SaveModal;
