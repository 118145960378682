import React from 'react';
import { ReactSVG } from 'react-svg';

import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import styles from './styles/AppIcon.module.scss';

export interface AppIconProps {
  category?: string;
  classNameSvg?: string[] | null;
  classNameWrapper?: string;
  icon: string;
  title?: string;
  onClick?: (e: IconClickEvent) => void;
  dataEvent?: string;
  onMouseOver?: (e?: React.MouseEvent) => void;
  onMouseLeave?: (e?: React.MouseEvent) => void;
}

export const AppIcon: React.FC<AppIconProps> = ({
  category = 'icons',
  classNameSvg,
  classNameWrapper = '',
  icon,
  title,
  onClick,
  dataEvent,
  onMouseOver,
}: AppIconProps) => {
  return (
    <ReactSVG
      beforeInjection={(svg) => {
        svg.classList.add(styles['app-icon__svg']);
        const acceptableSVGClasses = classNameSvg?.filter(
          (svgClass) => svgClass && svgClass.length
        );
        if (acceptableSVGClasses && acceptableSVGClasses.length) {
          svg.classList.add(...acceptableSVGClasses);
        }
      }}
      className={`${styles['app-icon']} ${classNameWrapper} ${
        onClick ? styles['app-icon--clickable'] : ''
      }`}
      wrapper="span"
      src={`${PUBLIC_ASSETS_PATH}/assets/${category}/${icon}.svg`}
      onClick={onClick}
      title={title}
      data-event={dataEvent}
      onMouseOver={onMouseOver}
    />
  );
};

export default AppIcon;
