import React, { useEffect, useState } from 'react';
import { signIn, ClientSafeProvider, useSession } from 'next-auth/react';
import Router, { useRouter } from 'next/router';
import * as amplitude from '@amplitude/analytics-browser';
import classNames from 'classnames/bind';

import { ACCOUNT_SIGN_UP_ERROR } from 'constants/amplitude';

import { openPopupWindow } from 'utils/openPopupWindow';

import useAuthRedirect from 'hooks/useAuthRedirect';

import AppButton from 'components/AppButton/AppButton';
import AppForm from 'components/AppForm/AppForm';

import AppSignInForm from './AppSignInForm';

import styles from './styles/AppSignIn.module.scss';

const SITE_BASE = process.env.NEXT_PUBLIC_SITE_BASE;
const SIGNUP_DEFAULT = `${SITE_BASE}/signup`;

const cx = classNames.bind(styles);

type Form = {
  username: string;
  password: string;
};

export interface AppSignInProps {
  className?: string;
  noPadding?: boolean;
  providers: Record<string, ClientSafeProvider> | null;
  redirect?: boolean;
  redirectUrl?: string;
  onSignUpClick?: () => void;
  signUpLink?: string;
}

export const AppSignIn: React.FC<AppSignInProps> = ({
  className,
  noPadding,
  providers,
  redirect = true,
  redirectUrl,
  signUpLink,
  onSignUpClick,
}: AppSignInProps) => {
  const router = useRouter();
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const [signUp, setSignUp] = useState(signUpLink || SIGNUP_DEFAULT);
  const redirectProps = useAuthRedirect(redirect, redirectUrl);
  const { data: session } = useSession();

  const showAndSetErrors = (error: string | string[]) => {
    let errorMessage: string;
    if (error.includes('invalid_grant')) {
      errorMessage =
        'Your email address and/or password is incorrect. Please check them and try again.';
    } else {
      errorMessage = 'Could not sign in.';
    }
    setServerErrors([errorMessage]);
    amplitude.track(ACCOUNT_SIGN_UP_ERROR, { 'error type': errorMessage });
  };

  useEffect(() => {
    if (session && redirect) {
      Router.push(redirectProps.callbackUrl || '/');
    }
    if (router.query.error) {
      showAndSetErrors(router.query.error);
    }
    if (router.query.redirect) {
      const signUpUrl = new URL(signUpLink || SIGNUP_DEFAULT);
      signUpUrl.searchParams.set('redirect', router.query.redirect as string);
      setSignUp(signUpUrl.toString());
    }
  }, [redirect, redirectProps.callbackUrl, router.query, session, signUpLink]);

  const handleEmailSignIn: AppFormSubmitHandler<Form> = async (
    formState: Form
  ) => {
    // "username-login" matches the id for the credential
    setServerErrors([]);
    const data = await signIn('username-login', {
      ...formState,
      ...redirectProps,
    });

    if (!redirect && data?.error) {
      showAndSetErrors(data.error);
    }
  };

  const handleProviderSignIn = async (
    e: ButtonClickEvent,
    provider: ClientSafeProvider
  ) => {
    e.preventDefault();

    openPopupWindow(
      `${window.location.origin}/signin/${provider.id}${window.location.search}`,
      `The Dyrt - Sign in with ${provider.name}`
    );
  };

  const formClassNames = cx({
    'sign-in': true,
    'sign-in--has-padding': !noPadding,
  });

  return (
    <AppForm<Form>
      className={`${formClassNames} ${className}`}
      onSubmit={handleEmailSignIn}
      formOptions={{
        defaultValues: { username: router.query.username, password: '' },
      }}
      id="signInForm"
    >
      <AppSignInForm errors={serverErrors} />
      {providers && (
        <div className={styles['sign-in__providers']}>
          <span className={styles['sign-in__providers-divider']}>OR</span>
          {Object.values(providers).map((provider) => {
            if (
              provider.id !== 'username-login' &&
              provider.id !== 'googleonetap'
            )
              return (
                <AppButton
                  type={'button'}
                  key={provider.id}
                  className={`${styles['sign-in__button']} ${styles['sign-in__button--is-provider']}`}
                  label={`Continue with ${provider.name}`}
                  onClick={(e) => handleProviderSignIn(e, provider)}
                  icon={provider.id}
                  iconCategory="providers"
                  iconStart
                  ghost="dark"
                  background
                  dataEvent={`click_signin_${provider.name}`}
                />
              );
          })}
        </div>
      )}
      <p className={styles['sign-in__join']}>
        First time here?&nbsp;
        <AppButton
          label="Join Now"
          type="button"
          ghost
          borderless
          onClick={() => {
            if (onSignUpClick) {
              onSignUpClick();
            } else {
              router.replace(signUp, undefined, { shallow: true });
            }
          }}
          dataEvent="click_join_now"
        />
      </p>
    </AppForm>
  );
};

export default AppSignIn;
