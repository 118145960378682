import React, { useState } from 'react';
import classNames from 'classnames/bind';

import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/AppInput.module.scss';

const cx = classNames.bind(styles);

export interface AppInputPasswordProps {
  inputRef?: React.Ref<HTMLInputElement>;
  className?: string;
  name: string;
  label?: string;
  placeholder?: string;
  value?: string;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
  onKeyUp?(e: React.KeyboardEvent<HTMLInputElement>): void;
  error?: string | null;
  hideErrorMessage?: boolean;
  required?: boolean;
}

export const AppInputPassword: React.FC<AppInputPasswordProps> = ({
  inputRef,
  className,
  name,
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  onKeyUp,
  error,
  hideErrorMessage = false,
  required,
}: AppInputPasswordProps) => {
  const InputClassNames = cx({
    input__field: true,
    'input__field--has-toggle': true,
    'input__field--has-error': error,
  });

  const [showPassword, setShowPassword] = useState(false);

  return (
    <label className={`${styles.input} ${className}`}>
      <span className={styles['input__label']}>{label}</span>
      <div className={styles['input__field-container']}>
        <input
          ref={inputRef}
          className={InputClassNames}
          name={name}
          type={showPassword ? 'text' : 'password'}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          required={required}
        />
        <AppIcon
          classNameWrapper={styles['input__toggle']}
          icon={showPassword ? 'password_show' : 'password_hide'}
          onClick={() => setShowPassword(!showPassword)}
        />
      </div>
      {error && !hideErrorMessage && (
        <span className={styles['input__error-message']}>{error}</span>
      )}
    </label>
  );
};

export default AppInputPassword;
