import React, { InputHTMLAttributes } from 'react';
import { RefCallBack } from 'react-hook-form';
import classNames from 'classnames/bind';

import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/AppInput.module.scss';

const cx = classNames.bind(styles);

export interface AppInputTextProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | JSX.Element;
  inputRef?: React.Ref<HTMLInputElement> | RefCallBack;
  hideErrorMessage?: boolean;
  error?: string | null;
  name: string;
  disabled?: boolean;
  icon?: string;
  iconAction?(): void;
  iconDataEvent?: string;
  // TODO 38317 requiredForm is a intermediate prop as we migrate to AppForm
  requiredForm?: boolean;
  step?: string;
  dataTestId?: string;
}

export const AppInputText: React.FC<AppInputTextProps> = ({
  className,
  error,
  hideErrorMessage = false,
  inputRef,
  label,
  name,
  onBlur,
  onChange,
  placeholder,
  required,
  value,
  disabled,
  readOnly,
  icon,
  iconAction,
  iconDataEvent,
  type = 'text',
  requiredForm,
  step,
  dataTestId,
  ...inputProps
}: AppInputTextProps) => {
  const InputClassNames = cx({
    input__field: true,
    'input__field--has-error': error,
  });

  const LabelClassNames = cx({
    input__label: true,
    'input__label--disabled': disabled,
    'input__label--required': requiredForm,
  });

  return (
    <label className={`${styles.input} ${className}`}>
      {label && <span className={LabelClassNames}>{label}</span>}
      <input
        className={InputClassNames}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        ref={inputRef}
        required={required}
        step={step}
        type={type}
        value={value}
        disabled={disabled}
        readOnly={readOnly}
        data-testid={dataTestId}
        {...inputProps}
      />
      {icon && (
        <AppIcon
          classNameSvg={[styles['input__icon-svg']]}
          classNameWrapper={styles['input__icon-svg-wrapper']}
          icon={icon}
          onClick={iconAction}
          dataEvent={iconDataEvent}
        />
      )}
      {error && !hideErrorMessage && (
        <span className={styles['input__error-message']}>{error}</span>
      )}
    </label>
  );
};

export default AppInputText;
