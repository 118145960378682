import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';

import { useUser } from 'contexts/currentUser';

const SentryComponent = () => {
  const { user, loading } = useUser();

  useEffect(() => {
    if (!loading) {
      if (user) {
        Sentry.setUser({
          id: user.id,
          email: user.email,
        });
      } else {
        Sentry.configureScope((scope) => scope.setUser(null));
      }
    }
  }, [user, loading]);
  return null;
};

export default SentryComponent;
