import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';

import { CANCELLATION_REASONS, OTHER } from 'constants/cancellationReasons';

import AppFormBoxedRadio from 'components/AppForm/AppFormBoxedRadio';
import AppFormDropdown from 'components/AppForm/AppFormDropdown';
import AppFormInputText from 'components/AppForm/AppFormInputText';
import AppFormTextArea from 'components/AppForm/AppFormTextArea';

import styles from '../styles/AppFormCollection.module.scss';

const cancellationReasonOptions = CANCELLATION_REASONS.map(
  (reason: string) => ({
    value: reason,
    label: reason,
  })
).concat({ value: OTHER, label: OTHER });

export interface CancelReservationsFormProps {
  reservation: ReservationModel;
  setShowSubmitButton: (show: boolean) => void;
}

export const CancelReservationsForm: React.FC<CancelReservationsFormProps> = ({
  reservation,
  setShowSubmitButton,
}) => {
  const cancellationReason = useWatch({ name: 'cancellationReason' });
  const otherReason = useWatch({ name: 'otherReason' });

  const otherReasonRequired =
    cancellationReason &&
    !CANCELLATION_REASONS.find((reason) => reason === cancellationReason);

  useEffect(() => {
    if (cancellationReason) {
      setShowSubmitButton(otherReasonRequired ? !!otherReason : true);
    }
  }, [
    cancellationReason,
    setShowSubmitButton,
    otherReasonRequired,
    otherReason,
  ]);

  return (
    <>
      <section className={styles['section--small']}>
        <h3 className={styles['section__title-small']}>
          Reason for cancellation
        </h3>
        <AppFormDropdown
          name="cancellationReason"
          options={cancellationReasonOptions}
          valueAccessor="value"
          labelAccessor="label"
          className={styles['section__column']}
          placeholder="Select one"
          requiredForm={true}
          label="(not shared with guest)"
        />
        {otherReasonRequired && (
          <div className={styles['section__row']}>
            <AppFormInputText
              name="otherReason"
              required
              label="Please specify other reason"
              validation={{
                pattern: {
                  message: 'Please enter a reason',
                  value: /\w{2,}/,
                },
              }}
            />
          </div>
        )}
      </section>
      <section className={styles['section--small']}>
        <h3 className={styles['section__title-small']}>Refund amount</h3>
        <div className={styles['section__row']}>
          {reservation.cancellationTitle && (
            <AppFormBoxedRadio
              name="cancelledBy"
              label={`${reservation.cancellationTitle} Policy Refund ($${reservation.refundAmount})`}
              value="customer"
              className={styles['section__column']}
              dataEvent={'cancelled_by_customer'}
            />
          )}
          <AppFormBoxedRadio
            name="cancelledBy"
            label={`Full Refund ($${reservation.total})`}
            value="manager"
            className={styles['section__column']}
            dataEvent={'cancelled_by_manager'}
          />
        </div>
      </section>
      <section className={styles['section--small']}>
        <h3 className={styles['section__title-small']}>Note to guest</h3>
        <p className={styles['section__message']}>(Sent via email)</p>
        <AppFormTextArea name="note" />
      </section>
    </>
  );
};

export default CancelReservationsForm;
