import React, { useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import * as amplitude from '@amplitude/analytics-browser';

import { WEB_PAGE_VIEW } from 'constants/amplitude';

import { hideCTAForHours } from 'utils/localStorage';

import useFetchProviders from 'hooks/useFetchProviders';
import { useOverlay, useOverlayContext } from 'hooks/useOverlayContext';

import AppModal from 'components/AppModal/AppModal';
import AppSignIn from 'components/AppSignIn/AppSignIn';
import AppSignUp from 'components/AppSignUp/AppSignUp';

import styles from './styles/SignInSignUpModal.module.scss';

export const SignInSignUpModal = () => {
  const overlay = useOverlay<SignInSignUpOverlay>('sign-in-sign-up');
  const { close } = useOverlayContext();
  const { data: session } = useSession();

  const { redirect, redirectUrl, authFormType, onLogin } = overlay || {};
  const [formType, setFormType] = useState(authFormType);

  const isSignIn = formType === 'sign-in';

  useEffect(() => {
    amplitude.track(WEB_PAGE_VIEW, {
      'page location': isSignIn ? '/signin' : '/signup',
      'page name': isSignIn ? 'Signin' : 'Signup',
      'page title': isSignIn ? 'Sign In | The Dyrt' : 'Join | The Dyrt',
    });
  }, [isSignIn]);

  useEffect(() => {
    if (session) {
      close();

      // Timeout to wait for the 600ms from the modal close functin in Context
      setTimeout(() => {
        onLogin?.();
      }, 900);
    }
  }, [session, close, onLogin]);

  useEffect(() => {
    hideCTAForHours(12, 'hide-see-in-app-until');
  }, []);

  const { providers } = useFetchProviders();

  return (
    <AppModal
      type="sign-in-sign-up"
      fullScreenMobile={false}
      className={styles['sign-in-sign-up-modal']}
      content={
        formType === 'sign-in' ? (
          <AppSignIn
            providers={providers}
            redirect={redirect}
            redirectUrl={redirectUrl}
            onSignUpClick={() => setFormType('sign-up')}
            noPadding
          />
        ) : (
          <AppSignUp
            providers={providers}
            redirect={redirect}
            redirectUrl={redirectUrl}
            onSignInClick={() => setFormType('sign-in')}
            noPadding
          />
        )
      }
    />
  );
};

export default SignInSignUpModal;
