import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useRouter } from 'next/router';

import { EMAIL_VALIDATION, NEW_PASSWORD_VALIDATION } from 'constants/inputs';

import AppButton from 'components/AppButton/AppButton';
import AppFormError from 'components/AppForm/AppFormError';
import AppFormInputPassword from 'components/AppForm/AppFormInputPassword';
import AppFormInputText from 'components/AppForm/AppFormInputText';
import AppIcon from 'components/AppIcon/AppIcon';
import _ProCheckoutV2SignupFormHeader from 'components/ProCheckoutV2Signup/_ProCheckoutV2SignupFormHeader';
import TermsAndPrivacyPolicy from 'components/TermsAndPrivacyPolicy/TermsAndPrivacyPolicy';

import styles from './styles/AppSignUp.module.scss';

interface AppSignUpFormProps {
  errors?: string[];
  handleSignInClick: () => void;
  handleBackClick: null | (() => void);
}

export const AppSignUpForm: React.FC<AppSignUpFormProps> = ({
  errors,
  handleSignInClick,
  handleBackClick,
}) => {
  const {
    trigger,
    formState: { isSubmitting },
  } = useFormContext();
  const { pathname } = useRouter();
  const v2 = pathname === '/signup/email';

  return (
    <>
      {v2 ? (
        <_ProCheckoutV2SignupFormHeader />
      ) : (
        <div className={styles['sign-up__header']}>
          {handleBackClick && (
            <AppIcon
              icon="arrow_left"
              classNameWrapper={styles['sign-up__back-arrow']}
              onClick={handleBackClick}
              dataEvent="click_back_arrow_to_sign_up_methods"
              title="Back to sign up methods"
            />
          )}
          <AppIcon
            icon="dyrt"
            title="dyrt logo"
            classNameSvg={[styles['sign-up__logo']]}
            classNameWrapper={styles['sign-up__logoContainer']}
          />
          <h1 className={styles['sign-up__title']}>Create your free account</h1>
        </div>
      )}

      <AppFormError errors={errors} className={styles['sign-up__error']} />
      <AppFormInputText
        name="name"
        className={styles['sign-up__field']}
        label="First and Last Name"
        onKeyUp={() => {
          return;
        }}
        validation={{
          required: 'Please enter a name.',
        }}
      />
      <AppFormInputText
        name="email"
        className={styles['sign-up__field']}
        label="Email"
        onKeyUp={() => {
          return;
        }}
        validation={EMAIL_VALIDATION}
      />
      <AppFormInputPassword
        name="password"
        className={styles['sign-up__field']}
        label="Password"
        onKeyUp={() => {
          return;
        }}
        validation={NEW_PASSWORD_VALIDATION}
      />

      <AppButton
        type={'submit'}
        className={`${styles['sign-up__button']} ${styles['sign-up__button--is-submit']}`}
        label="Join"
        disabled={isSubmitting ? 'loading' : false}
        onClick={() => {
          trigger();
        }}
        dataEvent="click_submit_signup"
      />
      <p
        className={`${styles['sign-up__sign-in']} ${
          v2 ? styles['sign-up__sign-in--v2'] : ''
        }`}
      >
        Already have an account?&nbsp;
        <AppButton
          label="Sign In"
          ghost
          borderless
          onClick={handleSignInClick}
          dataEvent="click_sign_in"
          type="button"
          className={styles['sign-up__sign-in-link']}
        />
      </p>

      <TermsAndPrivacyPolicy
        dataEvent="sign-in"
        className={v2 ? styles['sign-up__terms--v2'] : ''}
      />
    </>
  );
};

export default AppSignUpForm;
