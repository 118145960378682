import React from 'react';
import classNames from 'classnames/bind';

import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/AppAction.module.scss';

const cx = classNames.bind(styles);

export interface AppActionProps {
  type: 'button' | 'submit' | 'reset';
  className?: string;
  classNameIcon?: string;
  classNameSvg?: string;
  classNameLabel?: string;
  disabled?: boolean;
  icon: string;
  label?: string;
  onClick?(e: ButtonClickEvent): void;
  dataEvent: string;
  name?: string;
  transparentBackground?: boolean;
}

export const AppAction: React.FC<AppActionProps> = ({
  type,
  className,
  classNameIcon,
  classNameSvg,
  classNameLabel,
  dataEvent,
  disabled,
  icon,
  label,
  name,
  onClick,
  transparentBackground,
}: AppActionProps) => {
  const iconBackgroundClasses = cx({
    'action__icon-container': true,
    'action__icon-container--transparent': transparentBackground,
  });
  return (
    <button
      type={type}
      className={`${styles.action} ${className}`}
      disabled={disabled}
      onClick={onClick}
      data-event={dataEvent}
      name={name}
    >
      <AppIcon
        classNameWrapper={`${iconBackgroundClasses} ${classNameIcon}`}
        classNameSvg={[`${styles['action__icon']}`, `${classNameSvg}`]}
        icon={icon}
      />
      {!!label && (
        <span className={`${styles['action__label']} ${classNameLabel}`}>
          {label}
        </span>
      )}
    </button>
  );
};

export default AppAction;
