import React from 'react';

import styles from './styles/TermsAndPrivacyPolicy.module.scss';

const HOST = process.env.NEXT_PUBLIC_EMBER_HOST;

type TermsAndPrivacyPolicyProps = {
  dataEvent: string;
  excludeDescriptionText?: boolean;
  className?: string;
};

export const TermsAndPrivacyPolicy: React.FC<TermsAndPrivacyPolicyProps> = ({
  dataEvent,
  excludeDescriptionText,
  className = '',
}: TermsAndPrivacyPolicyProps) => {
  return (
    <p className={`${styles['policy__terms']} ${className}`}>
      {!excludeDescriptionText && (
        <span>By continuing to use The Dyrt, you agree to our </span>
      )}

      <span>
        <a
          href={`${HOST}/about/tacos`}
          target="_blank"
          rel="noreferrer"
          className={styles['policy__policy-link']}
          data-event={`click_${dataEvent}_terms_of_use`}
        >
          <span>Terms of Use</span>
        </a>
      </span>
      <span>
        {' '}
        and{' '}
        <a
          href={`${HOST}/about/privacy`}
          target="_blank"
          rel="noreferrer"
          className={styles['policy__policy-link']}
          data-event={`click_${dataEvent}_privacy_policy`}
        >
          <span>Privacy Policy</span>
        </a>
      </span>
    </p>
  );
};

export default TermsAndPrivacyPolicy;
