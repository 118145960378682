import React, { useContext, useReducer, useMemo, useEffect } from 'react';

import { useUser } from 'contexts/currentUser';

import {
  getMapLayersFromLocalStorage,
  getMapStyleFromLocalStorage,
  setMapLayersToLocalStorage,
  setMapStyleToLocalStorage,
} from 'utils/localStorage';

/**
 * Map style - stores the map style ('map' or 'satellite') and toggleMapStyle()
 * This holds the map styles for the entire site
 */
type MapStyleState = {
  style: MapStyleType;
  styleUrl: string;
  visibleLayers: MapLayer[];
  initialized: boolean;
};

type MapStyleContextValue = MapStyleState & {
  mapStyleDispatch: React.Dispatch<MapStyleAction>;
};

const MAPBOX_MAP_STYLE = process.env
  .NEXT_PUBLIC_MAPBOX_STYLE_STATIC_MAP as string;
const MAPBOX_SATELLITE_STYLE = process.env
  .NEXT_PUBLIC_MAPBOX_STYLE_SATELLITE as string;

const MapStyleContext = React.createContext<MapStyleContextValue | null>(null);

export const useMapStyle = () =>
  useContext(MapStyleContext) as MapStyleContextValue;

type MapStyleAction =
  | {
      type: 'SET_MAP_STYLE';
      payload: MapStyleType;
    }
  | {
      type: 'SET_MAP_LAYER';
      payload: MapLayer;
    }
  | {
      type: 'CLEAR_MAP_LAYERS';
    }
  | {
      type: 'INITIALIZE_STATE';
      payload: MapStyleState;
    };

const mapStyleReducer = (state: MapStyleState, action: MapStyleAction) => {
  switch (action.type) {
    case 'SET_MAP_STYLE':
      setMapStyleToLocalStorage(action.payload);

      return {
        style: action.payload,
        styleUrl:
          action.payload === 'map' ? MAPBOX_MAP_STYLE : MAPBOX_SATELLITE_STYLE,
        visibleLayers: state.visibleLayers,
        initialized: true,
      };
    case 'SET_MAP_LAYER':
      const newLayers = state.visibleLayers.includes(action.payload)
        ? state.visibleLayers.filter((layer) => layer !== action.payload)
        : [...state.visibleLayers, action.payload];

      setMapLayersToLocalStorage(newLayers);

      return {
        ...state,
        visibleLayers: newLayers,
      };
    case 'CLEAR_MAP_LAYERS':
      setMapLayersToLocalStorage([]);
      return {
        ...state,
        visibleLayers: [],
      };
    case 'INITIALIZE_STATE':
      return action.payload;
    default:
      return state;
  }
};

const defaultLayers = [] as MapLayer[];

const MapStyleProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const currentUserContext = useUser();

  const [mapStyleState, mapStyleDispatch] = useReducer(mapStyleReducer, {
    style: 'map' as const,
    styleUrl: MAPBOX_MAP_STYLE,
    visibleLayers: [],
    initialized: false,
  });

  const contextValue = useMemo(
    () => ({
      ...mapStyleState,
      mapStyleDispatch,
    }),
    [mapStyleDispatch, mapStyleState]
  );

  useEffect(() => {
    const activeLayers = getMapLayersFromLocalStorage() || [];
    const activeStyle = getMapStyleFromLocalStorage() || 'map';

    mapStyleDispatch({
      type: 'INITIALIZE_STATE',
      payload: {
        visibleLayers: currentUserContext.user?.pro
          ? activeLayers
          : defaultLayers,
        style: activeStyle as MapStyleType,
        styleUrl:
          activeStyle === 'map' ? MAPBOX_MAP_STYLE : MAPBOX_SATELLITE_STYLE,
        initialized: true,
      },
    });
  }, [mapStyleDispatch, currentUserContext.user]);

  useEffect(() => {
    if (currentUserContext.user && !currentUserContext.user.pro) {
      mapStyleDispatch({ type: 'CLEAR_MAP_LAYERS' });
    }
  }, [currentUserContext.user]);

  return (
    <MapStyleContext.Provider value={contextValue}>
      {children}
    </MapStyleContext.Provider>
  );
};

export default MapStyleProvider;
