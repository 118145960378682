import React, { MouseEventHandler } from 'react';
import classNames from 'classnames/bind';

import { skipInterstitalAdParam } from 'utils/googleAds';

import AppIcon from 'components/AppIcon/AppIcon';
import AppLink from 'components/AppLink/AppLink';

import styles from './styles/AppButton.module.scss';

const cx = classNames.bind(styles);
export interface AppButtonLinkProps {
  background?: boolean;
  borderless?: boolean;
  className?: string;
  ghost?: boolean | string;
  icon?: string;
  iconBefore?: boolean;
  iconCategory?: string;
  label: string;
  lightWeight?: boolean;
  path: string;
  pro?: boolean | string;
  pricing?: boolean | string;
  target?: string;
  dataEvent: string;
  iconClassName?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  nowrap?: boolean;
  fullWidth?: boolean;
  inline?: boolean;
  disabled?: boolean;
  skipInterstitalAd?: boolean;
}

export const AppButtonLink: React.FC<AppButtonLinkProps> = ({
  background,
  borderless,
  className,
  ghost,
  icon,
  iconBefore,
  iconCategory,
  label,
  lightWeight,
  path,
  pro,
  pricing,
  target,
  dataEvent,
  iconClassName,
  onClick,
  nowrap,
  fullWidth,
  inline,
  disabled,
  skipInterstitalAd,
}: AppButtonLinkProps) => {
  const buttonClassNames = cx({
    button: true,
    'button--is-ghost': ghost,
    'button--is-ghost--has-background': background,
    'button--is-ghost--is-action':
      (ghost && typeof ghost == 'boolean') || ghost === 'action',
    'button--is-ghost--is-dark': ghost === 'dark',
    'button--is-ghost--is-light': ghost === 'light',
    'button--is-ghost--is-pro': ghost === 'pro',
    'button--is-ghost--is-active': ghost === 'active',
    'button--is-ghost--is-borderless': borderless,
    'button--is-light-weight': lightWeight,
    'button--is-pro': pro,
    'button--is-pro-solid': pro === 'solid',
    'button--is-pro-gradient': pro === 'gradient',
    'button--is-pro--is-light': pro === 'light',
    'button--is-pricing': pricing,
    'button--is-pro--is-green': pro === 'green',
    'button--full-width': fullWidth,
    'button--inline': inline,
    'button--is-disabled': disabled,
  });

  const labelClassNames = cx({
    button__label: true,
    'button__label--nowrap': nowrap,
  });

  const targetBlank = target?.toLowerCase() === '_blank' ? 'noreferrer' : '';

  return (
    <AppLink href={path}>
      <a
        data-event={dataEvent}
        data-testid={dataEvent}
        className={`${buttonClassNames} ${className}`}
        target={target}
        rel={targetBlank}
        onClick={(e) => (disabled ? e.preventDefault() : onClick?.(e))}
        {...skipInterstitalAdParam(skipInterstitalAd)}
      >
        {iconBefore && icon && (
          <AppIcon
            classNameWrapper={styles['button__icon-container']}
            classNameSvg={[
              styles['button__icon'],
              !!iconClassName ? iconClassName : '',
            ]}
            icon={icon}
            category={iconCategory}
          />
        )}
        <span className={labelClassNames}>{label}</span>
        {!iconBefore && icon && (
          <AppIcon
            classNameWrapper={`${styles['button__icon-container']} ${iconClassName}`}
            classNameSvg={[
              styles['button__icon'],
              !!iconClassName ? iconClassName : '',
            ]}
            icon={icon}
            category={iconCategory}
          />
        )}
      </a>
    </AppLink>
  );
};

export default AppButtonLink;
