import React from 'react';

import useAppFormInput from 'hooks/useFormInput';

import AppInputText, {
  AppInputTextProps,
} from 'components/AppInput/AppInputText';

type AppFormInputTextProps<T> = FormInputProps<T> &
  Omit<AppInputTextProps, 'inputRef' | 'error' | 'onChange' | 'onBlur'>;

function AppFormInputText<T = Record<string, unknown>>(
  props: AppFormInputTextProps<T>
): React.ReactElement {
  // TODO 38317
  const { required, ...rest } = props;
  return useAppFormInput<AppInputTextProps>(AppInputText, {
    ...rest,
    requiredForm: required,
  });
}

export default AppFormInputText;
