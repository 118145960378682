import React from 'react';
import classNames from 'classnames/bind';

import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/AppButton.module.scss';

const cx = classNames.bind(styles);

export interface AppButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'disabled'> {
  type: 'button' | 'submit' | 'reset';
  background?: boolean;
  borderless?: boolean;
  ghost?: boolean | 'dark' | 'light' | 'action' | 'pro' | 'active' | 'warning';
  disabled?: boolean | 'loading' | 'submitted';
  disabledStyle?: boolean;
  caution?: boolean | string;
  icon?: string;
  iconBefore?: boolean;
  iconStart?: boolean;
  iconCategory?: string;
  iconClassName?: string;
  label: string;
  pro?: boolean | 'solid' | 'gradient' | 'green';
  submittedLabel?: string;
  dataEvent: string;
  nowrap?: boolean;
  fullWidth?: boolean;
  dataTestId?: string;
}

export const AppButton: React.FC<AppButtonProps> = ({
  background,
  borderless,
  className,
  disabled = false,
  disabledStyle,
  ghost,
  caution,
  icon,
  iconBefore,
  iconStart,
  iconCategory,
  iconClassName,
  label,
  onClick,
  pro,
  submittedLabel,
  dataEvent,
  type,
  form,
  id,
  nowrap,
  fullWidth,
  dataTestId,
  children,
}: AppButtonProps) => {
  const buttonClassNames = cx({
    button: true,
    [`button--is-ghost--is-${
      ghost && typeof ghost === 'boolean' ? 'action' : `${ghost}`
    }`]: !!ghost,
    'button--is-ghost': ghost,
    'button--is-ghost--has-background': background,
    'button--has-icon-start': iconStart,
    'button--is-borderless': !ghost && borderless,
    'button--is-ghost--is-borderless': ghost && borderless,
    'button--is-loading': disabled === 'loading',
    'button--is-submitted': disabled === 'submitted',
    'button--is-disabled': disabledStyle,
    'button--is-pro': pro,
    'button--is-pro-solid': pro === 'solid',
    'button--is-pro-gradient': pro === 'gradient',
    // TODO: Temp remove after interim onboarding
    'button--is-pro--is-green': pro === 'green',
    'button--caution': caution,
    'button--full-width': fullWidth,
  });

  const labelClassNames = cx({
    button__label: true,
    'button__label--nowrap': nowrap,
  });

  return (
    <button
      className={`${buttonClassNames} ${className}`}
      disabled={disabled !== false}
      onClick={onClick}
      data-event={dataEvent}
      type={type}
      form={form}
      id={id}
      aria-disabled={disabled !== false}
      data-testid={dataTestId}
    >
      {iconBefore && icon && (
        <AppIcon
          classNameWrapper={styles['button__icon-container']}
          classNameSvg={[
            styles['button__icon'],
            !!iconClassName ? iconClassName : '',
          ]}
          icon={icon}
          category={iconCategory}
        />
      )}
      <span className={labelClassNames}>{label}</span>
      {!iconBefore && icon && (
        <AppIcon
          classNameWrapper={`${styles['button__icon-container']}`}
          classNameSvg={[
            styles['button__icon'],
            !!iconClassName ? iconClassName : '',
          ]}
          icon={icon}
          category={iconCategory}
        />
      )}
      <AppIcon
        classNameWrapper={`${styles['button__loading-icon-container']} `}
        classNameSvg={[
          styles['button__icon'],
          !!iconClassName ? iconClassName : '',
        ]}
        icon="spinner"
      />
      <span className={styles['button__submitted-label']}>
        {submittedLabel || 'Submitted'}
      </span>
      {children}
    </button>
  );
};

export default AppButton;
