import React, { useEffect, useState } from 'react';
import { signIn, ClientSafeProvider, useSession } from 'next-auth/react';
import Router, { useRouter } from 'next/router';
import * as amplitude from '@amplitude/analytics-browser';
import classNames from 'classnames/bind';

import {
  ACCOUNT_SIGN_UP_ERROR,
  ACCOUNT_SIGN_UP_START,
  ACCOUNT_SIGN_UP_COMPLETE,
} from 'constants/amplitude';

import { openPopupWindow } from 'utils/openPopupWindow';

import useAuthRedirect from 'hooks/useAuthRedirect';
import useCreateRecord from 'hooks/useCreateRecord';

import AppButton from 'components/AppButton/AppButton';
import AppForm from 'components/AppForm/AppForm';
import AppFormError from 'components/AppForm/AppFormError';
import AppIcon from 'components/AppIcon/AppIcon';
import TermsAndPrivacyPolicy from 'components/TermsAndPrivacyPolicy/TermsAndPrivacyPolicy';

import AppSignUpForm from './AppSignUpForm';

import styles from './styles/AppSignUp.module.scss';

const SITE_BASE = process.env.NEXT_PUBLIC_SITE_BASE;
const SIGNIN_DEFAULT = `${SITE_BASE}/signin`;

const cx = classNames.bind(styles);

export interface AppSignUpProps {
  className?: string;
  noPadding?: boolean;
  isCheckout?: boolean;
  providers: Record<string, ClientSafeProvider> | null;
  redirect?: boolean;
  redirectUrl?: string;
  signInLink?: string;
  onSignInClick?: () => void;
}

type Form = {
  email: string;
  tos: boolean;
  name: string;
  newsletter: boolean;
  password: string;
};

export const AppSignUp: React.FC<AppSignUpProps> = ({
  className,
  noPadding,
  providers,
  redirect = true,
  redirectUrl,
  signInLink,
  onSignInClick,
}: AppSignUpProps) => {
  const router = useRouter();
  const {
    query: { subtitle, title = 'Create your free account' },
  } = router;
  const v2 = router.pathname === '/signup/email';
  const [emailSignUp, setEmailSignUp] = useState(() => !!v2);
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const [signInHref, setSignInHref] = useState(signInLink || SIGNIN_DEFAULT);
  const { createRecord } = useCreateRecord();
  const redirectProps = useAuthRedirect(redirect, redirectUrl);
  const { data: session } = useSession();

  useEffect(() => {
    if (session && redirect) {
      Router.push(redirectProps.callbackUrl || '/');
    }
    if (router.query.error) {
      if (router.query.error === 'invalid_grant') {
        setServerErrors(['Invalid email and/or password']);
      } else {
        setServerErrors([String(router.query.error)]);
      }
    }
    if (router.query.redirect) {
      const signInUrl = new URL(signInLink || SIGNIN_DEFAULT);
      signInUrl.searchParams.set('redirect', router.query.redirect as string);
      setSignInHref(signInUrl.toString());
    }
  }, [redirect, redirectProps.callbackUrl, router.query, session, signInLink]);

  const onEmailSignUp: AppFormSubmitHandler<Form> = async (formState: Form) => {
    setServerErrors([]);

    const { email, tos, name, newsletter, password } = formState;

    const { errors } = await createRecord<UserModel>({
      admin: false,
      agreesToTerms: tos,
      camperType: [],
      city: null,
      confirmedEmail: false,
      email,
      emailNotification: false,
      fullName: name,
      hasPassword: false,
      newsletter,
      password,
      phone: null,
      phoneNotification: false,
      pro: false,
      type: 'users',
      verified: false,
    });

    if (errors) {
      setServerErrors(errors);
      amplitude.track(ACCOUNT_SIGN_UP_ERROR, {
        'error type': errors.join(', '),
      });
    } else {
      const identify = new amplitude.Identify();
      identify.set('account sign up date', new Date().toISOString());
      identify.set('sign in method', 'email');
      identify.set('email', email);
      identify.set('email opt in', newsletter);
      identify.set('account status', 'free');

      amplitude.identify(identify);

      await signIn('username-login', {
        username: email,
        password,
        ...redirectProps,
      });

      amplitude.track(ACCOUNT_SIGN_UP_COMPLETE, {
        'sign up method': 'email',
      });

      window.dataLayer.push({
        event: 'registration_complete',
      });
    }
  };

  const handleProviderSignUp = async (
    e: ButtonClickEvent,
    provider: ClientSafeProvider
  ) => {
    e.preventDefault();

    openPopupWindow(
      `${window.location.origin}/signin/${provider.id}${window.location.search}`,
      `The Dyrt - Join with ${provider.name}`
    );
  };

  const formClassNames = cx({
    'sign-up': !v2,
    'sign-up--v2': v2,
    'sign-up--has-padding': !noPadding,
    'sign-up--has-shadow': !noPadding,
  });

  const titleClassNames = cx({
    'sign-up__title': true,
    'sign-up__title--has-subtitle': !!subtitle,
  });

  const joinButtonClassNames = cx({
    'sign-up__button': true,
    'sign-up__button--is-provider': true,
    'm-t-xl': !!subtitle,
  });

  const sendSignupEvent = (method: string) => {
    amplitude.track(ACCOUNT_SIGN_UP_START, {
      'sign in method': method,
    });
  };

  const handleSignInClick = () => {
    if (onSignInClick) {
      onSignInClick();
    } else {
      router.replace(signInHref, undefined, { shallow: true });
    }
  };

  if (emailSignUp) {
    return (
      <AppForm<Form>
        className={`${formClassNames} ${className}`}
        onSubmit={onEmailSignUp}
        id="emailSignUp"
      >
        <AppSignUpForm
          errors={serverErrors}
          handleSignInClick={handleSignInClick}
          handleBackClick={() => setEmailSignUp(false)}
        />
      </AppForm>
    );
  }

  return (
    <div className={`${formClassNames} ${className}`}>
      <div className={styles['sign-up__header']}>
        <AppIcon
          icon="dyrt"
          title="dyrt logo"
          classNameSvg={[styles['sign-up__logo']]}
          classNameWrapper={styles['sign-up__logoContainer']}
        />

        <h1 className={titleClassNames}>{title}</h1>
        {!!subtitle && (
          <p className={styles['sign-up__subtitle']}>{subtitle}</p>
        )}
      </div>

      <AppFormError
        className={styles['sign-up__error']}
        errors={serverErrors}
      />
      {providers && (
        <div className={styles['sign-up__providers']}>
          {Object.values(providers).map((provider) => {
            if (
              provider.id !== 'username-login' &&
              provider.id !== 'googleonetap'
            )
              return (
                <AppButton
                  type={'button'}
                  key={provider.id}
                  className={`${styles['sign-up__button']} ${styles['sign-up__button--is-provider']}`}
                  label={`Continue with ${provider.name}`}
                  onClick={(e) => {
                    handleProviderSignUp(e, provider);
                    sendSignupEvent(provider.id);
                  }}
                  icon={provider.id}
                  iconCategory="providers"
                  iconStart
                  ghost="dark"
                  background
                  dataEvent={`click_signup_${provider.name}`}
                />
              );
          })}
          <span className={styles['sign-up__providers-divider']}>OR</span>
        </div>
      )}
      <AppButton
        className={joinButtonClassNames}
        label="Create a Free Account"
        onClick={() => {
          setEmailSignUp(true);
          sendSignupEvent('email');
        }}
        dataEvent="click_signup_email"
        type="button"
      />
      <p className={styles['sign-up__sign-in']}>
        Already have an account?&nbsp;
        <AppButton
          type={'button'}
          label="Sign In"
          ghost
          borderless
          onClick={handleSignInClick}
          dataEvent="click_sign_in"
        />
      </p>
      <TermsAndPrivacyPolicy dataEvent="sign-in" />
    </div>
  );
};

export default AppSignUp;
