// set of common react-hook-form validations

export const REQUIRED_EMAIL_VALIDATION = { required: 'Please enter an email.' };

export const PATTERN_EMAIL_VALIDATION = {
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: 'Please enter a valid email.',
  },
};

export const EMAIL_VALIDATION = {
  ...REQUIRED_EMAIL_VALIDATION,
  ...PATTERN_EMAIL_VALIDATION,
};

export const PASSWORD_VALIDATION = { required: 'Please enter a password.' };

export const NEW_PASSWORD_VALIDATION = {
  ...PASSWORD_VALIDATION,
  minLength: {
    value: 8,
    message: 'Please enter a password at least 8 characters long.',
  },
};

export const WEBSITE_VALIDATION = {
  pattern: {
    message: 'Please enter a valid URL.',
    value:
      /^((ftp|http|https):\/\/)(www.)?(?!.*(ftp|http|https|www.))?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/gm,
  },
};

export const PHONE_VALIDATION = {
  pattern: {
    message: 'Please enter a valid phone number.',
    value:
      /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g,
  },
};

export const CALENDAR_URL_VALIDATION = {
  pattern: {
    message: 'Please enter a valid .ics URL.',
    value: /(\.ics)|(ical)/gm,
  },
};

export const PHONE_NUMBER_MASK = '(999) 999-9999';
