import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames/bind';
import gfm from 'remark-gfm';

import AppButton from 'components/AppButton/AppButton';

import styles from './styles/AppMarkdown.module.scss';

const cx = classNames.bind(styles);

export interface AppMarkdownProps {
  className?: string;
  buttonClassName?: string;
  content: string | null;
  truncate?: boolean | number;
  expandable?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLInputElement>;
}

interface LinkProps {
  href: string;
  children?: JSX.Element;
}

// https://github.com/remarkjs/react-markdown/issues/541#issuecomment-775868753
const customRenderers = {
  link: function CustomMarkdownLink({ href, children }: LinkProps) {
    return (
      <a href={href} target="_blank" rel="nofollow noopener noreferrer">
        {children}
      </a>
    );
  },
};

export const AppMarkdown: React.FC<AppMarkdownProps> = ({
  content,
  className,
  buttonClassName,
  truncate,
  expandable = true,
  onClick,
}: AppMarkdownProps) => {
  const [isTruncated, setTruncate] = useState(!!truncate ? true : false);

  const truncatedClassNames = cx({
    'markdown__contentTruncated-1line': !!isTruncated && !!truncate,
    'markdown__contentTruncated-2line': !!isTruncated && truncate === 2,
    'markdown__contentTruncated-3line': !!isTruncated && truncate === 3,
    'markdown__contentTruncated-4line': !!isTruncated && truncate === 4,
    'markdown__contentTruncated-5line': !!isTruncated && truncate === 5,
    markdown__contentFull: !isTruncated,
  });

  if (typeof content !== 'string') return null;

  return (
    <div className={`${styles['markdown']} ${className}`}>
      <div className={truncatedClassNames}>
        <ReactMarkdown plugins={[[gfm, {}]]} renderers={customRenderers}>
          {content.replace(/<p>|<\/p>/g, '')}
        </ReactMarkdown>
      </div>

      {isTruncated && expandable && (
        <AppButton
          type={'button'}
          label="Read More"
          className={buttonClassName}
          ghost
          borderless
          onClick={(e) => {
            setTruncate(!isTruncated);
            onClick?.(e);
          }}
          dataEvent={`click_expand_markdown`}
        />
      )}
    </div>
  );
};

export default AppMarkdown;
