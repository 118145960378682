import React, {
  useContext,
  useMemo,
  useReducer,
  useCallback,
  useEffect,
} from 'react';
import { Router } from 'next/router';
import { PaymentMethod } from '@stripe/stripe-js';

import useCreateRecord from 'hooks/useCreateRecord';
import useLoadRecord from 'hooks/useLoadRecord';
import useRouterMethod from 'hooks/useRouterMethod';
import useUpdateRecord from 'hooks/useUpdateRecord';

import { useUser } from './currentUser';

type FormState = {
  loading: boolean;
  errors: null | string[];
};

type ReservationState = {
  reservation: ReservationModel;
  campground: CampgroundModel;
  reservationItems: ReservationItemModel[];
} & FormState;

type ReservationAction =
  | {
      type: 'SET_RESERVATION';
      payload: {
        reservation: ReservationModel;
        campground?: CampgroundModel;
        reservationItems?: ReservationItemModel[];
      };
    }
  | { type: 'CLEAR_RESERVATION'; payload: ReservationState }
  | {
      type: 'UPDATE_FORM_STATE';
      payload: Partial<FormState>;
    };

const PAYMENT_METHOD_SESSION_STORAGE_KEY = 'reservationPaymentMethod';

export type ReservationContextValue = ReservationState & {
  createReservation: (
    campgroundId: CampgroundModel['id'],
    startDate?: ReservationModel['startDate'],
    endDate?: ReservationModel['endDate']
  ) => void;
  setReservation: (reservationId: ReservationModel['id']) => void;
  clearReservation: () => void;
  updateReservation: (
    requestReservation: Partial<ReservationModel>
  ) => Promise<{ record: ReservationModel | null; errors: null | unknown }>;
  updateFormState: (newState: Partial<FormState>) => void;
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
  getPaymentMethod: () => PaymentMethod | null;
};

const defaultReservationState: ReservationState = {
  reservation: {} as ReservationModel,
  campground: {} as CampgroundModel,
  reservationItems: [] as ReservationItemModel[],
  errors: null,
  loading: false,
};

const ReservationContext = React.createContext<ReservationContextValue>({
  reservation: {} as ReservationModel,
  campground: {} as CampgroundModel,
  reservationItems: [] as ReservationItemModel[],
  loading: false,
  errors: null,
  createReservation: () => {},
  setReservation: () => {},
  clearReservation: () => {},
  updateReservation: async () => ({ errors: null, record: null }),
  updateFormState: () => {},
  setPaymentMethod: () => {},
  getPaymentMethod: () => null,
});

const reservationReducer = (
  state: ReservationState,
  action: ReservationAction
) => {
  switch (action.type) {
    case 'SET_RESERVATION':
      const reservationItems = action.payload.reservationItems
        ? action.payload.reservationItems
        : state.reservationItems;

      const campground = action.payload.campground
        ? action.payload.campground
        : state.campground;

      const reservation = action.payload.reservation;
      reservation.informationFields = reservation.informationFields?.filter(
        (field) => field.name !== 'paymentInfo'
      );

      return {
        ...state,
        reservation,
        reservationItems,
        campground,
      };
    case 'CLEAR_RESERVATION':
      return defaultReservationState;
    case 'UPDATE_FORM_STATE':
      const updatedState = { ...state };
      if (action.payload.errors !== undefined) {
        updatedState.errors = action.payload.errors;
      }
      if (action.payload.loading !== undefined) {
        updatedState.loading = action.payload.loading;
      }
      return updatedState;
    default:
      return state;
  }
};

// TODO hack until 48829 lands
const serializeRelationships = (reservation: Partial<ReservationModel>) => {
  return Object.entries(reservation).reduce((memo, [key, value]) => {
    const match = key.match(/^(.*?)Id$/);

    if (match && value) {
      const relationshipType = match[1];
      memo[relationshipType] = {
        data: {
          type: `${relationshipType}s`,
          id: value as string,
        },
      };
    }
    return memo;
  }, {} as Record<string, JSONAPIRelationship>);
};

export const useReservations = () =>
  useContext(ReservationContext) as ReservationContextValue;

const ReservationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [reservationState, reservationDispatch] = useReducer(
    reservationReducer,
    defaultReservationState
  );

  const { reservation, errors } = reservationState;

  const { createRecord } = useCreateRecord();

  const { loadRecord } = useLoadRecord();

  const { updateRecord } = useUpdateRecord();

  const { push } = useRouterMethod();

  const { user: currentUser } = useUser();

  const setPaymentMethod = useCallback(
    (paymentMethod: PaymentMethod | null) => {
      if (paymentMethod) {
        sessionStorage.setItem(
          PAYMENT_METHOD_SESSION_STORAGE_KEY,
          JSON.stringify(paymentMethod)
        );
      } else {
        sessionStorage.removeItem(PAYMENT_METHOD_SESSION_STORAGE_KEY);
      }
    },
    []
  );

  const getPaymentMethod = useCallback(() => {
    const method = sessionStorage.getItem(PAYMENT_METHOD_SESSION_STORAGE_KEY);

    if (method) {
      return JSON.parse(method) as PaymentMethod;
    }

    return null;
  }, []);

  const createReservation = useCallback(
    async (
      campgroundId: CampgroundModel['id'],
      startDate?: ReservationModel['startDate'],
      endDate?: ReservationModel['endDate']
    ) => {
      if (campgroundId === reservationState.reservation?.campgroundId) {
        const { reservation } = reservationState;

        push(
          `/checkout/${campgroundId}/step/${reservation.informationFields[0].name}`
        );
        return;
      }

      const reservationPartial: Partial<ReservationModel> = {
        type: 'reservations',
      };

      if (startDate && endDate) {
        reservationPartial.startDate = startDate;
        reservationPartial.endDate = endDate;
      }

      if (currentUser) {
        reservationPartial.userId = currentUser.id;
        reservationPartial.email = currentUser.email;
        reservationPartial.phone = currentUser.phone;
        reservationPartial.name = currentUser.fullName;
      }

      reservationPartial.campgroundId = campgroundId;

      await loadRecord<CampgroundModel>(
        'campgrounds',
        campgroundId,
        {},
        {},
        (response) => {
          const { data: campground } = response;

          createRecord(
            reservationPartial,
            serializeRelationships(reservationPartial),
            (data) => {
              reservationDispatch({
                type: 'SET_RESERVATION',
                payload: { reservation: data, campground },
              });

              push(
                `/checkout/${data.id}/step/${data.informationFields[0].name}`
              );
            }
          );
        }
      );
    },
    [reservationState, createRecord, loadRecord, push, currentUser]
  );

  const setReservation = useCallback(
    (reservationId: ReservationModel['id']) => {
      loadRecord<ReservationModel, ReservationItemModel>(
        'reservations',
        reservationId,
        {
          include: 'reservation-items',
        },
        {},
        (response) => {
          const { data, included } = response;
          reservationDispatch({
            type: 'SET_RESERVATION',
            payload: {
              reservation: data,
              reservationItems: included,
            },
          });
        }
      );
    },
    [loadRecord]
  );

  const updateFormState = useCallback(
    ({ loading, errors }: Partial<FormState>) => {
      reservationDispatch({
        type: 'UPDATE_FORM_STATE',
        payload: {
          errors,
          loading,
        },
      });
    },
    []
  );

  const updateReservation = useCallback(
    async (requestReservation: Partial<ReservationModel>) => {
      updateFormState({ loading: true });
      const response = await updateRecord<
        ReservationModel,
        ReservationItemModel
      >(
        requestReservation,
        serializeRelationships(requestReservation),
        (data, included) => {
          reservationDispatch({
            type: 'SET_RESERVATION',
            payload: {
              reservation: data,
              reservationItems: included,
            },
          });
        },
        {
          include: 'reservation-items',
        }
      );

      if (response.errors) {
        updateFormState({ errors: response.errors, loading: false });
      } else if (errors && !response.errors) {
        updateFormState({ errors: null, loading: false });
      } else {
        updateFormState({ loading: false });
      }

      return response;
    },
    [updateRecord, errors, updateFormState]
  );

  const clearReservation = useCallback(() => {
    setPaymentMethod(null);
    reservationDispatch({
      type: 'CLEAR_RESERVATION',
      payload: defaultReservationState,
    });
  }, [setPaymentMethod, reservationDispatch]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (reservation.id && !url.includes(`/checkout/${reservation.id}`)) {
        clearReservation();
      }
    };

    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [reservation.id, clearReservation, setPaymentMethod]);

  const contextValue = useMemo(() => {
    return {
      ...reservationState,
      reservationDispatch,
      createReservation,
      setReservation,
      clearReservation,
      updateReservation,
      updateFormState,
      setPaymentMethod,
      getPaymentMethod,
    };
  }, [
    reservationState,
    reservationDispatch,
    createReservation,
    setReservation,
    clearReservation,
    updateReservation,
    updateFormState,
    setPaymentMethod,
    getPaymentMethod,
  ]);

  return (
    <ReservationContext.Provider value={contextValue}>
      {children}
    </ReservationContext.Provider>
  );
};

export default ReservationProvider;
