import React from 'react';

import { useUser } from 'contexts/currentUser';

import _SidebarFiltersFilter from './_SidebarFiltersFilter';

import styles from './styles/SearchCampgrounds.module.scss';

export interface SubgroupProps {
  className?: string;
  searchFilters: Record<SearchFilter['id'], SearchFilter>;
  searchFilterGroup: SearchFilterGroup;
}
const _SidebarFiltersSubgroup: React.FC<SubgroupProps> = ({
  className,
  searchFilterGroup,
  searchFilters,
}: SubgroupProps) => {
  const userState = useUser();
  const filterPaywall = searchFilterGroup.pro && !userState?.user?.pro;
  const filterGroup = () => {
    return searchFilterGroup.content.map((filter) => {
      if ('id' in filter) {
        const searchFilter = searchFilters[filter.id];
        return (
          <_SidebarFiltersFilter
            className={styles['subgroup__filter']}
            key={filter.id}
            searchFilter={searchFilter}
            disabled={filterPaywall || false}
          />
        );
      }
    });
  };
  return (
    <div className={`${className} ${styles['subgroup']}`}>
      <p className={styles['subgroup__headline']}>{searchFilterGroup.name}</p>
      {filterGroup()}
    </div>
  );
};

export default _SidebarFiltersSubgroup;
