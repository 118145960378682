import React from 'react';

import { useOverlay } from 'hooks/useOverlayContext';

import ManagerReservationsModal from './ManagerReservationsModal';

const _Decline = () => {
  const overlay = useOverlay<ManagerReservationOverlay>(
    'manager-reservation-decline'
  );
  const { reservation } = overlay || {};

  return (
    <ManagerReservationsModal
      title="Decline Request"
      description={`We’ll let ${reservation?.name} know that their request has been declined.`}
      inputLabel="Include a message to this camper."
      placeholder="Let them know if you have other Sites 
        available on their dates."
      buttonLabel="Decline Request"
      buttonDataEvent={`click_decline_reservation_${reservation?.id}`}
      type="manager-reservation-decline"
    />
  );
};

export default _Decline;
