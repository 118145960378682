import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import useCreateFlag from 'hooks/useCreateFlag';

import AppButton from 'components/AppButton/AppButton';
import AppForm from 'components/AppForm/AppForm';
import AppFormError from 'components/AppForm/AppFormError';
import AppFormRadio from 'components/AppForm/AppFormRadio';
import AppFormTextArea from 'components/AppForm/AppFormTextArea';

import styles from './styles/AppModal.module.scss';

interface ReportFormProps {
  subject: DyrtModel;
  close(reportedId: string | null): void;
}

const REASONS = [
  'Spam / Scam',
  'Nudity',
  'Hate / Violence',
  'Illegal',
  'Unrelated',
  'Blurry / Low Quality',
  'Other',
] as const;

type Reason = typeof REASONS[number];

type Form = {
  reason: Reason;
  otherReason: string;
};

const ReportForm: React.FC<ReportFormProps> = ({
  subject,
  close,
}: ReportFormProps) => {
  const { createFlag, errors: apiErrors } = useCreateFlag();

  const cancel = () => {
    close(null);
  };

  const submit: AppFormSubmitHandler<Form> = async (formValues) => {
    const { reason, otherReason } = formValues;

    const flag = await createFlag(subject, reason, otherReason);

    if (flag) close(subject.id);
  };

  return (
    <div className={styles['report']}>
      <AppForm<Form> onSubmit={submit}>
        <div className={styles['report__content-container']}>
          <h2 className={styles['report__title']}>Report Content</h2>
          <_ReportFormContent />
          <AppFormError errors={apiErrors} className="m-t-xl" />
        </div>
        <div className={styles['modal__buttons-bar']}>
          <AppButton
            label="Report"
            type="submit"
            dataEvent={`click_submit_report`}
          />
          <AppButton
            type="button"
            ghost
            label="Cancel"
            onClick={cancel}
            dataEvent={`click_cancel_report`}
          />
        </div>
      </AppForm>
    </div>
  );
};

const _ReportFormContent = () => {
  const reason = useWatch({ name: 'reason' });
  const {
    formState: {
      errors: { reason: reasonError },
    },
  } = useFormContext();

  return (
    <div className="m-t-md">
      {REASONS.map((reason) => (
        <AppFormRadio
          name="reason"
          value={reason}
          key={reason}
          label={reason}
          className={styles['report__option']}
          validation={{ required: 'Please select a reason' }}
        />
      ))}
      {reasonError && (
        <AppFormError errors={[reasonError.message]} className="m-t-xl" />
      )}
      {reason === 'Other' && (
        <div className="m-t-md">
          <AppFormTextArea
            name="otherReason"
            validation={{ required: 'Please enter a reason' }}
          />
        </div>
      )}
    </div>
  );
};

export default ReportForm;
