import React from 'react';

import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/_ProModalFeatureListItem.module.scss';

export interface _ProModalFeatureListItemProps {
  className?: string;
  title: string;
}

export const _ProModalFeatureListItem: React.FC<
  _ProModalFeatureListItemProps
> = ({ className, title }: _ProModalFeatureListItemProps) => {
  return (
    <div className={`${styles['pro-modal-feature']} ${className}`}>
      <AppIcon
        classNameWrapper={styles['pro-modal-feature-icon-wrapper']}
        classNameSvg={[styles['pro-modal-feature-icon']]}
        icon={'check-gradient'}
        category={'pro'}
      />
      <p className={styles['pro-modal-feature-title']}>{title}</p>
    </div>
  );
};

export default _ProModalFeatureListItem;
