import React from 'react';
import classNames from 'classnames/bind';

import styles from './styles/AppInput.module.scss';

const cx = classNames.bind(styles);

export interface AppInputRadioProps {
  inputRef?: React.Ref<HTMLInputElement> | React.RefCallback<HTMLInputElement>;
  className?: string;
  label?: string | JSX.Element;
  labelClassName?: string;
  value: string;
  disabled?: boolean;
  name: string;
  isChecked?: boolean;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  isDescOrder?: boolean;
  dataEvent?: string;
  dataEventAction?: string;
  dataEventCategory?: string;
  dataEventLabel?: boolean;
  dataEventValue?: string;
  dataTestId?: string;
}

export const AppInputRadio: React.FC<AppInputRadioProps> = ({
  inputRef,
  className,
  label,
  name,
  value,
  isChecked,
  isDescOrder = false,
  onChange,
  disabled,
  dataEvent,
  dataEventAction,
  dataEventCategory,
  dataEventLabel,
  dataEventValue,
  dataTestId,
  labelClassName,
}: AppInputRadioProps) => {
  const buttonSVG = (
    <svg
      className={styles['input__radio-svg']}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="11.5" fill="white" stroke="#6A6B6C" />
      {isChecked && <circle cx="12" cy="12" r="8" fill="#920B66" />}
    </svg>
  );

  const appInputRadioClassName = cx({
    'input__radio-desc': isDescOrder,
  });

  return (
    <label
      className={`${styles['input__radio']} ${className} ${appInputRadioClassName}`}
      data-testid={dataTestId}
    >
      <span className={styles['input__radio-container']}>
        <input
          ref={inputRef}
          className={styles['input__radio-actual']}
          type="radio"
          value={value}
          name={name}
          checked={isChecked}
          onChange={onChange}
          tabIndex={0}
          disabled={disabled}
          data-event={dataEvent}
          data-event-action={dataEventAction}
          data-event-category={dataEventCategory}
          data-event-label={dataEventLabel}
          data-event-value={dataEventValue}
        />
        {buttonSVG}
      </span>
      <span
        className={`${styles['input__label-container']} ${styles['input__label']}${labelClassName}`}
      >
        {label}
      </span>
    </label>
  );
};

export default AppInputRadio;
