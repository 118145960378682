import React, { useEffect, useState, useRef, useCallback } from 'react';
import Router from 'next/router';
import classNames from 'classnames/bind';
import kebabCase from 'lodash.kebabcase';

import { isServer } from 'utils/isServer';

import { useOverlay, useOverlayContext } from 'hooks/useOverlayContext';

import AppAction from 'components/AppAction/AppAction';
import AppIcon from 'components/AppIcon/AppIcon';
import AppPhoto from 'components/AppPhoto/AppPhoto';
import AppVideo from 'components/AppVideo/AppVideo';

import AppMediaContent from './AppMediaContent';

import styles from './styles/AppMediaViewer.module.scss';

const cx = classNames.bind(styles);

export interface AppMediaViewerProps {
  className?: string;
  reported?: string[];
}

interface MediaItemProps {
  item: MediaItemModel;
  hidden?: boolean;
}

const MediaItem: React.FC<MediaItemProps> = ({
  item,
  hidden = false,
}: MediaItemProps) => {
  const [loading, setLoading] = useState(true);
  const photoRef = useRef<HTMLImageElement | null>(null);

  const showPlaceholder = !item.mediaUrl;
  const showVideo = !!item.mediaUrl && item.mediaType === 'Video';
  const showPhoto = !!item.mediaUrl && item.mediaType === 'Photo';

  useEffect(() => {
    showVideo && setLoading(true);
    showPhoto && setLoading(item.mediaUrl !== photoRef.current?.src);
  }, [hidden, setLoading, item, showPhoto, showVideo]);

  const mediaItemClasses = cx({
    'media-viewer__media': true,
    'media-viewer__media--is-video': item.mediaType === 'Video',
    'media-viewer__media--is-hidden': hidden || loading,
  });

  return (
    <>
      {loading && !hidden && (
        <AppIcon
          classNameWrapper={styles['media-viewer__loading']}
          classNameSvg={[styles['media-viewer__loading-icon']]}
          icon="spinner"
        />
      )}
      {showPlaceholder && (
        <AppPhoto
          className={mediaItemClasses}
          src={'placeholder'}
          alt={'Media is being processed'}
          onLoad={() => !hidden && setLoading(false)}
          sizes={'100vmax'}
          options={{
            fit: 'crop',
            crop: 'smart',
          }}
        />
      )}
      {showVideo && (
        <AppVideo
          className={mediaItemClasses}
          youtubeUrl={item.mediaUrl}
          title={item.caption || ''}
          onLoad={() => !hidden && setLoading(false)}
        />
      )}
      {showPhoto && (
        <AppPhoto
          className={mediaItemClasses}
          src={item.mediaUrl}
          alt={item.caption || ''}
          options={{}}
          onLoad={() => !hidden && setLoading(false)}
          sizes={`100vmax`}
          imageRef={photoRef}
        />
      )}
    </>
  );
};

export const AppMediaViewer: React.FC<AppMediaViewerProps> = ({
  className = '',
  reported = [],
}: AppMediaViewerProps) => {
  const { setOverlay, close } = useOverlayContext<AppMediaViewerBase>();
  const overlay = useOverlay<AppMediaViewerBase>('media');

  const [showContent, setShowContent] = useState(true);

  const {
    type,
    mediaItems = [],
    index: startAt = 0,
    campground,

    redirectOnClose,
    review,
  } = overlay || {};
  let { reviews = [] } = overlay || {};

  const indexFromRoute = mediaItems.findIndex(
    (img) => img.id === Router.query.id
  );

  if (!reviews.length && review) reviews = [review];

  const [currentIndex, setCurrentIndex] = useState(
    indexFromRoute && indexFromRoute > 0 ? indexFromRoute : startAt
  );

  const modalClosing = useRef(false);

  const active = type === 'media';

  //  updates after trying to get this Id from url params
  const thisId = mediaItems[currentIndex]?.id;

  const loadPrevious = useCallback(
    (event: KeyboardEvent | IconClickEvent) => {
      event.stopPropagation();

      setCurrentIndex(
        currentIndex - 1 < 0 ? mediaItems.length - 1 : currentIndex - 1
      );
    },
    [currentIndex, mediaItems.length]
  );

  const loadNext = useCallback(
    (event: KeyboardEvent | IconClickEvent) => {
      event.stopPropagation();

      setCurrentIndex(
        currentIndex + 1 > mediaItems.length - 1 ? 0 : currentIndex + 1
      );
    },
    [currentIndex, mediaItems.length]
  );

  useEffect(() => {
    if (active) {
      const listenForArrowKeys = (e: KeyboardEvent) => {
        if (e.key === 'ArrowRight') {
          loadNext(e);
        } else if (e.key === 'ArrowLeft') {
          loadPrevious(e);
        }
      };

      document.addEventListener('keyup', listenForArrowKeys);

      return () => {
        document.removeEventListener('keyup', listenForArrowKeys);
      };
    }
  }, [active, loadPrevious, loadNext]);

  const mediaViewerClasses = cx({
    'media-viewer': true,
    'media-viewer--is-active': active,
    'media-viewer--has-controls': showContent,
  });

  const removeQueryParam = (param: string) => {
    const { pathname, query } = Router;

    // eslint-disable-next-line
    const params = new URLSearchParams(query as any);

    params.delete(param);
    Router.replace({ pathname, query: params.toString() }, undefined, {
      shallow: true,
    });
  };

  const isSingleReviewRoute = review
    ? Router.asPath.split('/media')[0].includes(review.id)
    : false;
  const isSeoPage = !Router.query.campground;

  const mediaAsPath = isSeoPage
    ? `/camping/${kebabCase(campground.regionName)}/${
        campground.slug
      }/media/${thisId}`
    : review && !isSingleReviewRoute
    ? `${Router.asPath.split('/media')[0]}/media/${thisId}?review=${review.id}`
    : `${Router.asPath.split('/media')[0]}/media/${thisId}`;

  useEffect(() => {
    const updateUrl = async () => {
      await Router.replace(
        {
          query: { ...Router.query, media: currentIndex },
        },
        mediaAsPath,

        { shallow: true }
      );
    };

    if (!modalClosing.current) updateUrl();
  }, [currentIndex, thisId, mediaAsPath]);

  return (
    <article className={`${mediaViewerClasses} ${className} media-viewer`}>
      <header className={styles['media-viewer__actions']}>
        <AppAction
          type={'button'}
          className={styles['media-viewer__action']}
          classNameLabel={styles['media-viewer__actionLabel']}
          classNameIcon={styles['media-viewer__actionIcon']}
          classNameSvg={styles['media-viewer__actionSvg']}
          icon="share"
          label="Share"
          onClick={() => {
            setOverlay<AppMediaOverlayShare>({
              type: 'share',
              title: `Share ${mediaItems[currentIndex].mediaType}`,
              url: `${isServer ? '' : window.location.origin}${
                Router.asPath.split('/media')[0]
              }/media/${thisId}
              `,
            });
          }}
          dataEvent={`click_media_viewer_share`}
        />
        <AppAction
          type={'button'}
          className={styles['media-viewer__action']}
          classNameLabel={styles['media-viewer__actionLabel']}
          classNameIcon={styles['media-viewer__actionIcon']}
          classNameSvg={styles['media-viewer__actionSvg']}
          icon="flag"
          label={reported.includes(thisId as string) ? 'Reported' : 'Report'}
          onClick={() => {
            if (!reported.includes(mediaItems[currentIndex].id)) {
              setOverlay<AppMediaOverlayReport>({
                type: 'report',
                subject: mediaItems[currentIndex] as MediaItemModel,
              });
            }
          }}
          dataEvent={`click_media_viewer_report`}
        />
      </header>
      {mediaItems.length >= 1 && (
        <>
          <div className={styles['media-viewer__media-scroll']}>
            <div className={styles['media-viewer__media-scrollContainer']}>
              <div
                className={styles['media-viewer__media-container']}
                onClick={() => setShowContent(!showContent)}
                data-event="click_toggle_media_viewer_content"
              >
                {/* Preload previous mediaItem */}
                {mediaItems.length > 1 && (
                  <MediaItem
                    item={
                      mediaItems[
                        (currentIndex - 1 + mediaItems.length) %
                          mediaItems.length
                      ]
                    }
                    hidden
                  />
                )}

                {mediaItems.length > 1 && (
                  <>
                    <AppIcon
                      classNameWrapper={`${styles['media-viewer__control-image--prev']}`}
                      classNameSvg={[styles['media-viewer__control-icon']]}
                      icon="chevron_left"
                      onClick={(e) => {
                        loadPrevious(e);
                      }}
                      dataEvent={`click_media-viewer-prev`}
                    />
                  </>
                )}

                <MediaItem item={mediaItems[currentIndex]} />
                {/* Preload next mediaItem */}
                {mediaItems.length > 1 && (
                  <MediaItem
                    item={mediaItems[(currentIndex + 1) % mediaItems.length]}
                    hidden
                  />
                )}

                {mediaItems.length > 1 && (
                  <>
                    <AppIcon
                      classNameWrapper={` ${styles['media-viewer__control-image--next']}`}
                      classNameSvg={[styles['media-viewer__control-icon']]}
                      icon="chevron_right"
                      onClick={(e) => {
                        loadNext(e);
                      }}
                      dataEvent={`click_media-viewer-next`}
                    />
                  </>
                )}
              </div>

              <AppMediaContent
                currentItem={mediaItems[currentIndex]}
                campground={campground}
                reviews={reviews}
              />
            </div>
          </div>
        </>
      )}
      <AppIcon
        classNameWrapper={`${styles['media-viewer__control']} ${styles['media-viewer__control--close']}`}
        classNameSvg={[styles['media-viewer__control-icon']]}
        icon="close_x"
        onClick={() => {
          modalClosing.current = true;

          if (redirectOnClose) Router.replace(redirectOnClose);
          else {
            removeQueryParam('media');
            close();
          }
        }}
        dataEvent={`click_media-viewer-close`}
      />
    </article>
  );
};

export default AppMediaViewer;
