import { useCallback } from 'react';

import { isJSONApiErrorError, isJSONSyntaxError } from 'utils/errors';

const useHandleApiError = () => {
  return useCallback(
    (
      error: unknown,
      syntaxMessage = 'Sorry, your request could not be processed',
      unknownMessage = 'Unknown Error'
    ): string[] => {
      if (isJSONSyntaxError(error)) {
        return [syntaxMessage];
      } else if (error instanceof Error) {
        return isJSONApiErrorError(error)
          ? error.errors.map(({ title, detail }) => detail || title)
          : [error.message];
      } else {
        return [unknownMessage];
      }
    },
    []
  );
};

export default useHandleApiError;
