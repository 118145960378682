// If using a dynamic canonicalUrl or desktopUrl, use the useDynamicWebToAppLink hook.
const webToAppLink = ({
  featureParam,
  canonicalUrl = '',
  desktopUrl = '',
}: {
  featureParam: string;
  canonicalUrl?: string;
  desktopUrl?: string;
}) =>
  `https://thedyrt.app.link?~campaign=web2app&~feature=${featureParam}&$ios_url=https%3A%2F%2Fapps.apple.com%2Fapp%2Fapple-store%2Fid1187626329%3Fpt%3D118456722%26ct%3D${featureParam}%26mt%3D8${
    canonicalUrl ? `&$canonical_url=${canonicalUrl}` : ''
  }${desktopUrl ? `&$desktop_url=${desktopUrl}` : ''}`;

export default webToAppLink;
