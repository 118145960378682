import React from 'react';

import { useOverlay } from 'hooks/useOverlayContext';

import AppMarkdown from 'components/AppMarkdown/AppMarkdown';

import AppModal from './AppModal';

const CampgroundAboutModal: React.FC = () => {
  const overlay = useOverlay<AboutOverlay>('about');

  return (
    <AppModal
      type="about"
      title="About This Campground"
      content={<AppMarkdown content={overlay?.description} />}
    />
  );
};
export default CampgroundAboutModal;
