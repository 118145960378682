import { useEffect, useState } from 'react';
import { ClientSafeProvider, getProviders } from 'next-auth/react';

const useFetchProviders = () => {
  const [providers, setProviders] = useState<Record<
    string,
    ClientSafeProvider
  > | null>(null);

  useEffect(() => {
    getProviders().then((currentProviders) => {
      setProviders(currentProviders);
    });
  }, []);

  return { providers };
};

export default useFetchProviders;
