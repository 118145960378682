import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import classNames from 'classnames/bind';

import { logAmplitudeWebClick } from 'utils/amplitude';

import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/AppInput.module.scss';

const cx = classNames.bind(styles);

export interface AppDropdownProps<L extends string, V extends string> {
  inputRef?: React.Ref<HTMLDivElement>;
  className?: string;
  // the value is `selectedOption[valueAccessor]`
  value?: string | null;
  options: InputOption<L, V>[];
  onSelect: (id: string) => void;
  category?: string;
  icon?: string;
  placeholder?: string;
  valueAccessor: V;
  labelAccessor: L;
  label?: string;
  disabled?: boolean;
  renderOptions?: (
    setSelectedId: (id: string, optionText: string) => void,
    toggle: () => void
  ) => React.ReactNode;
  error?: string | null;
  // TODO 38317 requiredForm is a intermediate prop as we migrate to AppForm
  requiredForm?: boolean;
  renderToggle?: (toggle: () => void) => React.ReactNode;
  rightAlign?: boolean;
}

function AppDropdown<L extends string = 'label', V extends string = 'id'>({
  category,
  className,
  disabled,
  error,
  icon = 'chevron_down',
  inputRef,
  label,
  labelAccessor,
  onSelect,
  options,
  placeholder,
  renderOptions,
  requiredForm,
  value,
  valueAccessor,
  renderToggle,
  rightAlign,
}: AppDropdownProps<L, V>) {
  const menuRef = useRef<HTMLDivElement | null>(null);

  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const setSelectedIdCallback = useCallback(
    (id: string, optionText: string) => {
      const dataEventString = `dropdown_option_click_${label}`;
      toggle();
      onSelect(id);

      logAmplitudeWebClick(optionText, dataEventString);
    },
    [toggle, onSelect, label]
  );

  const selectedOption = useMemo(
    () => options.find((option) => option[valueAccessor] === value),
    [options, value, valueAccessor]
  );

  const filteredOptions = useMemo(
    () => options.filter((option) => option[valueAccessor] !== value),
    [value, options, valueAccessor]
  );

  const buttonClassNames = cx({
    dropdown__button: true,
    'dropdown__button--is-open': isOpen,
    'dropdown__button--is-disabled': disabled,
  });

  const labelClassNames = cx({
    dropdown__label: true,
    'dropdown__label--required': requiredForm,
    'dropdown__label--disabled': disabled,
  });

  const textClassNames = cx({
    dropdown__text: true,
    'dropdown__text--disabled': disabled,
  });

  const listClassNames = cx({
    dropdown__list: true,
    'dropdown__list--right-align': rightAlign,
  });

  const onMouseClick = useCallback(
    (e: MouseEvent) => {
      if (
        isOpen &&
        menuRef.current &&
        !menuRef.current.contains(e.target as Node)
      ) {
        toggle();
      }
    },
    [isOpen, toggle]
  );

  useEffect(() => {
    document.addEventListener('mousedown', onMouseClick);

    return () => {
      document.removeEventListener('mousedown', onMouseClick);
    };
  }, [onMouseClick]);

  return (
    <div
      ref={menuRef}
      className={`${className} ${styles['dropdown']}`}
      role="menu"
    >
      <span className={labelClassNames}>{label}</span>
      {!!renderToggle ? (
        renderToggle(toggle)
      ) : (
        <div
          ref={inputRef}
          onClick={disabled ? undefined : toggle}
          className={buttonClassNames}
          tabIndex={0}
        >
          <p className={textClassNames}>
            {selectedOption ? selectedOption[labelAccessor] : placeholder}
          </p>
          <AppIcon
            icon={icon}
            category={category}
            classNameSvg={
              disabled ? [styles['dropdown__icon--disabled']] : undefined
            }
          />
        </div>
      )}
      {!!error && !isOpen && (
        <span className={styles['input__error-message']}>{error}</span>
      )}
      {isOpen && (
        <ul className={`${className} ${listClassNames}`}>
          {filteredOptions.map((option) => (
            <li
              className={styles['dropdown__list-item']}
              key={option[valueAccessor]}
              id={option[valueAccessor]}
              onClick={() =>
                setSelectedIdCallback(
                  option[valueAccessor],
                  option[labelAccessor]
                )
              }
              tabIndex={0}
            >
              <span className={textClassNames}>{option[labelAccessor]}</span>
            </li>
          ))}
          {renderOptions && renderOptions(setSelectedIdCallback, toggle)}
        </ul>
      )}
    </div>
  );
}

export default AppDropdown;
