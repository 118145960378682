import { useState } from 'react';
import { validate } from 'validate.js';

export function useValidateJsError(
  name: string,
  validateJs: Record<string, unknown> | null,
  hookFormValidation: Record<string, unknown>
) {
  const [validateJsError, setValidateJsError] = useState<string | null>(null);

  const validatejsFunc = (value: string | number) => {
    const cleanName = name.replace('.', '');

    const result = validate(
      { [cleanName]: value },
      { [cleanName]: validateJs }
    );

    if (result) {
      const errors = result[cleanName];
      if (errors.length > 0) {
        setValidateJsError(errors[0]);
      }
    } else {
      setValidateJsError(null);
    }

    return !result;
  };

  const validationObject = validateJs
    ? { validate: validatejsFunc }
    : hookFormValidation;

  return {
    validationObject,
    validateJsError,
  };
}
