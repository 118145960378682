import { usePathname } from 'next/navigation';

import { PAGE_NAMES } from 'constants/amplitude';

import { isServer } from 'utils/isServer';

export const useAmplitudePageName = () => {
  const pathname = usePathname();
  if (!isServer && window?.location?.pathname?.startsWith('/magazine'))
    return 'Magazine';

  return PAGE_NAMES[pathname as PageKeys];
  // TODO: This is broken because usePathname does not return a string that is a key of PAGE_NAMES
};

export default useAmplitudePageName;
