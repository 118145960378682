import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRouter } from 'next/router';

import { EMAIL_VALIDATION, PASSWORD_VALIDATION } from 'constants/inputs';

import AppButton from 'components/AppButton/AppButton';
import AppFormError from 'components/AppForm/AppFormError';
import AppFormInputPassword from 'components/AppForm/AppFormInputPassword';
import AppFormInputText from 'components/AppForm/AppFormInputText';
import AppLink from 'components/AppLink/AppLink';

import styles from './styles/AppSignIn.module.scss';

interface AppSignInFormProps {
  errors?: string[];
  beforeSignIn?: () => void;
}

export const AppSignInForm: React.FC<AppSignInFormProps> = ({ errors }) => {
  const router = useRouter();

  const { trigger, formState, setFocus } = useFormContext();

  const { isSubmitting, isDirty } = formState || {};

  useEffect(() => {
    // focus password field when API throws invalid password error
    if (router.query.error && router.query.error === 'invalid_grant') {
      setFocus('password');
    }
  }, [router.query, setFocus]);

  return (
    <>
      <h2 className={styles['sign-in__title']}>Welcome Back</h2>
      <AppFormError
        errors={errors}
        className={styles['sign-in__error']}
        shouldScroll={isDirty}
      />
      <AppFormInputText
        name="username"
        className={`${styles['sign-in__field']} ${styles['sign-in__field--is-username']}`}
        label="Email"
        validation={EMAIL_VALIDATION}
      />
      <AppFormInputPassword
        name="password"
        className={`${styles['sign-in__field']} ${styles['sign-in__field--is-password']}`}
        label="Password"
        validation={PASSWORD_VALIDATION}
      />
      <AppLink href="/forgotpassword">
        <a
          className={styles['sign-in__forgot']}
          data-event="Consumer_Signin_Forgot-Password"
        >
          Forgot password?
        </a>
      </AppLink>
      <AppButton
        className={`${styles['sign-in__button']} ${styles['sign-in__button--is-submit']}`}
        label="Sign In"
        disabled={isSubmitting ? 'loading' : false}
        onClick={() => {
          trigger();
        }}
        dataEvent="click_submit_signin"
        type="submit"
      />
    </>
  );
};

export default AppSignInForm;
