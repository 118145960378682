import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import kebabCase from 'lodash.kebabcase';

import { NEARBY_CAMPGROUND_CLICK } from 'constants/amplitude';

import { quantitativeDescription } from 'utils/quantitativeDescription';

import AppLink from 'components/AppLink/AppLink';
import AppStarRating from 'components/AppStarRating/AppStarRating';

import styles from './styles/CampgroundDetailsReviewInfo.module.scss';

export interface CampgroundDetailsReviewInfoProps {
  campground: CampgroundModel;
  className?: string;
  titleClassName?: string;
  cityClassName?: string;
}

export const CampgroundDetailsReviewInfo: React.FC<
  CampgroundDetailsReviewInfoProps
> = ({
  campground,
  className,
  titleClassName,
  cityClassName,
}: CampgroundDetailsReviewInfoProps) => {
  const {
    name,
    regionName,
    slug,
    nearestCityName,
    nearestCityRegionName,
    reviewsCount,
    photosCount,
    rating,
  } = campground;
  return (
    <div className={`${styles['details__review']} ${className}`}>
      <div>
        <AppLink href={`/camping/${kebabCase(regionName)}/${slug}`}>
          <a
            onClick={() =>
              amplitude.track(NEARBY_CAMPGROUND_CLICK, {
                'campground name': name,
                'campground region': regionName,
              })
            }
            data-event="Consumer_Campground_Sidebar-Links"
          >
            <div
              className={`${styles['details__review--title']} ${titleClassName} `}
            >
              {name}
            </div>
          </a>
        </AppLink>
      </div>
      {!!nearestCityName && !!nearestCityRegionName && (
        <AppLink
          href={`/camping/${kebabCase(nearestCityRegionName)}/near/${kebabCase(
            nearestCityName
          )}`}
        >
          <a className={`${styles['details__review--info']} ${cityClassName} `}>
            {nearestCityName}, {nearestCityRegionName}
          </a>
        </AppLink>
      )}

      {(!!reviewsCount || !!photosCount) && (
        <div className={styles['details__review--info']}>
          {!!reviewsCount && (
            <div>
              <AppStarRating rating={rating || 0} size="sm" />{' '}
              <span className={styles['details__review--reviews']}>
                {quantitativeDescription('Review', reviewsCount)}
              </span>
            </div>
          )}

          {!!photosCount && (
            <div>{quantitativeDescription('Photo', photosCount)}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default CampgroundDetailsReviewInfo;
