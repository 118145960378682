import React from 'react';

import { useOverlayContext } from 'hooks/useOverlayContext';

import SitesAboutModal from 'components/AppFormCollection/Site/SitesAboutModal';
import AppMediaViewer from 'components/AppMediaViewer/AppMediaViewer';
import DyrtAlertsProUpgrade from 'components/AppModal/_DyrtAlertsProUpgrade';
import AppCampgroundGatingModal from 'components/AppModal/AppCampgroundGatingModal';
import AppConfirmationModal from 'components/AppModal/AppConfirmationModal';
import AppDatesSelectModal from 'components/AppModal/AppDatesSelectModal';
import AppDeleteReviewModal from 'components/AppModal/AppDeleteReviewModal';
import AppReportModal from 'components/AppModal/AppReportModal';
import AppSaveList from 'components/AppModal/AppSaveList';
import AppShareModal from 'components/AppModal/AppShareModal';
import CampgroundAboutModal from 'components/AppModal/CampgroundAboutModal';
import CampgroundAddedModal from 'components/AppModal/CampgroundAddedModal';
import ContactBookingModal from 'components/AppModal/ContactBookingModal';
import InterstitialModal from 'components/AppModal/InterstitialModal';
import ProCellServiceOverlay from 'components/AppModal/ProCellServiceModal';
import _ProUpgradeModalB from 'components/AppModal/ProUpgradeModal/_ProUpgradeModalB/_ProUpgradeModalB';
import _ProUpgradeModalC from 'components/AppModal/ProUpgradeModal/_ProUpgradeModalC/_ProUpgradeModalC';
import _ProUpgradeModalD from 'components/AppModal/ProUpgradeModal/_ProUpgradeModalD/_ProUpgradeModalD';
import SeeInAppModal from 'components/AppModal/SeeInAppModal';
import SignInSignUpModal from 'components/AppModal/SignInSignUpModal';
import CampgroundConnectionDetailsModal from 'components/CampgroundConnectionDetailsModal/CampgroundConnectionDetailsModal';
import ClaimAddListingSuccessModal from 'components/ClaimAddListingSuccessModal/ClaimAddListingSuccessModal';
import ClaimFormSuccessModal from 'components/ClaimFormSuccessModal/ClaimFormSuccessModal';
import LocationEditSuccessModal from 'components/LocationEditSuccessModal/LocationEditSuccessModal';
import ManagerCalendarBlockDatesModal from 'components/ManagerCalendar/CreateBlockedDateRangeModal';
import EditBlockedDateRangeModal from 'components/ManagerCalendar/EditBlockedDateRangeModal';
import ExternalBookingModal from 'components/ManagerCalendar/ExternalBookingModal';
import InternalBookingModal from 'components/ManagerCalendar/InternalBookingModal';
import _Approve from 'components/ManagerReservationsModal/_Approve';
import _Cancel from 'components/ManagerReservationsModal/_Cancel';
import _Decline from 'components/ManagerReservationsModal/_Decline';
import _Modify from 'components/ManagerReservationsModal/_Modify';
import _SidebarFiltersModal from 'components/SearchCampgrounds/_SidebarFiltersModal';
import SetupBookingsStandbyModal from 'components/SetupBookingsStandbyModal/SetupBookingsStandbyModal';
import SetupBookingsSuccessModal from 'components/SetupBookingsSuccessModal/SetupBookingsSuccessModal';

const AppOverlay = () => {
  const { overlays } = useOverlayContext();
  return (
    <>
      {overlays.map(({ type }, index) => {
        switch (type) {
          case 'about':
            return <CampgroundAboutModal key={index} />;
          case 'add':
            return <AppCampgroundGatingModal key={index} />;
          case 'claim-success':
            return <ClaimFormSuccessModal key={index} />;
          case 'confirmation':
            return <AppConfirmationModal key={index} />;
          case 'delete':
            return <AppDeleteReviewModal key={index} />;
          case 'dyrt-alerts-pro-upgrade':
            return <DyrtAlertsProUpgrade key={index} />;
          case 'interstitial-modal':
            return <InterstitialModal key={index} />;
          case 'listing-success':
            return <ClaimAddListingSuccessModal key={index} />;
          case 'listing-success-camper':
            return <CampgroundAddedModal key={index} />;
          case 'location-edit-success':
            return <LocationEditSuccessModal key={index} />;
          case 'manager-block-dates-create':
            return <ManagerCalendarBlockDatesModal key={index} />;
          case 'manager-block-dates-edit':
            return <EditBlockedDateRangeModal key={index} />;
          case 'manager-external-booking':
            return <ExternalBookingModal key={index} />;
          case 'manager-internal-booking':
            return <InternalBookingModal key={index} />;
          case 'manager-reservation-approve':
            return <_Approve key={index} />;
          case 'manager-reservation-decline':
            return <_Decline key={index} />;
          case 'manager-reservation-modify':
            return <_Modify key={index} />;
          case 'manager-reservation-cancel':
            return <_Cancel key={index} />;
          case 'media':
            return <AppMediaViewer key={index} />;
          case 'report':
            return <AppReportModal key={index} />;
          case 'save':
            return <AppSaveList key={index} />;
          case 'share':
            return <AppShareModal key={index} />;
          case 'stay-connected':
            return <CampgroundConnectionDetailsModal key={index} />;
          case 'see-in-app':
            return <SeeInAppModal key={index} />;
          case 'setup-bookings-success':
            return <SetupBookingsSuccessModal key={index} />;
          case 'setup-bookings-standby':
            return <SetupBookingsStandbyModal key={index} />;
          case 'sidebar-filters':
            return <_SidebarFiltersModal key={index} />;
          case 'sign-in-sign-up':
            return <SignInSignUpModal key={index} />;
          case 'date-range-picker':
            return <AppDatesSelectModal key={index} />;

          case 'sites-about':
            return <SitesAboutModal key={index} />;
          case 'contact-booking':
            return <ContactBookingModal key={index} />;
          case 'pro-cell-service':
            return <ProCellServiceOverlay key={index} />;
          case 'pro-upgrade-modal-b':
            return <_ProUpgradeModalB key={index} />;
          case 'pro-upgrade-modal-c':
            return <_ProUpgradeModalC key={index} />;
          case 'pro-upgrade-modal-d':
            return <_ProUpgradeModalD key={index} />;
          default:
            return null;
        }
      })}
    </>
  );
};

export default AppOverlay;
