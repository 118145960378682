/**
 * Fastly src and srcSet generation
 *
 * Srcset generation works in 2 modes: with and without sizes
 * Without a @size attribute, DPR levels are generated for the image
 * With a @size attribute, a 16-unit srcset is generated
 *
 * Some Fastly parammeters interfere with each other in unexpected ways.
 * Read https://developer.fastly.com/reference/io/ for all of the @options
 * The most common one you'll encounter is fit with either a width or a height
 * (rather than both or neither) invalidates the dimension as an argument.
 */

import {
  DEFAULT_DUMP_IMAGE,
  DEFAULT_IMAGE,
  DEFAULT_PARKING_IMAGE,
  DEFAULT_WATER_IMAGE,
} from 'constants/fastlyImage';
import { PUBLIC_ASSETS_PATH } from 'constants/paths';

const PHOTOS = process.env.NEXT_PUBLIC_PHOTOS;
const MAX_IMAGE_WIDTH = 8192;
const DPR_SIZES = [1, 2, 3, 4, 5];

export const generateSrcsetSizes = (minWidth = 64, maxWidth?: number) => {
  const sizes = [];
  let lastSize = minWidth;
  do {
    sizes.push(lastSize);
    lastSize = Math.round((lastSize * 1.33) / 2) * 2;
  } while (lastSize < (maxWidth || MAX_IMAGE_WIDTH));
  if (maxWidth) {
    sizes.push(maxWidth);
  }
  return sizes;
};

const getPathForSize = (
  url: string,
  size: number,
  options: FastlyImageOptions
) => {
  const sizePath = new URL(url);
  const params = sizePath.searchParams;
  if (options.height && options.width) {
    options.height = Math.round((options.height * size) / options.width);
  }
  options.width = size;

  Object.entries(options).forEach((entry) => {
    const [key, value] = entry;
    if (value !== undefined && value !== null) {
      params.set(key, value);
    }
  });
  return `${sizePath.toString()} ${size}w`;
};

const getPathForDpr = (
  url: string,
  dpr: number,
  options: FastlyImageOptions
) => {
  const sizePath = new URL(url);
  const params = sizePath.searchParams;
  options.dpr = dpr;
  Object.entries(options).forEach((entry) => {
    const [key, value] = entry;
    if (value !== undefined && value !== null) {
      params.set(key, value);
    }
  });
  return `${sizePath.toString()} ${dpr}x`;
};

export const getDPRSrcSet = (src: string): string => {
  const allUrls = [];
  for (let i = 1; i < 6; i++) {
    const dprUrl = new URL(src);
    dprUrl.searchParams.append('dpr', i + '');
    allUrls.push(`${dprUrl.toString()} ${i}x`);
  }
  return allUrls.join(',');
};

const replaceHost = (path: string): string => {
  // pass PUBLIC_ASSETS_PATH URLs along
  if (path.startsWith(PUBLIC_ASSETS_PATH)) {
    return path;
  }
  if (!path.startsWith('https')) {
    // relative path
    return `${PHOTOS}/${path}` as string;
  }
  return path;
};

export const getFastlySrc = (
  path: string | null,
  options: FastlyImageOptions,
  locationType?: string | null
): string => {
  if (typeof path !== 'string' || path === 'placeholder') {
    if (locationType === 'dump_station') {
      return DEFAULT_DUMP_IMAGE;
    } else if (locationType === 'water_station') {
      return DEFAULT_WATER_IMAGE;
    } else if (locationType === 'overnight_parking') {
      return DEFAULT_PARKING_IMAGE;
    } else return DEFAULT_IMAGE;
  }

  if (path.endsWith('.svg')) return path;

  path = replaceHost(path);

  const optionsToApply = {
    ...options,
    auto: 'webp',
  };
  const srcPath = new URL(path);
  const params = srcPath.searchParams;
  Object.entries(optionsToApply).forEach((entry) => {
    const [key, value] = entry;
    if (value !== undefined && value !== null) {
      // TODO revise like DPR method
      params.set(key, String(value));
    }
  });
  return srcPath.toString();
};

export const getFastlySrcset = (
  path: string | null,
  options: FastlyImageOptions,
  sizes?: string,
  locationType?: string | null
): string => {
  if (typeof path !== 'string' || path === 'placeholder') {
    if (locationType === 'dump_station') {
      return DEFAULT_DUMP_IMAGE;
    } else if (locationType === 'water_station') {
      return DEFAULT_WATER_IMAGE;
    } else if (locationType === 'overnight_parking') {
      return DEFAULT_PARKING_IMAGE;
    } else return DEFAULT_IMAGE;
  }

  if (path.endsWith('.svg')) return path;

  path = replaceHost(path);

  const optionsToApply = {
    ...options,
    auto: 'webp',
  };

  let srcSizes = [];
  let srcUrls = [];

  if (sizes) {
    srcSizes = generateSrcsetSizes();
    srcUrls = srcSizes.map((size) => {
      return getPathForSize(path as string, size, optionsToApply);
    });
  } else {
    srcSizes = DPR_SIZES;
    optionsToApply.width = options.width;
    optionsToApply.height = options.height;
    srcUrls = srcSizes.map((size) => {
      return getPathForDpr(path as string, size, optionsToApply);
    });
  }

  return srcUrls.join(', ');
};

export const getFastlySrcsetForSource = (
  path: string,
  width: number,
  height: number,
  options: FastlyImageOptions = {}
): string => {
  path = replaceHost(path);

  const optionsToApply: FastlyImageOptions = {
    crop: `${width},${height},smart`,
    auto: 'webp',
    fit: 'crop',
    width,
    height,
    ...options,
  };

  const srcSizes = DPR_SIZES;
  const srcUrls = srcSizes.map((size) => {
    return getPathForDpr(path as string, size, optionsToApply);
  });

  return srcUrls.join(', ');
};
