import React from 'react';

import { useOverlay } from 'hooks/useOverlayContext';

import ManagerReservationsModal from './ManagerReservationsModal';

const _Approve = () => {
  const overlay = useOverlay<ManagerReservationOverlay>(
    'manager-reservation-approve'
  );
  const { reservation } = overlay || {};

  return (
    <ManagerReservationsModal
      title="Approve Request"
      description={`Let ${reservation?.name} know that their request has been approved.`}
      inputLabel="Include a message to this camper."
      placeholder="Input"
      buttonLabel="Approve Request"
      buttonDataEvent={`click_approve_reservation_${reservation?.id}`}
      type="manager-reservation-approve"
    />
  );
};

export default _Approve;
