import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import AppInputDatePicker, {
  AppInputDatePickerProps,
} from 'components/AppInput/AppInputDatePicker';

type AppFormInputDatePickerProps = FormInputProps &
  Omit<AppInputDatePickerProps, 'onChange' | 'value'>;

const AppFormDatePicker: React.FC<AppFormInputDatePickerProps> = ({
  name,
  required,
  validation,
  ...props
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field: { onChange, value } }) => {
        return (
          <AppInputDatePicker
            name={name}
            selected={typeof value === 'string' ? new Date(value) : value}
            onChange={onChange}
            error={errors[name]?.message}
            requiredForm={required}
            {...props}
          />
        );
      }}
    />
  );
};

export default AppFormDatePicker;
