import { useState, useCallback } from 'react';

import { deleteRecord as deleteRecordUtil } from 'utils/JSONAPIAdapter';

import useAuthenticationHeader from './useAuthenticationHeaders';
import useHandleApiError from './useHandleApiError';

interface State {
  errors: string[] | null;
  loading: boolean;
}

type Params = Parameters<typeof deleteRecordUtil>;

const useDeleteRecord = () => {
  const authenticationHeader = useAuthenticationHeader();

  const handleApiError = useHandleApiError();

  const [state, setState] = useState<State>({ loading: false, errors: null });

  const deleteRecord = useCallback(
    async (record: Params[0], onSuccess?: () => Promise<unknown> | unknown) => {
      setState({ loading: true, errors: null });

      try {
        await deleteRecordUtil(record, {}, authenticationHeader);

        setState({
          loading: false,
          errors: null,
        });

        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        setState({
          loading: false,
          errors: handleApiError(err),
        });
      }
    },
    [setState, authenticationHeader, handleApiError]
  );

  return {
    deleteRecord: deleteRecord,
    ...state,
  };
};

export default useDeleteRecord;
