import React, { useEffect, useRef } from 'react';
import isEmpty from 'lodash.isempty';

import useInViewport from 'hooks/useInViewport';
import { usePrevious } from 'hooks/usePrevious';

import styles from './styles/AppForm.module.scss';

export interface AppFormErrorProps {
  className?: string;
  errors?: string[] | null;
  shouldScroll?: boolean;
}

export const AppFormError: React.FC<AppFormErrorProps> = ({
  className,
  errors,
  shouldScroll = true,
}: AppFormErrorProps) => {
  const prevErrors = usePrevious(errors);

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const onScreen = useInViewport(scrollRef);

  useEffect(() => {
    if (
      !isEmpty(errors) &&
      prevErrors !== errors &&
      !onScreen &&
      shouldScroll
    ) {
      scrollRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [errors, shouldScroll, onScreen, prevErrors, scrollRef]);

  return (
    <div ref={scrollRef} className={`${styles['error-box']} ${className}`}>
      {!!errors &&
        errors.map((err, index) => {
          return (
            <div
              key={`error-box-${index}`}
              className={styles['error-box__inner']}
            >
              <p className={styles['error-box__error']}>{err}</p>
            </div>
          );
        })}
    </div>
  );
};

export default AppFormError;
