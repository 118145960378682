export const PAGE_NAMES = {
  '/camping/[region]/[campground]/reviews/[review]': 'Campground Review',
  '/camping/[region]/[campground]/reviews': 'Campground Reviews List',
  '/camping/[region]/[campground]/gallery': 'Campground Gallery',
  '/camping/[region]/near/[place]/with/[filter]': 'Region',
  '/camping/[region]/near/[place]': 'Region',
  '/camping/[region]/with/[filter]': 'Region',
  '/camping/[region]/cities': 'Cities',
  '/camping/[region]/parks': 'Parks',
  '/camping/[region]/[campground]': 'Campground',
  '/camping/[region]': 'Region',

  '/alerts/my-alerts/[alert]': 'Dyrt Alert',
  '/alerts/my-alerts': 'My Dyrt Alerts',
  '/alerts': 'Dyrt Alerts Landing',

  '/claim/properties': 'Claim Properties',
  '/claim/add': 'Claim Add',
  '/claim/[slug]': 'Claim Campground',
  '/claim': 'Claim',

  '/pro/checkout': 'Pro Checkout',
  '/pro/gift': 'Pro Gift',
  '/pro/discount-camping': 'Pro Discount Camping',
  '/pro/free-camping-maps': 'Pro Free Camping Maps',
  '/pro/off-grid-camping-access': 'Pro Off Grid Camping Access',
  '/pro': 'Pro Benefits',
  '/pro-p': 'Pro P',
  '/pro-p-free': 'Pro P Free',
  '/pro-p-plan': 'Pro P Plan',
  '/pro-p-rv': 'Pro P RV',

  '/search': 'Map Search',
  '/signin': 'Signin',
  '/signup': 'Signup',
  '/about/us': 'About Us',

  '/forgotpassword': 'Forgot Password',
  '/new': 'New',
  '/password/reset': 'Password Reset',

  '/special-offer': 'Special Offer',
  '/_error': 'Error',

  '/': 'Home',
} as const;

export const WEB_PAGE_VIEW = 'Web Page View';
export const WEB_CLICK = 'Web Click';
export const WEB_TO_APP_CLICK = 'Web to App Click';
export const WEB_TO_APP_BANNER_SHOWN = 'Web to App Banner Shown';
export const WEB_TO_APP_BANNER_CLICK = 'Web to App Banner Click';
export const CLAIM_CTA_CLICK = 'Claim CTA Click';
export const CLAIM_LEAD_FORM_COMPLETE = 'Claim Lead Form Complete';
export const PROPERTY_CREATED = 'Property Created';
export const PROPERTY_ADDED_TO_CLAIM = 'Property Added to Claim';
export const CLAIM_PHONE_VERIFICATION_COMPLETE =
  'Claim Phone Verification Complete';
export const CLAIM_COMPLETE = 'Claim Complete';
export const CLAIM_SET_UP_BOOKINGS_START = 'Set Up Bookings Start';
export const CLAIM_PROPERTY_OWNER_TYPE_COMPLETE =
  'Claim Property Owner Type Complete';
export const CLAIM_BOOKING_INTENTION_COMPLETE = 'Booking Intention Complete';
export const CLAIM_SOFTWARE_USED_COMPLETE = 'Claim Software Used Complete';
export const CLAIM_BOOKING_METHOD_COMPLETE = 'Booking Method Complete';
export const CLAIM_SET_UP_BOOKINGS_COMPLETE = 'Set Up Bookings Complete';

export const ACCOUNT_SIGN_UP_ERROR = 'Account Sign Up Error';
export const ACCOUNT_SIGN_UP_START = 'Account Sign Up Start';
export const ACCOUNT_SIGN_IN_COMPLETE = 'Account Sign In Complete';
export const ACCOUNT_SIGN_UP_COMPLETE = 'Account Sign Up Complete';
export const ACCOUNT_ONE_TAP_SHOWN = 'Account One Tap Shown';
export const ACCOUNT_ONE_TAP_DISMISSED = 'Account One Tap Dismissed';

export const PUBLIC_LANDS_MAP_CLICK = 'Public Lands Map Click';
export const MAP_VIEW_CLICK = 'Map View Click';
export const CELL_SERVICE_MAP_CLICK = 'Cell Service Map Click';
export const ROAD_TRIP_CLICK = 'Road Trip Click';
export const OFFLINE_MAPS_CLICK = 'Offline Maps Click';
export const FEATURE_CARD_CLICK = 'Feature Card Click';
export const FILTER_SELECT = 'Filter Select';
export const CAMPGROUND_SEARCH = 'Campground Search';
export const CAMPGROUND_PAGE_VIEW = 'Campground Page View';
export const DYRT_ALERTS_CTA_CLICK = 'Dyrt Alerts CTA Click';
export const DYRT_ALERTS_SEARCH_CLICK = 'Dyrt Alerts Search Click';
export const DYRT_ALERTS_CAMPGROUND_DIRECTORY =
  'Dyrt Alerts Browse Campground Directory';
export const MAP_SEARCH_PAGE_VIEW = 'Map Search Page View';
export const MAPS_TAB_OPEN = 'Maps Tab Open';
export const MAP_FILTER_OPEN = 'Map Filter Open';
export const MAP_PIN_CLICK = 'Map Pin Click';
export const BOOKING_CTA_CLICK = 'Booking CTA Click';
export const BOOKING_SITE_SELECT_COMPLETE = 'Booking Site Select Complete';
export const BOOKING_GUEST_DETAIL_COMPLETE = 'Booking Guest Detail Complete';
export const BOOKING_PAYMENT_INFO_COMPLETE = 'Booking Payment Info Complete';
export const BOOKING_UPSELL_PAGE_VIEW = 'Booking Upsell Page View';
export const BOOKING_UPSELL_SELECTION_COMPLETE =
  'Booking Upsell Selection Complete';
export const BOOKING_UPSELL_CHECKBOX_CLICK = 'Booking Upsell Checkbox Click';
export const BOOKING_COMPLETE = 'Booking Complete';
export const CAMPGROUND_SAVED_TO_LIST = 'Campground Saved to List';
export const PRO_CTA_CLICK = 'PRO CTA Click';
export const PRO_FEATURE_PAGE_VIEW = 'PRO Feature Page View';
export const PRO_CHECKOUT_PAGE_VIEW = 'PRO Checkout Page View';
export const PRO_PURCHASE_START = 'PRO Purchase Start';
export const PRO_PURCHASE_COMPLETE = 'PRO Purchase Complete';
export const SEO_PAGE_VIEW = 'SEO Page View';
export const GET_PERMIT_CLICK = 'Get Permit Click';

export const NEARBY_CAMPGROUND_CLICK = 'Nearby Campgrounds Click';
export const OPEN_GOOGLE_MAPS_CLICK = 'Open Google Maps Click';
export const COPY_DIRECTIONS_CLICK = 'Copy Directions Click';
export const COPY_COORDINATES_CLICK = 'Copy Coordinates Click';
export const COPY_ADDRESS_CLICK = 'Copy Address Click';
export const SHARE_REVIEW_CLICK = 'Share Review Click';
export const REPORT_REVIEW_CLICK = 'Report Review Click';
export const ENTER_CONTEST_CLICK = 'Enter Contest Click';
export const SHARE_CAMPGROUND_CLICK = 'Share Campground Click';
export const SAVE_CAMPGROUND_CLICK = 'Save Campground Click';
export const VIEW_ALL_MEDIA_CLICK = 'View All Media Click';
export const DOWNLOAD_APP_FOR_OFFLINE_CLICK = 'Download App for Offline Click';
export const HOMEPAGE_PAGE_VIEW = 'Homepage Page View';
export const DATES_SELECTED = 'Dates Selected';

export const MY_DYRT_ALERTS_PAGE_VIEW = 'My Dyrt Alerts Page View';
export const DYRT_ALERTS_LANDING_PAGE_VIEW = 'Dyrt Alerts Landing Page View';
export const DYRT_ALERTS_SELECT_CAMPGROUNDS_VIEW =
  'Dyrt Alerts Select Campgrounds View';
export const DYRT_ALERTS_SELECT_ARRIVAL_DATE_VIEW =
  'Dyrt Alerts Select Arrival Date View';
export const DYRT_ALERTS_SELECT_NIGHTS_VIEW = 'Dyrt Alerts Select Nights View';
export const DYRT_ALERTS_CHOOSE_SCAN_VIEW = 'Dyrt Alerts Choose Scan View';
export const DYRT_ALERTS_ADD_PHONE_VIEW = 'Dyrt Alerts Add Phone View';
export const DYRT_ALERTS_PAYMENT_VIEW = 'Dyrt Alerts Payment View';
export const DYRT_ALERTS_MONTH_WARNING_VIEW = 'Dyrt Alerts Month Warning View';
export const DYRT_ALERTS_SCAN_LIMIT_WARNING_VIEW =
  'Dyrt Alerts Scan Limit Warning View';
export const DYRT_ALERTS_CONFIRMATION_VIEW = 'Dyrt Alerts Confirmation View';
export const DYRT_ALERTS_SINGLE_SCAN_VIEW = 'Dyrt Alerts Single Scan View';
export const DYRT_ALERTS_PRO_STATUS_VIEW = 'Dyrt Alerts PRO Status View';
export const DYRT_ALERTS_VIEW_SINGLE_SCANS_CLICK =
  'Dyrt Alerts View Single Scans Click';
export const DYRT_ALERTS_CHOOSE_PRO_SCAN_VIEW =
  'Dyrt Alerts Choose PRO Scan View';
export const DYRT_ALERTS_CHOOSE_SINGLE_SCAN_CLICK =
  'Dyrt Alerts Choose Single Scan Click';

export const FREE_CAMPING_MAP_FILTER_OPEN = 'Free Camping Map Filter Open';
export const EXPLORE_THE_MAP_CLICK = 'Explore Map Click';

export const RESERVATION_STEP_EVENT_NAMES = {
  datesAndSiteType: BOOKING_SITE_SELECT_COMPLETE,
  paymentInfo: BOOKING_PAYMENT_INFO_COMPLETE,
  guests: BOOKING_GUEST_DETAIL_COMPLETE,
  checkout: BOOKING_COMPLETE,
} as const;

export const ADD_LOCATION = {
  START: 'Add Location Start',
  TYPE_CLICK: 'Add Location Type Click',
  UNSAVED_CHANGES_WARNING: 'Add Location Unsaved Changes Warning',
  DECLARE_MANAGER_VIEW: 'Add Location Declare Manager',
  NAME_VIEW: 'Add Location Name View',
  ALREADY_LISTED_VIEW: 'Add Location Already Listed View',
  MAP_VIEW: 'Add Location Map View',
  DETAILS_VIEW: 'Add Location Details View',
  COMPLETE: 'Add Location Complete',
  UNSAVED_WARNING: 'Add Location Unsaved Changes Warning',
};
