import React from 'react';

import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/AppProUpgradeButton.module.scss';

export interface AppProUpgradeLinkProps {
  className?: string;
  dataEvent: string;
  href: string;
  variant?: 'primary' | 'secondary';
  onClick?: () => void;
}

export const AppProUpgradeLink: React.FC<AppProUpgradeLinkProps> = ({
  className,
  dataEvent,
  href,
  variant = 'primary',
  onClick,
}: AppProUpgradeLinkProps) => {
  return variant === 'primary' ? (
    <a
      className={`${className} ${styles['redeem-link']}`}
      href={href}
      data-event={dataEvent}
      onClick={onClick}
    >
      Upgrade to
      <img
        className={styles[`redeem-link__pro-badge`]}
        src={`${PUBLIC_ASSETS_PATH}/assets/badges/pro-white.svg`}
        alt={`The Dyrt Pro`}
      />
    </a>
  ) : (
    <a
      className={`${className} ${styles['redeem-link-large']} ${styles['redeem-link-desktop']}`}
      href={href}
      data-event={dataEvent}
      onClick={onClick}
    >
      <span className={styles['redeem-link-large__title']}>
        Camp better with PRO Maps
      </span>
      <span>
        PRO Maps help you find free camping, check cell service and stay
        connected offline.
      </span>
      <div className={styles['redeem-link-large__upgrade']}>
        <span>Upgrade to PRO</span>
        <AppIcon
          classNameSvg={[styles['redeem-link-large__icon']]}
          icon="arrow_right"
        />
      </div>
    </a>
  );
};

export default AppProUpgradeLink;
