import React, { createContext, useState, useContext, useEffect } from 'react';
import Head from 'next/head';
import { Router } from 'next/router';
import Script from 'next/script';

import { setDimensionsAndLogOnRender } from 'utils/googleAds';
import { subdomain } from 'utils/subdomain';

import useOnResize from 'hooks/useOnResize';

const GoogleAdContext = createContext<GoogleAdContext>({
  ready: false,
});

const GoogleAdContextProvider: React.FC = ({ children }) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (ready) {
      try {
        googletag?.cmd?.push(function () {
          googletag.pubads?.().setTargeting?.('env', subdomain());
          googletag
            .pubads?.()
            ?.addEventListener('slotRenderEnded', setDimensionsAndLogOnRender);
        });

        const handleRouteChange = (url: string) => {
          if (url !== window.location.pathname) {
            if (typeof googletag?.pubads === 'function') {
              googletag?.pubads()?.clearTargeting();
              googletag?.pubads()?.setTargeting('env', [subdomain()]);
            }
          }
        };

        Router.events.on('routeChangeStart', handleRouteChange);

        return () => {
          Router.events.off('routeChangeStart', handleRouteChange);

          if (window?.googletag?.pubads) {
            window.googletag
              .pubads?.()
              ?.removeEventListener(
                'slotRenderEnded',
                setDimensionsAndLogOnRender
              );
          }
        };
      } catch (error) {
        console.error('Error setting up Google Ads event listeners', error);
      }
    }
  }, [ready]);

  useOnResize(() => {
    const { googletag } = window;
    googletag?.cmd?.push(() => {
      googletag.pubads().refresh();
    });
  }, 1500);

  return (
    <>
      <Head>
        <script
          type="text/javascript"
          id="googletagDefault"
        >{`window.googletag = window.googletag || {cmd: []};`}</script>
      </Head>
      <Script
        strategy="afterInteractive"
        src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        onReady={() => {
          setReady(true);
        }}
      />
      <GoogleAdContext.Provider value={{ ready }}>
        {children}
      </GoogleAdContext.Provider>
    </>
  );
};

export const useGoogleAdContext = () => useContext(GoogleAdContext);

export default GoogleAdContextProvider;
