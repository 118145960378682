/**
 * Local storage functions
 */
import add from 'date-fns/add';

import { UTM_TAGS } from 'constants/analytics';

export function checkExistingCoupon(): void {
  // verifies if a coupon in localStorage already has expired, and removes it if it has
  const lastCouponEvent = Number(localStorage.getItem('coupon_timestamp'));
  const timestamp = Date.now();
  // clear old coupon data if it has been more than 24 hrs
  if (timestamp - lastCouponEvent > 86400) {
    localStorage.removeItem('coupon_timestamp');
    localStorage.removeItem('coupon');
  }
}

export function stashCoupon(coupon: string): void {
  // adds a coupon to localStorage along with a timestamp of when it was added
  if (coupon === 'true') {
    return;
  }
  const timestamp = Date.now();
  localStorage.setItem('coupon_timestamp', String(timestamp));
  localStorage.setItem('coupon', coupon);
}

export function checkUTMParams(params: URLSearchParams): void {
  const lastUtmEvent = Number(localStorage.getItem('utm_timestamp'));
  const timestamp = Date.now();
  // clear old UTM data if it has been more than 24 hrs
  if (timestamp - lastUtmEvent > 86400) {
    clearUTMData();
  }
  if (UTM_TAGS.some((tag) => params.get(tag))) {
    // found incoming utm props
    clearUTMData();
    localStorage.setItem('utm_timestamp', String(timestamp));
    UTM_TAGS.forEach((tag) => {
      const tagValue = params.get(tag);
      if (tagValue) {
        localStorage.setItem(tag, tagValue);
      }
    });
  }
}

export function addUTMDataToParams(params: URLSearchParams) {
  UTM_TAGS.forEach((tag) => {
    const utmData = localStorage.getItem(tag);
    if (utmData) {
      params.set(tag, utmData);
    }
  });
}

export function clearUTMData() {
  localStorage.removeItem('utm_timestamp');
  UTM_TAGS.forEach((tag) => localStorage.removeItem(tag));
}

export function setMapLayersToLocalStorage(params: string[]) {
  localStorage.setItem('mapbox-layers', JSON.stringify(params));
}

export function getMapLayersFromLocalStorage() {
  const foundLayersData = localStorage.getItem('mapbox-layers');
  if (foundLayersData) {
    return JSON.parse(foundLayersData) as MapLayer[];
  } else {
    return null;
  }
}

export function setMapStyleToLocalStorage(style: string) {
  localStorage.setItem('mapbox-style', style);
}

export function getMapStyleFromLocalStorage() {
  return (localStorage.getItem('mapbox-style') || 'map') as MapStyleType;
}

export function hideCTAForHours(hours: number, localStorageLabel: string) {
  const timestamp = add(Date.now(), { hours });
  localStorage.setItem(localStorageLabel, String(timestamp));
}

export function isCTAHidden(localStorageLabel: string) {
  const hideUntil = localStorage.getItem(localStorageLabel);
  if (hideUntil) {
    const hideUntilDate = new Date(hideUntil);
    const now = new Date();
    if (now > hideUntilDate) {
      localStorage.removeItem(localStorageLabel);
    }
    return now < hideUntilDate;
  } else {
    return false;
  }
}

export function localStorageIsAllowed(): boolean {
  // tests localStorage so we can know if an operation is going to be authorized
  try {
    localStorage.setItem('test-for-localstorage-permissions', 'true');
    localStorage.removeItem('test-for-localstorage-permissions');
    return true;
  } catch {
    return false;
  }
}
