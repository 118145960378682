export const copyText = (text: string): void => {
  const textarea = document.createElement('textarea');
  textarea.textContent = text;
  textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
  textarea.style.bottom = '0';
  textarea.style.left = '0';
  document.body.appendChild(textarea);
  textarea.select();
  try {
    document.execCommand('copy'); // Security exception may be thrown by some browsers.
  } catch (ex) {
  } finally {
    document.body.removeChild(textarea);
  }
};
