import React from 'react';
import { useWatch } from 'react-hook-form';

import useAppFormInput from 'hooks/useFormInput';

import AppTextArea, { AppTextAreaProps } from 'components/AppInput/AppTextArea';

type AppFormTextAreaProps<T> = FormInputProps<T> &
  Omit<AppTextAreaProps, 'inputRef' | 'error' | 'onChange' | 'onBlur'>;

function AppFormTextArea<T = Record<string, unknown>>(
  props: AppFormTextAreaProps<T>
): React.ReactElement {
  const value = useWatch({ name: props.name });
  return useAppFormInput<AppTextAreaProps>(
    AppTextArea,
    props,
    props.maxLength || props.minLength ? () => ({ value }) : undefined
  );
}

export default AppFormTextArea;
