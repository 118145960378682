import { useRef } from 'react';
import debounce from 'lodash.debounce';

import { isServer } from 'utils/isServer';

import useMountEffect from './useMountEffect';

// Only use if onResizeFunction has no dependencies that are going to change.
// Only looks at width, not height. That's usually all we care about, and some mobile browsers resize height on scroll or keyboard open.
const useOnResize = (
  onResizeFunction: (e: UIEvent) => void,
  debounceDelay = 200
) => {
  const windowWidth = useRef<number | null>(null);

  useMountEffect(() => {
    if (isServer) return;

    windowWidth.current = window.innerWidth;

    const handleResize = debounce((e) => {
      const newWidth = e.currentTarget?.innerWidth;
      if (newWidth !== windowWidth.current) {
        onResizeFunction(e);
        windowWidth.current = newWidth;
      }
    }, debounceDelay);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
};
export default useOnResize;
