import React, { useState, useEffect } from 'react';
import { useSession, signIn } from 'next-auth/react';
import Script from 'next/script';
import * as amplitude from '@amplitude/analytics-browser';

import {
  ACCOUNT_ONE_TAP_SHOWN,
  ACCOUNT_SIGN_IN_COMPLETE,
} from 'constants/amplitude';

import isMobileDevice from 'utils/isMobileDevice';

import styles from './styles/OneTapComponent.module.scss';

const OneTapComponent = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const { status } = useSession();

  useEffect(() => {
    if (status === 'unauthenticated' && scriptLoaded) {
      const { google, innerWidth } = window;
      const isMobile = isMobileDevice() || innerWidth < 600;
      if (google && !isMobile) {
        google.accounts.id.initialize({
          client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID!,
          callback: async (response: OneTapSigninResponse) => {
            await signIn('googleonetap', {
              credential: response.credential,
              redirect: false,
              parentContainerId: 'oneTap',
            });
            amplitude.track(ACCOUNT_SIGN_IN_COMPLETE, {
              'sign in method': 'Google One Tap',
            });
          },
          use_fedcm_for_prompt: true,
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        google.accounts.id.prompt(() => {
          amplitude.track(ACCOUNT_ONE_TAP_SHOWN, {
            'sign in method': 'Google One Tap',
          });
        });
      }
    }
  }, [status, scriptLoaded]);

  return (
    <>
      <Script
        src="https://accounts.google.com/gsi/client"
        strategy="lazyOnload"
        onReady={() => {
          setScriptLoaded(true);
        }}
      />
      <div id="oneTap" className={styles['onetap']} />
    </>
  );
};

export default OneTapComponent;
