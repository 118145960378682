import { useContext } from 'react';

import { OverlayContext, OverlayContextValue } from 'contexts/overlay';

export function useOverlayContext<O extends Record<string, unknown>>() {
  return useContext(OverlayContext) as OverlayContextValue<O>;
}

export function useOverlay<O extends Record<string, unknown>>(
  overlayType?: OverlayType
) {
  const { overlays } = useOverlayContext();

  return overlays?.find(
    ({ type }) => type === overlayType || type === null
  ) as O;
}
