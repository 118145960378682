import React from 'react';

import styles from './styles/AppVideo.module.scss';

export interface AppVideoProps {
  youtubeUrl: string;
  title: string;
  className?: string;
  onLoad?(): void;
}

export const AppVideo: React.FC<AppVideoProps> = ({
  youtubeUrl,
  title,
  className,
  onLoad,
}: AppVideoProps) => {
  const videoUrl = `${youtubeUrl}?html5=1&modestbranding=1`;
  return (
    <iframe
      src={videoUrl}
      title={title}
      className={[styles['video'], className].join(' ')}
      allowFullScreen
      onLoad={() => {
        onLoad && onLoad();
      }}
    />
  );
};

export default AppVideo;
