import React from 'react';
import classNames from 'classnames/bind';
import { format } from 'date-fns';

import styles from './styles/SegmentedDates.module.scss';

const cx = classNames.bind(styles);

export interface SegmentedDatesProps {
  dates: [Date | null, Date | null];
}

export const _SegmentedDates: React.FC<SegmentedDatesProps> = ({ dates }) => {
  const leftClassNames = cx({
    dates__segment: true,
    'dates__segment-left': true,
    'dates__segment-active': !dates[0],
  });

  const rightClassNames = cx({
    dates__segment: true,
    'dates__segment-right': true,
    'dates__segment-active': !!dates[0] && !dates[1],
  });

  return (
    <div className={styles['dates']}>
      <span className={leftClassNames}>
        <span className={styles['dates__label']}>Check-in</span>

        <div className={styles['dates__date']}>
          {dates[0] ? format(dates[0], 'MM/dd/yyyy') : 'Add Date'}
        </div>
      </span>

      <span
        className={`${styles['dates__divider']} ${
          (!dates[0] || !dates[1]) && styles['dates__divider-active']
        }`}
      />
      <span className={rightClassNames}>
        <span className={styles['dates__label']}>Checkout</span>
        <div className={styles['dates__date']}>
          {dates[1] ? format(dates[1], 'MM/dd/yyyy') : 'Add Date'}
        </div>
      </span>
    </div>
  );
};

export default _SegmentedDates;
