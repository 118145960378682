import React, { useEffect } from 'react';
import debounce from 'lodash.debounce';

import { DatesPopoverContextProvider } from 'contexts/datesPopover';

import { isServer } from 'utils/isServer';

import { useModalOrPopover } from 'hooks/useModalOrPopover';
import { useOverlay, useOverlayContext } from 'hooks/useOverlayContext';

import AppDatesSelectContent from 'components/AppDatesSelect/AppDatesSelectContent';
import AppModal from 'components/AppModal/AppModal';

export const AppDatesSelectModal: React.FC = () => {
  const overlay = useOverlay<DateRangePickerOverlay>('date-range-picker');

  const { close } = useOverlayContext<DateRangePickerOverlay>();

  const shouldUseModal = useModalOrPopover();

  useEffect(() => {
    if (isServer) {
      return;
    }

    const onResize = debounce(() => {
      if (overlay && !shouldUseModal()) {
        close();
      }
    }, 500);

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [close, shouldUseModal, overlay]);

  if (!overlay) return null;

  const {
    context: { dates, setDates },
    rightButton,
  } = overlay;

  return (
    <AppModal
      type="date-range-picker"
      padded={false}
      disableClose
      content={
        <DatesPopoverContextProvider
          startDate={dates[0]}
          endDate={dates[1]}
          onDateSelected={setDates}
        >
          <AppDatesSelectContent rightButton={rightButton} close={close} />
        </DatesPopoverContextProvider>
      }
    />
  );
};

export default AppDatesSelectModal;
