import { useRouter } from 'next/router';

import webToAppLink from 'utils/webToAppUrl';

// Returns link url with canonical url and desktop url params set to current pathname.
//   If those dynamic params are not needed, use utils/webToAppLink
const useDynamicWebToAppLink = ({
  featureParam,
  includeCanonicalUrl = false,
  includeDesktopUrl = false,
}: {
  featureParam: string;
  includeCanonicalUrl?: boolean;
  includeDesktopUrl?: boolean;
}) => {
  const { asPath } = useRouter();

  return webToAppLink({
    featureParam,
    canonicalUrl: includeCanonicalUrl ? `https://thedyrt.com${asPath}` : '',
    desktopUrl: includeDesktopUrl ? `https://thedyrt.com${asPath}` : '',
  });
};

export default useDynamicWebToAppLink;
