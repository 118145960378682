import React from 'react';
import { isSameDay } from 'date-fns';

import { useDatesPopoverContext } from 'contexts/datesPopover';

import { useModalOrPopover } from 'hooks/useModalOrPopover';

import AppButton from 'components/AppButton/AppButton';
import AppInputDatePicker from 'components/AppInput/AppInputDatePicker';

import _SegmentedDates from './_SegmentedDates';

import styles from './styles/AppDatesSelectContent.module.scss';

interface AppDatesSelectContent {
  className?: string;
  rightButton: JSX.Element;
  close: () => void;
}

export const AppDatesSelectContent: React.FC<AppDatesSelectContent> = ({
  className,
  rightButton,
  close,
}) => {
  const { dates, setDates, close: closeRef } = useDatesPopoverContext();

  const shouldUseModal = useModalOrPopover();

  const useModal = shouldUseModal();

  closeRef.current = close;

  return (
    <div className={`${className} ${styles['date-select-content']}`}>
      <header className={styles['date-select-content__header']}>
        <h3 className={styles['date-select-content__title']}>Add dates</h3>
        <_SegmentedDates dates={dates} />
      </header>
      <AppInputDatePicker
        selected={dates[0]}
        startDate={dates[0]}
        endDate={dates[1]}
        size="large"
        onChange={(dates: [Date | null, Date | null]) => {
          if (
            dates[0] instanceof Date &&
            dates[1] instanceof Date &&
            isSameDay(dates[0], dates[1])
          ) {
            // ensure that we get two different dates
            setDates([dates[0], null]);
          } else {
            setDates(dates);
          }
        }}
        name="date-range"
        // @ts-ignore because this should be a boolean but the type definition is wrong
        selectsRange
        inline
        minDate={new Date()}
        monthsShown={useModal ? 1 : 2}
      />

      <div className={styles['date-select-content__button-container']}>
        <div>
          {!!dates[0] && (
            <AppButton
              type={'button'}
              label="Clear"
              ghost
              borderless
              onClick={() => {
                setDates([null, null]);
              }}
              dataEvent="clear-date-range-picker"
            />
          )}
        </div>
        {dates[0] && !dates[1] ? null : rightButton}
      </div>
    </div>
  );
};

export default AppDatesSelectContent;
