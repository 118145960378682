import React, { InputHTMLAttributes } from 'react';
import { RefCallBack } from 'react-hook-form';
import classNames from 'classnames/bind';

import styles from './styles/AppInput.module.scss';

const cx = classNames.bind(styles);

export interface AppTextAreaProps
  extends InputHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  label?: string;
  inputRef?: React.Ref<HTMLTextAreaElement> | RefCallBack;
  hideErrorMessage?: boolean;
  error?: string | null;
  name: string;
  rows?: number;
}

export const AppInputText: React.FC<AppTextAreaProps> = ({
  className,
  error,
  hideErrorMessage = false,
  inputRef,
  label,
  name,
  onChange,
  onBlur,
  onKeyUp,
  placeholder,
  required,
  value,
  disabled,
  maxLength,
  minLength,
  rows = 4,
}) => {
  const InputClassNames = cx({
    input__field: true,
    'input__text-area': true,
    'input__field--has-error': error,
  });

  const LabelClassNames = cx({
    input__label: true,
    'input__text-area': true,
    'input__label--disabled': disabled,
    'input__label--required': required,
  });

  return (
    <label
      className={`${styles.input} ${styles['input--is-full-width']} ${className}`}
    >
      <span className={LabelClassNames}>{label}</span>
      <textarea
        className={InputClassNames}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        ref={inputRef}
        required={required}
        value={value}
        disabled={disabled}
        rows={rows}
        maxLength={maxLength}
        minLength={minLength}
      />
      {(maxLength || minLength) && (
        <div className={styles['input__text-area-count']}>
          {!!minLength && <span>{`${minLength} characters required`}</span>}
          {!!maxLength && `${value ? ('' + value).length : '0'} / ${maxLength}`}
        </div>
      )}
      {error && !hideErrorMessage && (
        <span className={styles['input__error-message']}>{error}</span>
      )}
    </label>
  );
};

export default AppInputText;
