const managerPortalStateFromActiveClaim = (
  claim?: ClaimModel | null
): ManagerData['managerPortalState'] => {
  switch (claim?.managerPortalState) {
    case 'new_book_bookings':
    case 'rentals_united_bookings':
    case 'res_nexus_bookings':
      return 'pms_bookings';
    case 'dyrt_bookings':
      return 'dyrt_bookings';
    default:
      if (claim?.bookingIntention === 'claim_and_bookings') {
        return 'standby';
      }
      return 'no_bookings';
  }
};

export default managerPortalStateFromActiveClaim;
