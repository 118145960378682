import React from 'react';

import AppFormDropdown from 'components/AppForm/AppFormDropdown';
import AppFormMultiSelect from 'components/AppForm/AppFormMultiSelect';
import AppFormRadio from 'components/AppForm/AppFormRadio';
import AppStarRating from 'components/AppStarRating/AppStarRating';

import styles from './styles/_SidebarFiltersFilter.module.scss';

export interface FilterProps {
  className?: string;
  searchFilter: SearchFilter;
  disabled?: boolean;
  descriptionClassName?: string;
}

const _SidebarFiltersFilter: React.FC<FilterProps> = ({
  searchFilter,
  disabled,
  descriptionClassName,
}: FilterProps) => {
  const getBulletedDescription = () => {
    const bullets = searchFilter.options[0]?.description?.split(',');
    return (
      <ul>
        {bullets?.map((bullet) => (
          <li key={bullet} className={styles['filter__bulleted-description']}>
            {bullet}
          </li>
        ))}
      </ul>
    );
  };

  const multiSelect = () => {
    return (
      <>
        <AppFormMultiSelect
          options={searchFilter.options}
          name={searchFilter.id}
          labelClassName={styles['filter__label']}
          disabled={disabled}
          labelAccessor="displayName"
          valueAccessor="value"
          bulletedDescription={searchFilter.key === 'free_or_dispersed'}
          descriptionClassName={descriptionClassName}
          labelLarge
          greyedOutBox
        />

        {searchFilter.key === 'free_or_dispersed' && (
          <>{getBulletedDescription()}</>
        )}
      </>
    );
  };

  const radio = () => {
    return searchFilter.options.map((option) => {
      const showRatingStars = isNaN(Number(option.value)) ? (
        option.displayName
      ) : (
        <AppStarRating rating={Number(option.value)} size="md" />
      );

      return (
        <AppFormRadio
          key={option.value}
          className="m-t-md"
          label={
            searchFilter.id === 'rating' ? showRatingStars : option.displayName
          }
          value={option.value}
          name={searchFilter.id}
          disabled={disabled}
          isDescOrder={true}
          useCase="filter-select"
        />
      );
    });
  };

  const select = () => {
    return (
      <>
        <AppFormDropdown
          options={searchFilter.options}
          valueAccessor="value"
          labelAccessor="displayName"
          name={searchFilter.id}
          className={styles['filter__dropdown']}
        />

        {searchFilter.description && <p>{searchFilter.description}</p>}
      </>
    );
  };

  const controls = () => {
    switch (searchFilter.controlType) {
      case 'multi_select':
        return multiSelect();
      case 'radio':
        return radio();
      case 'select':
      case 'rating':
        return select();
      default:
        return <div>{searchFilter.name}</div>;
    }
  };

  return <>{controls()}</>;
};

export default _SidebarFiltersFilter;
