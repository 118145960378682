import { useCallback } from 'react';
import { DeepMap, DeepPartial } from 'react-hook-form';

const useGetFormChangeset = <F extends DyrtFormData>() => {
  const getFormChangeset = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-types
    (
      currentValues: Partial<F>,
      dirtyFields?: DeepMap<DeepPartial<F>, boolean>,
      defaultValues?: Partial<F>
    ) => {
      // only send changeset to API when `defaultValues` is passed in
      if (defaultValues && defaultValues.id && defaultValues.type) {
        const dirtied = Object.keys(dirtyFields || {});
        if (dirtied.length) {
          return dirtied.reduce<Record<string, unknown>>(
            (acc, key) => {
              acc[key] = currentValues[key];
              return acc;
            },
            { id: defaultValues.id, type: defaultValues.type }
          );
        } else {
          // do nothing, the user has not touched form fields
          return {} as Partial<F>;
        }
      }

      return currentValues;
    },
    []
  );

  return getFormChangeset;
};

export default useGetFormChangeset;
