import React from 'react';
import Image from 'next/image';

import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import { useOverlay } from 'hooks/useOverlayContext';

import AppModal from './AppModal';

import styles from './styles/AppModal.module.scss';

const DyrtAlertsProUpgrade: React.FC = () => {
  const overlay = useOverlay<AppConfirmationOverlay>(
    'dyrt-alerts-pro-upgrade'
  ) as AppConfirmationOverlay;

  const { footerButtons, footerButtonsCenter, action } = overlay || {};
  const { proScanQuantity } = window || {};

  const title = action === 'resume' ? 'Upgrade to Resume' : 'Upgrade to Edit';

  return (
    <AppModal
      type="dyrt-alerts-pro-upgrade"
      className={styles['dyrtalerts-pro-upgrade-modal']}
      footerButtons={footerButtons}
      footerButtonsCenter={footerButtonsCenter}
      footerPadded
      fullScreenMobile={false}
      padded={false}
      content={
        <div
          className={styles['dyrtalerts-pro-upgrade-modal__content-container']}
        >
          <div className={styles['dyrtalerts-pro-upgrade-modal__content']}>
            <section
              className={
                styles[
                  'dyrtalerts-pro-upgrade-modal__content-camper-containerText'
                ]
              }
            >
              <div
                className={styles['dyrtalerts-pro-upgrade-modal__content-text']}
              >
                <p
                  className={
                    styles['dyrtalerts-pro-upgrade-modal__content-description']
                  }
                >
                  {title}
                </p>
                <p
                  className={
                    styles[
                      'dyrtalerts-pro-upgrade-modal__content-sub-description'
                    ]
                  }
                >
                  PRO gives you more alerts and the ability to {action} your
                  scans. PRO also gives you:
                </p>
                <ul className={styles['dyrtalerts-pro-upgrade-modal__list']}>
                  <li>{proScanQuantity} reusable PRO Dyrt Alerts</li>
                  <li>Use PRO Dyrt Alerts over and over again</li>
                  <li>50% off additional single scans</li>
                  <li>
                    All other PRO features (ie. maps, discounts and free camping
                    collection)
                  </li>
                </ul>
              </div>
            </section>
            <section
              className={
                styles[
                  'dyrtalerts-pro-upgrade-modal__content-camper-containerImage'
                ]
              }
            >
              <Image
                src={`${PUBLIC_ASSETS_PATH}/assets/images/dyrt-alerts/phone-illustration@3x.png`}
                alt={`Phone illustration image`}
                width={160}
                height={160}
              />
            </section>
          </div>
        </div>
      }
    />
  );
};
export default DyrtAlertsProUpgrade;
