import { useState, useCallback, useMemo } from 'react';
import isEmpty from 'lodash.isempty';

import { updateRecord as updateRecordUtil } from 'utils/JSONAPIAdapter';

import useAuthenticationHeader from './useAuthenticationHeaders';
import useHandleApiError from './useHandleApiError';

interface State {
  errors: string[] | null;
  loading: boolean;
}

const useUpdateRecord = () => {
  const authenticationHeader = useAuthenticationHeader();

  const handleApiError = useHandleApiError();

  const [state, setState] = useState<State>({ loading: false, errors: null });

  const updateRecord = useCallback(
    async function <T extends DyrtModel, V extends DyrtModel = DyrtModel>(
      record: Partial<T>,
      relationships?: Record<string, JSONAPIRelationship>,
      onSuccess?: (data: T, included?: V[]) => unknown | Promise<unknown>,
      options: JSONAPIOptions = {}
    ) {
      if (isEmpty(record)) {
        if (onSuccess) {
          onSuccess({} as T);
        }

        return { record: {} as T, errors: null };
      }

      setState({ loading: true, errors: null });

      try {
        const response = await updateRecordUtil<T>(
          record,
          options,
          authenticationHeader,
          relationships
        );

        setState({
          loading: false,
          errors: null,
        });

        if (onSuccess) {
          onSuccess(response.data as T, response.included as V[]);
        }

        return {
          record: response.data as T,
          errors: null,
          included: response.included as V[],
        };
      } catch (err) {
        const errors = handleApiError(err);

        setState({
          errors,
          loading: false,
        });

        return { record: null, errors };
      }
    },
    [setState, authenticationHeader, handleApiError]
  );

  const clearErrors = useCallback(
    () => setState({ ...state, errors: null }),
    [state]
  );

  return useMemo(() => {
    return {
      updateRecord,
      clearErrors,
      ...state,
    };
  }, [updateRecord, state, clearErrors]);
};

export default useUpdateRecord;
