import { useUser } from 'contexts/currentUser';

import useCreateRecord from './useCreateRecord';

export const useCreateFlag = () => {
  const { createRecord, errors } = useCreateRecord();
  const { user } = useUser();

  const createFlag = async (
    subject: DyrtModel,
    reason = '',
    reasonDetails = ''
  ) => {
    const relationships: Record<string, JSONAPIRelationship> = {
      subject: { data: { id: subject.id, type: subject.type } },
    };
    if (user) {
      relationships.user = { data: { id: user.id, type: 'users' as const } };
    }

    const response = await createRecord<FlagModel>(
      {
        type: 'flags',
        reason: [reason, reasonDetails]
          .filter((item) => item?.length)
          .join(': '),
      },
      relationships
    );
    return response;
  };

  return { createFlag, errors };
};

export default useCreateFlag;
