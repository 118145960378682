import React, { useCallback, useState } from 'react';
import { useRouter } from 'next/router';

import { OTHER } from 'constants/cancellationReasons';

import { useUser } from 'contexts/currentUser';

import useCreateRecord from 'hooks/useCreateRecord';
import { useOverlay } from 'hooks/useOverlayContext';

import AppButton from 'components/AppButton/AppButton';
import AppForm from 'components/AppForm/AppForm';
import AppFormError from 'components/AppForm/AppFormError';
import CancelReservationsForm from 'components/AppFormCollection/Reservations/CancelReservationsForm';
import AppModal from 'components/AppModal/AppModal';
import ManagerSupportFooterOneline from 'components/ManagerSupportFooter/ManagerSupportFooterOneline';

import styles from './styles/ManagerReservationsModal.module.scss';

const DEFAULT_NOTE =
  'Unfortunately, the dates of your reservation are no longer available due to unforeseen circumstances. Please accept our apologies for the inconvenience.';

export const _Cancel: React.FC = () => {
  const { user } = useUser();

  const {
    query: { campground },
    push,
  } = useRouter();

  const overlay = useOverlay<ManagerReservationOverlay>(
    'manager-reservation-cancel'
  );

  const { reservation } = overlay || {};
  const [showSubmitButton, setShowSubmitButton] = useState(
    !!reservation?.cancellationReason
  );

  const navigateToReservations = useCallback(
    () => push(`/manager/campgrounds/${campground}/reservations`),
    [campground, push]
  );

  const {
    errors: noteErrors,
    createRecord: createNoteRecord,
    loading: loadingNote,
  } = useCreateRecord();

  const {
    errors: cancellationErrors,
    createRecord: createCancellationRecord,
    loading: loadingCancellation,
  } = useCreateRecord();

  const defaultValues = {
    ...reservation,
    cancelledBy: reservation.cancelledBy || 'customer',
    note: DEFAULT_NOTE,
    otherReason: reservation.cancellationReason,
  };

  const onSubmit = useCallback(
    async (formState: ReservationCancellationForm) => {
      const {
        note: dirtyNote,
        otherReason,
        cancellationReason,
        cancelledBy,
      } = formState;

      const note =
        typeof dirtyNote === 'string' ? dirtyNote.trim() : DEFAULT_NOTE;

      if (note) {
        await createNoteRecord({
          type: 'cancellation-notes',
          body: note,
          reservationId: reservation?.id,
          userId: user?.id,
        });
      }

      const { record } =
        await createCancellationRecord<ReservationCancellationModel>({
          type: 'reservation-cancellations',
          reservationId: reservation?.id,
          cancellationReason:
            cancellationReason === OTHER ? otherReason : cancellationReason,
          cancelledBy: cancelledBy || defaultValues.cancelledBy,
        });
      if (record?.id) {
        navigateToReservations();
      }
    },
    [
      createCancellationRecord,
      createNoteRecord,
      navigateToReservations,
      reservation?.id,
      user?.id,
      defaultValues.cancelledBy,
    ]
  );

  return (
    <AppForm<ReservationCancellationForm>
      onSubmit={onSubmit}
      formOptions={{ defaultValues }}
    >
      <AppModal
        type="manager-reservation-cancel"
        padded={false}
        footerButtons={
          showSubmitButton ? (
            <AppButton
              label="Cancel Now"
              dataEvent="manager_cancel_reservation_submit"
              disabled={loadingCancellation || (loadingNote && 'loading')}
              type="submit"
            />
          ) : null
        }
        content={
          <div className={styles['reservations-modal']}>
            <div className={styles['reservations-modal__content']}>
              <h3 className={styles['reservations-modal__headline']}>
                Cancel Booking {reservation.confirmationCode}
              </h3>
              <section className={styles['cancellation-policy']}>
                <h4>{reservation.cancellationTitle} Cancellation Policy</h4>
                <p>{reservation.cancellationDescription}</p>
              </section>
              <CancelReservationsForm
                reservation={reservation}
                setShowSubmitButton={setShowSubmitButton}
              />
            </div>
            <AppFormError errors={cancellationErrors} />
            <AppFormError errors={noteErrors} />
            {!showSubmitButton && <ManagerSupportFooterOneline />}
          </div>
        }
      />
    </AppForm>
  );
};

export default _Cancel;
