import React, { useEffect } from 'react';
import Image from 'next/image';
import * as amplitude from '@amplitude/analytics-browser';
import startCase from 'lodash.startcase';

import { WEB_TO_APP_CLICK } from 'constants/amplitude';
import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import { hideCTAForHours } from 'utils/localStorage';
import webToAppLink from 'utils/webToAppUrl';

import { useOverlay } from 'hooks/useOverlayContext';

import AppButton from 'components/AppButton/AppButton';
import AppButtonLink from 'components/AppButton/AppButtonLink';

import AppModal from './AppModal';

import styles from './styles/InterstitialModal.module.scss';

const InterstitialModal: React.FC = () => {
  const overlay = useOverlay<InterstitialModal>('interstitial-modal');

  const { pathContinue, toggleModal, featureParam } = overlay || {};

  useEffect(() => {
    hideCTAForHours(12, 'hide-see-in-app-until');
  }, []);

  return (
    <AppModal
      disableClose
      type="interstitial-modal"
      content={
        <div className={styles['interstitial-modal']}>
          <Image
            src={`${PUBLIC_ASSETS_PATH}/assets/brand/horizontal/logo-color_brown.svg`}
            alt="The Dyrt – find campgrounds for tents, RVs, cabins, glamping and more."
            width={184}
            height={51}
            loading="eager"
          />
          <div className={styles['interstitial-modal__button-container']}>
            <AppButtonLink
              className={styles['interstitial-modal__app-open-button']}
              label={'Open in The Dyrt app'}
              path={webToAppLink({ featureParam })}
              dataEvent={'interstitial_web_to_app_button'}
              onClick={() => {
                amplitude.track(WEB_TO_APP_CLICK, {
                  'page name': startCase(featureParam),
                  'click label': 'Open in The Dyrt app',
                });
              }}
              fullWidth
            />
            {pathContinue ? (
              <AppButtonLink
                ghost
                borderless
                className={styles['interstitial-modal__continue-button']}
                label={'Continue to the mobile site'}
                path={pathContinue}
                dataEvent={'interstitial_continue_to_site'}
                fullWidth
              />
            ) : (
              <AppButton
                type={'button'}
                ghost
                borderless
                className={styles['interstitial-modal__continue-button']}
                label={'Continue to the mobile site'}
                dataEvent={'interstitial_continue_to_site'}
                onClick={() => {
                  toggleModal();
                }}
                fullWidth
              />
            )}
          </div>
        </div>
      }
    />
  );
};
export default InterstitialModal;
