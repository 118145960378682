import React from 'react';

import styles from './styles/ManagerSupportFooter.module.scss';

export const ManagerSupportFooterOneline: React.FC = () => {
  return (
    <div className={styles['contact--small']}>
      {`We're here to help. `}
      <a
        href="https://support.thedyrt.com/hc/en-us"
        className={styles['contact__link']}
      >
        Visit our FAQs
      </a>{' '}
      or{' '}
      <a className={styles['contact__link']} href="mailto:support@thedyrt.com">
        Contact Us
      </a>
    </div>
  );
};

export default ManagerSupportFooterOneline;
