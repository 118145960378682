import React from 'react';

import { useOverlay } from 'hooks/useOverlayContext';

import ShareLinks from './_ShareLinks';

import AppModal from './AppModal';

import styles from './styles/AppModal.module.scss';

const AppShareModal: React.FC = () => {
  const overlay = useOverlay<ShareOverlay>('share');

  const { title, url: urlToShare, text: textToShare } = overlay || {};

  return (
    <AppModal
      type="share"
      title={title}
      content={
        <div className={styles['modal__share']}>
          <ShareLinks textToShare={textToShare} urlToShare={urlToShare} />
        </div>
      }
    />
  );
};

export default AppShareModal;
