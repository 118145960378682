import React, { useCallback, useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';
import {
  FieldValues,
  SubmitHandler,
  useForm,
  UseFormProps,
  FormProvider,
  UnpackNestedValue,
  DeepMap,
  DeepPartial,
} from 'react-hook-form';

import useGetFormChangeset from 'hooks/useGetFormChangeset';
import useHandleApiError from 'hooks/useHandleApiError';

import styles from './styles/AppForm.module.scss';

export interface AppFormProps<F extends DyrtFormData> {
  children: React.ReactNode;
  className?: string;
  onSubmit?: AppFormSubmitHandler<F>;
  onError?: (errors: Record<string, unknown>) => void;
  formOptions?: UseFormProps<FieldValues, Record<string, unknown>>;
  id?: string;
  focusInputName?: string;
  resetValues?: Partial<F>; // To clear form completely on submit, resetValues={{}}
  afterSubmit?: () => void;
}

export function AppForm<F extends DyrtFormData = DyrtFormData>({
  children,
  className,
  formOptions,
  id,
  onError,
  onSubmit = () => {},
  focusInputName,
  resetValues,
  afterSubmit,
}: AppFormProps<F>): React.ReactElement {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    ...formOptions,
  });

  const { defaultValues } = formOptions || {};

  // Read the formState before render to subscribe the form state through the Proxy
  const {
    formState: { dirtyFields, isSubmitSuccessful },
    setFocus,
    reset,
    setError,
    clearErrors,
    handleSubmit,
    getValues,
  } = methods;

  const handleApiError = useHandleApiError();
  const getFormChangeset = useGetFormChangeset<F>();

  const beforeSubmitCallbacks = useRef<Map<string, BeforeSubmitCallback<F>>>(
    new Map()
  );

  useEffect(() => {
    focusInputName && setFocus(focusInputName);
  }, [setFocus, focusInputName]);

  useEffect(() => {
    if (isSubmitSuccessful) afterSubmit?.();
  }, [isSubmitSuccessful, afterSubmit]);

  const addBeforeSubmitCallback = useCallback((cb: BeforeSubmitCallback<F>) => {
    beforeSubmitCallbacks.current.set(cb.name, cb);
  }, []);

  const removeBeforeSubmitCallback = useCallback(
    (cb: BeforeSubmitCallback<F>) => {
      beforeSubmitCallbacks.current.delete(cb.name);
    },
    []
  );

  const onSubmitCallback: AppFormSubmitHandler<F> = async (values, e) => {
    try {
      await onSubmit(
        getFormChangeset(
          values as Partial<F>,
          dirtyFields as DeepMap<DeepPartial<F>, boolean>,
          defaultValues as Partial<F> | undefined
        ) as UnpackNestedValue<F>,
        e
      );

      resetValues ? reset(resetValues) : reset(values);
    } catch (err) {
      const message = handleApiError(err)[0];
      setError('submit', {
        type: 'custom',
        message,
      });
      Sentry.captureException(message);
    }
  };

  const handleSubmitCallback = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    clearErrors('submit');
    const values = getValues();

    for (const cb of beforeSubmitCallbacks.current.values()) {
      await cb(values as F);
    }

    handleSubmit(onSubmitCallback as SubmitHandler<FieldValues>, onError)(e);
  };

  return (
    <FormProvider
      {...methods}
      addBeforeSubmitCallback={addBeforeSubmitCallback}
      removeBeforeSubmitCallback={removeBeforeSubmitCallback}
    >
      <form
        id={id}
        className={`${styles['component']} ${className}`}
        onSubmit={handleSubmitCallback}
      >
        {children}
      </form>
    </FormProvider>
  );
}

export default AppForm;
