import React from 'react';

import { FILTERS_MODAL_WRAPPER_ID, FILTERS_PANEL_ID } from 'constants/search';

import { useSearch } from 'contexts/search';

import { useOverlayContext } from 'hooks/useOverlayContext';

import AppForm from 'components/AppForm/AppForm';
import AppIcon from 'components/AppIcon/AppIcon';
import AppModal from 'components/AppModal/AppModal';

import _SidebarFiltersGroup from './_SidebarFiltersGroup';
import _SidebarFilterActions from './_SidebarFilterActions';

import styles from './styles/SearchCampgrounds.module.scss';

const _SidebarFiltersModal: React.FC = () => {
  const { updateSearchContext, attributeFilter, searchFilterGroups, search } =
    useSearch();

  const { close } = useOverlayContext();

  const onSubmit: AppFormSubmitHandler<AppliedAttributeFilter> = (values) => {
    search(values);
    close();
  };

  return attributeFilter ? (
    <AppForm<AppliedSearchFilter>
      formOptions={{ defaultValues: attributeFilter }}
      onSubmit={onSubmit}
    >
      <AppModal
        type="sidebar-filters"
        disableClose
        padded={false}
        articleId={FILTERS_MODAL_WRAPPER_ID}
        footerButtons={<_SidebarFilterActions />}
        content={
          <div id={FILTERS_PANEL_ID} className={styles['filters-panel']}>
            <div className={styles['filters-panel__header']}>
              <h4 className={styles['filters-panel__title']}>Filters</h4>
              <AppIcon
                icon="close_x"
                classNameWrapper={styles['filters-panel__exit']}
                classNameSvg={[styles['filters-panel__exit-icon']]}
                onClick={() => {
                  updateSearchContext({ activePanel: 'results' });
                  close();
                }}
              />
            </div>

            <div className={styles['filters-panel__body']}>
              {searchFilterGroups.map((searchFilterGroup, index) => {
                if (searchFilterGroup.type === 'search-filter-group') {
                  return (
                    <_SidebarFiltersGroup
                      className={styles['filters-panel__list']}
                      key={searchFilterGroup.name + index}
                      searchFilterGroup={searchFilterGroup}
                    />
                  );
                }
                return null;
              })}
            </div>
          </div>
        }
      />
    </AppForm>
  ) : null;
};

_SidebarFiltersModal.displayName = '_SidebarFilters';

export default _SidebarFiltersModal;
