import React from 'react';

import { formatReadableDate } from 'utils/dates';
import getMonthFromNumber from 'utils/getMonthFromNumber';

import AppAvatar from 'components/AppAvatar/AppAvatar';
import AppDivider from 'components/AppDivider/AppDivider';
import AppMarkdown from 'components/AppMarkdown/AppMarkdown';
import AppStarRating from 'components/AppStarRating/AppStarRating';
import CampgroundDetailsReviewInfo from 'components/CampgroundDetailsReviewInfo/CampgroundDetailsReviewInfo';

import styles from './styles/AppMediaViewer.module.scss';

export interface AppMediaContentProps {
  currentItem: MediaItemModel;
  campground: CampgroundModel;
  reviews: ReviewModel[];
}

export const AppMediaContent: React.FC<AppMediaContentProps> = ({
  currentItem,
  campground,
  reviews,
}: AppMediaContentProps) => {
  const { reviewId, caption, createdAt } = currentItem;

  const review =
    reviews && reviewId
      ? reviews.find((review) => review.id === reviewId)
      : null;

  return (
    <div className={styles['media-viewer__content']}>
      <div className={styles['media-viewer__content-interior']}>
        <div className={styles['media-viewer__details']}>
          <div className={styles['media-viewer__info']}>
            {currentItem.author && (
              <AppAvatar
                user={currentItem.author as UserModel}
                size="sm"
                linkToUser
              />
            )}
            {currentItem.createdAt && (
              <div className={styles['media-viewer__infoDesktop']}>
                <span className={styles['media-viewer__date']}>Uploaded</span>
                <br />
                {formatReadableDate(`${currentItem.createdAt}`)}
              </div>
            )}
          </div>

          {caption && (
            <>
              <AppMarkdown
                content={caption}
                className={styles['media-viewer__caption']}
              />
              {!!review && (
                <AppDivider
                  className={styles['media-viewer__caption--devider']}
                />
              )}
            </>
          )}
          {!!review && (
            <>
              <header>
                <div className={styles['media-viewer__reviewRating']}>
                  <AppStarRating rating={review.rating || 0} />
                  {review.createdAt && (
                    <span className={styles['media-viewer__reviewLabel']}>
                      Reviewed {formatReadableDate(review.createdAt, true)}
                    </span>
                  )}
                </div>
                <h4 className={styles['media-viewer__reviewTitle']}>
                  {review.title}
                </h4>
              </header>
              <AppMarkdown content={review.body} />

              <div className={styles['media-viewer__reviewSite']}>
                {review.site && (
                  <div>
                    <h3>Site</h3> <p>{review.site}</p>
                  </div>
                )}

                {review.month && (
                  <div>
                    <h3>Month of Visit</h3>
                    <p>{getMonthFromNumber(review.month)}</p>
                  </div>
                )}
              </div>
            </>
          )}
          {campground && (
            <CampgroundDetailsReviewInfo
              campground={campground}
              className={styles['media-viewer__reviewInfo']}
              titleClassName={styles['media-viewer__reviewInfo--title']}
            />
          )}

          {createdAt && (
            <div className={styles['media-viewer__infoMobile']}>
              <span className={styles['media-viewer__date']}>Uploaded</span>
              <br />
              {formatReadableDate(`${createdAt}`)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppMediaContent;
