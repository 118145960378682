import { useCallback } from 'react';

import { useUser } from 'contexts/currentUser';

import { loadRecords } from 'utils/JSONAPIAdapter';

import useAuthenticationHeader from 'hooks/useAuthenticationHeaders';

// Fetches campgrounds for a logged in user in _app.tsx
const useFetchClaims = () => {
  const { user } = useUser();
  const authHeaders = useAuthenticationHeader();

  const getAdminClaimByRouter = useCallback(
    async (slug: string) => {
      const { data: campgrounds, included: claims } = await loadRecords<
        CampgroundModel,
        ClaimModel
      >(
        'campgrounds',
        {
          filter: {
            slug,
          },
          include: 'campground-claims',
        },
        authHeaders
      );

      return {
        adminCampground: claims?.[0] ? campgrounds[0] : undefined,
        adminClaim: claims?.[0],
      };
    },
    [authHeaders]
  );

  // Check if user is manager and set manager context
  const fetchClaims = useCallback(
    async (slug?: string) => {
      if (!user?.id) return {};

      const campgrounds: CampgroundModel[] = [];
      const claims: ClaimModel[] = [];
      let activeCampground;
      let activeClaim;

      const { data: claimsLoaded, included: campgroundsIncluded } =
        await loadRecords<ClaimModel, CampgroundModel>(
          'campground-claims',
          {
            filter: {
              userId: user?.id,
            },
            include: 'campground',
            page: {
              size: 200,
              number: 1,
            },
          },
          authHeaders
        );

      // ensure one claim per campground
      campgroundsIncluded?.forEach((campground: CampgroundModel) => {
        const claim = claimsLoaded.find(({ campgroundId }) => {
          return campgroundId === campground.id;
        });

        if (claim) {
          campgrounds.push(campground);
          claims.push(claim);
          if (campground.slug === slug) {
            activeCampground = campground;
            activeClaim = claim;
          }
        }
      });

      if (user?.admin && slug && !activeCampground) {
        const { adminCampground, adminClaim } =
          (await getAdminClaimByRouter(slug)) || {};
        if (adminCampground && adminClaim) {
          campgrounds.push(adminCampground);
          claims.push(adminClaim);
          activeCampground = adminCampground;
          activeClaim = adminClaim;
        }
      }

      return { campgrounds, claims, activeCampground, activeClaim };
    },
    [user?.id, user?.admin, authHeaders, getAdminClaimByRouter]
  );

  return fetchClaims;
};

export default useFetchClaims;
