import React, {
  useReducer,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useRouter } from 'next/router';
import isEmpty from 'lodash.isempty';

import { usePrevious } from 'hooks/usePrevious';

import { overlayReducer } from './overlayReducer';

export type OverlayContextValue<O extends Record<string, unknown>> = {
  overlays: Array<Overlay<O>>;
  overlay: Overlay<O> | null;
  setOverlay: <T extends Record<string, unknown>>(
    overlay: Overlay<T> | Overlay<O> | null
  ) => void;

  close: () => void;
};

export const OverlayContext = React.createContext<
  OverlayContextValue<Record<string, unknown>>
>({
  overlays: [],
  overlay: null,
  setOverlay: () => {},
  close: () => {},
});

export function OverlayProvider<T extends Record<string, unknown>>({
  children,
}: {
  children: React.ReactNode;
}) {
  const router = useRouter();

  const showTimeout = useRef<null | NodeJS.Timeout>(null);
  const dismissTimeout = useRef<null | NodeJS.Timeout>(null);

  const previousAsPath = usePrevious(router.asPath);

  const [overlayState, dispatchOverlay] = useReducer(overlayReducer, {
    overlays: [],
  });

  const setOverlay = useCallback(
    function setOverlay<O extends Record<string, unknown>>(
      overlay: Overlay<O> | null
    ) {
      if (overlay) {
        dispatchOverlay({ type: 'INITIATE_SHOW_OVERLAY', payload: overlay });
        showTimeout.current = setTimeout(
          () => dispatchOverlay({ type: 'SHOW_OVERLAY', payload: overlay }),
          0
        );
      }
    },
    [dispatchOverlay]
  );

  const close = useCallback(() => {
    // close modal transition with content
    dispatchOverlay({ type: 'INITIATE_DISMISS_OVERLAY' });
    dismissTimeout.current = setTimeout(
      () => dispatchOverlay({ type: 'DISMISS_OVERLAY' }),
      600
    );
  }, []);

  useEffect(() => {
    if (
      !isEmpty(overlayState.overlays) &&
      previousAsPath !== router.asPath &&
      !router.query.media
    ) {
      dispatchOverlay({ type: 'CLEAR_OVERLAYS' });
    }
  }, [
    overlayState.overlays,
    previousAsPath,
    router.asPath,
    router.query.media,
  ]);

  useEffect(() => {
    return () => {
      if (showTimeout.current) {
        clearTimeout(showTimeout.current);
      }

      if (dismissTimeout.current) {
        clearTimeout(dismissTimeout.current);
      }
    };
  }, []);

  const overlayContextValue = useMemo(
    () =>
      ({
        overlays: overlayState.overlays,
        setOverlay,
        close,
      } as OverlayContextValue<T>),
    [close, overlayState, setOverlay]
  );

  return (
    <OverlayContext.Provider value={overlayContextValue}>
      {children}
    </OverlayContext.Provider>
  );
}
