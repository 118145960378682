// JSONAPIError is the TYPE of object sent by the API in their response.data.errors
// JSONApiErrorError is our custom error CLASS which contains an array of JSONAPIError objects.
export class JSONApiErrorError extends Error {
  errors: JSONAPIError[];
  status: number;
  code?: string;
  url?: string;
  name = 'JSONApiErrorError';

  constructor(errors: JSONAPIError[], status: number, url?: string) {
    super(errors[0].detail);
    this.errors = errors;
    this.status = status;
    this.url = url;
  }
}

export function isJSONApiErrorError(
  error: unknown
): error is JSONApiErrorError {
  return error instanceof JSONApiErrorError;
}

export function isJSONSyntaxError(error: unknown): boolean {
  if (
    error instanceof Error &&
    (error instanceof SyntaxError ||
      error?.message.includes('Unexpected token'))
  ) {
    return true;
  }
  return false;
}
