import React from 'react';
import { useRouter } from 'next/router';
import * as amplitude from '@amplitude/analytics-browser';

import { PRO_CTA_CLICK, PAGE_NAMES } from 'constants/amplitude';
import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import { useUser } from 'contexts/currentUser';

import getNavLink from 'utils/getNavLink';

import AppProUpgradeLink from 'components/AppProUpgradeButton/AppProUpgradeLink';

import AppModal from './AppModal';

import styles from './styles/AppModal.module.scss';

const ProCellServiceModal: React.FC = () => {
  const userState = useUser();
  const router = useRouter();

  const sendProCTAClickEvent = () => {
    amplitude.track(PRO_CTA_CLICK, {
      'button name': 'Upgrade to PRO',
      'page name': PAGE_NAMES[router.pathname as PageKeys],
    });
  };

  return (
    <AppModal
      type="pro-cell-service"
      wrapperClassName={styles['pro-cell-service-modal-wrapper']}
      className={styles['pro-cell-service-modal']}
      content={
        <div className={styles['pro-cell-service-modal__content-container']}>
          <span className={styles['pro-cell-service-modal__heading']}>
            <img
              className={styles['pro-cell-service-modal__heading-pro-badge']}
              src={`${PUBLIC_ASSETS_PATH}/assets/badges/pro.svg`}
              alt={`Pro Cell Service Modal Pro Badge`}
            />
            Cell Service Maps
          </span>
          <div className={styles['pro-cell-service-modal__content']}>
            <div className={styles['pro-cell-service-modal__content-text']}>
              <p
                className={
                  styles['pro-cell-service-modal__content-description']
                }
              >
                Keep in touch.
                <br />
                {`Or don't, we won't tell.`}
              </p>
              <p
                className={
                  styles['pro-cell-service-modal__content-sub-description']
                }
              >
                Find campsites with your cell service coverage.
              </p>
              <div
                className={styles['pro-cell-service-modal__content-pro-cta']}
              >
                <AppProUpgradeLink
                  href={getNavLink(
                    'pro/checkout?redeem-code=true',
                    userState?.user
                  )}
                  dataEvent="pro_cell_service_modal_cta_click"
                  onClick={sendProCTAClickEvent}
                />
              </div>
            </div>
            <div
              className={
                styles['pro-cell-service-modal__content-image-wrapper']
              }
            >
              <img
                className={styles['pro-cell-service-modal__content-image']}
                src={`${PUBLIC_ASSETS_PATH}/assets/images/fieldreport_illus_option.jpg`}
                alt={`Pro Cell Service Modal Field Report Option`}
              />
            </div>
          </div>
          <div
            className={styles['pro-cell-service-modal__content-pro-cta-mobile']}
          >
            <AppProUpgradeLink
              href={getNavLink(
                'pro/checkout?redeem-code=true',
                userState?.user
              )}
              dataEvent="pro_cell_service_modal_cta_click"
              onClick={sendProCTAClickEvent}
            />
          </div>
        </div>
      }
    />
  );
};
export default ProCellServiceModal;
