declare const FORCE_SERVER: boolean | undefined;

let server: boolean;

try {
  server = typeof window === 'undefined' || FORCE_SERVER === true;
} catch {
  // FORCE_SERVER is not defined
  server = typeof window === 'undefined';
}

export const isServer = server;
