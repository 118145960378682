import React, {
  useContext,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { Router } from 'next/router';

import { useUser } from 'contexts/currentUser';

import useLoadRecords from 'hooks/useLoadRecords';
import { useOverlayContext } from 'hooks/useOverlayContext';

interface CampgroundsSavedToListsContextValue {
  campgroundsSavedToLists: string[];
  syncCampgroundsSavedToLists: (refresh?: boolean) => Promise<void>;
  setCampgroundToSaveOnLogin: (
    campground: CampgroundModel | CampgroundSearchResultModel
  ) => void;
}

const CampgroundsSavedToListContext =
  React.createContext<CampgroundsSavedToListsContextValue>({
    campgroundsSavedToLists: [],
    syncCampgroundsSavedToLists: async () => {},
    setCampgroundToSaveOnLogin: () => {},
  });

export const useCampgroundsSavedToLists = () =>
  useContext(CampgroundsSavedToListContext);

const CampgroundsSavedToListProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [campgroundsSavedToLists, setCampgroundsSavedToLists] = useState<
    string[]
  >([]);
  const [campgroundToSaveOnLogin, setCampgroundToSaveOnLogin] = useState<
    CampgroundModel | CampgroundSearchResultModel | null
  >(null);

  const { loadRecords } = useLoadRecords();

  const { user } = useUser();
  const { setOverlay } = useOverlayContext();

  const syncCampgroundsSavedToLists = useCallback(async () => {
    if (user) {
      await loadRecords<ResourceIdCollectionModel>(
        `users/${user.id}/list-items/campground-ids`,
        { time: Date.now() },
        undefined,
        ({ data }) => {
          if (data && data.length) {
            setCampgroundsSavedToLists(
              data[0].resourceIds.map((id) => `${id}`)
            );
          } else {
            setCampgroundsSavedToLists([]);
          }
        }
      );
    } else {
      setCampgroundsSavedToLists([]);
    }
  }, [setCampgroundsSavedToLists, loadRecords, user]);

  useEffect(() => {
    syncCampgroundsSavedToLists();
  }, [syncCampgroundsSavedToLists]);

  useEffect(() => {
    if (user && campgroundToSaveOnLogin) {
      const campground = { ...campgroundToSaveOnLogin };
      setCampgroundToSaveOnLogin(null);
      setTimeout(() => {
        // Timeout used to offset the 600ms for closing the sign-in modal.
        setOverlay({
          type: 'save',
          campground,
        });
      }, 900);
    } else if (!user && campgroundToSaveOnLogin) {
      const clearOnRouteChange = (location: string) => {
        if (location.split('?')[0] !== window.location.pathname) {
          setCampgroundToSaveOnLogin(null);
        }
      };

      Router.events.on('routeChangeStart', clearOnRouteChange);
      return () => {
        Router.events.off('routeChangeStart', clearOnRouteChange);
      };
    }
  }, [campgroundToSaveOnLogin, setOverlay, user]);

  const campgroundsSavedToListContextValue = useMemo(() => {
    return {
      campgroundsSavedToLists,
      syncCampgroundsSavedToLists,
      setCampgroundToSaveOnLogin,
    };
  }, [
    campgroundsSavedToLists,
    syncCampgroundsSavedToLists,
    setCampgroundToSaveOnLogin,
  ]);

  return (
    <CampgroundsSavedToListContext.Provider
      value={campgroundsSavedToListContextValue}
    >
      {children}
    </CampgroundsSavedToListContext.Provider>
  );
};

export default CampgroundsSavedToListProvider;
