import { useMemo } from 'react';
import { useSession } from 'next-auth/react';

const useAuthenticationHeader = (): Record<string, string> => {
  const { data: sessionData } = useSession() || {};
  const token = sessionData?.accessToken;

  const authenticationHeader = useMemo(() => {
    if (token) {
      return {
        'x-access-token': token as string,
        Authorization: `Bearer ${token}` as string,
      };
    }
    return {} as Record<string, string>;
  }, [token]);

  return authenticationHeader;
};

export default useAuthenticationHeader;
