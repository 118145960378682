import Router from 'next/router';
import kebabCase from 'lodash.kebabcase';
const NEXT_HOST = process.env.NEXT_PUBLIC_SITE_BASE;
const EMBER_HOST = process.env.NEXT_PUBLIC_EMBER_HOST;

export const campgroundUrl = (
  campground:
    | CampgroundModel
    | CampgroundSearchResultModel
    | CampgroundAutocomplete
) =>
  `${NEXT_HOST}/camping/${kebabCase(campground.regionName)}/${campground.slug}`;

export const campgroundCreateReviewUrl = (
  campground:
    | CampgroundModel
    | CampgroundSearchResultModel
    | CampgroundAutocomplete
) =>
  `${EMBER_HOST}/camping/${kebabCase(campground.regionName)}/${
    campground.slug
  }/review/create`;

export const waterStationUrl = (
  station: WaterStationModel | LocationSearchResultModel
) =>
  `${NEXT_HOST}/water-stations/${kebabCase(station.regionName)}/${
    station.slug
  }`;

export const dumpStationUrl = (
  station: DumpStationModel | LocationSearchResultModel
) =>
  `${NEXT_HOST}/dump-stations/${kebabCase(station.regionName)}/${station.slug}`;

export const campgroundOrLocationUrl = (
  campgroundOrLocation: CampgroundOrLocationModel
) => {
  if (campgroundOrLocation.type === 'location-search-results') {
    const location = campgroundOrLocation as LocationSearchResultModel;
    if (location.locationType === 'water_station') {
      return waterStationUrl(location);
    }
    if (location.locationType === 'dump_station') {
      return dumpStationUrl(location);
    }
  }

  return campgroundUrl(campgroundOrLocation as CampgroundSearchResultModel);
};

// gets url for search location, with dates if applicable. This should only be used in components that are only rendered client-side. Should be called again if dates change.
export const campgroundOrLocationUrlWithDates = (
  campgroundOrLocation: CampgroundOrLocationModel
) => {
  let dateString = '';

  if (
    typeof Router !== 'undefined' &&
    Router.query?.filters &&
    campgroundOrLocation.bookingMethod
  ) {
    try {
      const searchFilters = JSON.parse(Router.query.filters as string);
      if (searchFilters?.start_date && searchFilters?.end_date) {
        dateString = `?startDate=${searchFilters.start_date}&endDate=${searchFilters.end_date}`;
      }
    } catch (e) {
      console.error(e);
    }
  }

  return `${campgroundOrLocationUrl(campgroundOrLocation)}${dateString}`;
};

export const locationEditPath = (
  location:
    | CampgroundModel
    | WaterStationModel
    | DumpStationModel
    | DumpAndWaterStationModel
) =>
  `/${location.type === 'campgrounds' ? 'camping' : location.type}/${kebabCase(
    location.regionName
  )}/edit/${location.slug}`;
