import React from 'react';

import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import { useOverlay } from 'hooks/useOverlayContext';

import AppButtonLink from 'components/AppButton/AppButtonLink';
import AppModal from 'components/AppModal/AppModal';

import styles from './styles/ClaimAddListingSuccessModal.module.scss';

export interface ClaimAddListingSuccessModalProps {
  className?: string;
}

export const ClaimAddListingSuccessModal: React.FC<
  ClaimAddListingSuccessModalProps
> = ({ className }: ClaimAddListingSuccessModalProps) => {
  const overlay = useOverlay<ListingSuccessOverlay>('listing-success');
  const { campground } = overlay || {};

  return (
    <AppModal
      type="listing-success"
      className={`${styles['listing-success']} ${className}`}
      disableClose={true}
      padded={false}
      content={
        <>
          <div className={styles['listing-success__text']}>
            <h2 className={styles['listing-success__title']}>Success!</h2>
            <img
              className={styles['listing-success__image']}
              srcSet={`${PUBLIC_ASSETS_PATH}/assets/images/claim/success.jpg, ${PUBLIC_ASSETS_PATH}/assets/images/claim/success@2x.jpg 2x`}
              src={`${PUBLIC_ASSETS_PATH}/assets/images/claim/success.jpg`}
              alt="Two hands hold flags while waving them. One flag is green and says 'Good Job', while the other is blue with a smiley face."
            />
            <p>Thank you for adding your property.</p>
            <p>
              Claim your Listing to edit your property in the Manager Portal.
            </p>
          </div>

          <div className={styles['listing-success__footer']}>
            <AppButtonLink
              className={styles['listing-success__link']}
              path={`/claim/${campground?.slug}`}
              label="Got it"
              dataEvent="click_listing_success_modal"
            />
          </div>
        </>
      }
    />
  );
};

export default ClaimAddListingSuccessModal;
