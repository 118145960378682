import * as amplitude from '@amplitude/analytics-browser';
import startCase from 'lodash/startCase';

import { PAGE_NAMES, WEB_PAGE_VIEW, WEB_CLICK } from 'constants/amplitude';

export const getProductProperties = (
  platformSubscription: PlatformSubscriptionModel,
  discountCodeInfo?: DiscountCodeInfoModel | null,
  purchaseType = 'Regular PRO'
) => ({
  'sku type': platformSubscription.trialPeriodDays ? 'trial' : 'no trial',
  discount: discountCodeInfo?.percentOff
    ? discountCodeInfo?.percentOff / 100
    : 0,
  'coupon code': discountCodeInfo?.code?.toLowerCase(),
  price: platformSubscription.amount / 100,
  'purchase source': platformSubscription.platform,
  sku: platformSubscription.sku,
  'subscription period': platformSubscription.period,
  'trial period': platformSubscription.trialPeriodDays,
  'purchase type': purchaseType,
});

export const pageNameFromUrl = (url: string) => {
  let pathname = url;
  if (
    process.env.NEXT_PUBLIC_SITE_BASE &&
    pathname.startsWith(process.env.NEXT_PUBLIC_SITE_BASE)
  ) {
    pathname = new URL(url).pathname;
  }
  pathname = pathname.split(/[?#]/)[0];

  // @ts-ignore
  if (PAGE_NAMES[pathname]) return PAGE_NAMES[pathname];

  if (pathname.match(/\/media/)) {
    return 'Campground Media';
  }
  if (pathname.startsWith('/manager')) {
    return 'Manager Portal';
  }

  const segments = pathname.split('/');
  const pageNameRoute = Object.keys(PAGE_NAMES).find((key) => {
    return key
      .split('/')
      .every(
        (keySegment, index) =>
          !!keySegment.match(/^\[.*\]$/) || keySegment === segments[index]
      );
  });
  // @ts-ignore
  if (pageNameRoute) return PAGE_NAMES[pageNameRoute];

  return startCase(segments.join(' '));
};

export const logAmplitudePageView = (
  webVitals?: Record<string, number | undefined>
) => {
  if (typeof window === 'undefined' || !process.env.NEXT_PUBLIC_AMPLITUDE_KEY)
    return;

  const location = window.location.pathname.split(/[?#]/)[0];

  const { FCP, LCP, CLS, TTFB } = webVitals || {};

  const properties: Record<string, number | string | undefined> = {
    'page location': location,
    'page name': pageNameFromUrl(location),
    'page title': document.title,
  };

  if (FCP) properties['first contentful paint'] = FCP;
  if (LCP) properties['largest contentful paint'] = LCP;
  if (CLS) properties['cumulative layout shift'] = CLS;
  if (TTFB) properties['time to first byte'] = TTFB;

  amplitude.track(WEB_PAGE_VIEW, properties);
};

export const logAmplitudeWebClick = (text = '', event = '') => {
  if (typeof window === 'undefined' || !process.env.NEXT_PUBLIC_AMPLITUDE_KEY)
    return;

  const location = window.location.pathname.split(/[?#]/)[0];

  amplitude.track(WEB_CLICK, {
    'page location': location,
    'page name': pageNameFromUrl(location),
    'page title': document.title,
    'click label': text,
    'data event': event,
  });
};

export function onLinkOrButtonClicked(this: HTMLElement | HTMLImageElement) {
  const dataEvent =
    this.getAttribute('data-event') ||
    this.getAttribute('data-event-action') ||
    this.parentElement?.getAttribute('data-event') ||
    this.getAttribute('data-event-action') ||
    '';

  let text = this.innerText
    ? this.innerText.trim()
    : this.firstElementChild?.tagName == 'IMG'
    ? 'image'
    : this.parentElement?.innerText?.trim() ||
      this.parentElement?.parentElement?.innerText?.trim() ||
      this.parentElement?.parentElement?.parentElement?.innerText?.trim() ||
      this.parentElement?.parentElement?.parentElement?.parentElement?.innerText?.trim() ||
      '';

  text = text?.replace('\nSubmitted', '');

  logAmplitudeWebClick(dataEvent, text);
}
