const HOST = process.env.NEXT_PUBLIC_EMBER_HOST;

export default function getNavLink(
  path: string,
  user?: UserModel | null
): string {
  if (!HOST) {
    throw new Error('Error in Next env variable config: no HOST');
  }
  let newPath = path.replace(/:host/g, HOST);
  if (user) {
    newPath = newPath.replace(/:slug/g, user.slug);
  }
  return newPath;
}
