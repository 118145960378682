import React, { useState } from 'react';
import { useRouter } from 'next/router';
import kebabCase from 'lodash.kebabcase';

import { deleteRecord } from 'utils/JSONAPIAdapter';

import useAuthenticationHeader from 'hooks/useAuthenticationHeaders';
import { useOverlay, useOverlayContext } from 'hooks/useOverlayContext';

import AppButton from 'components/AppButton/AppButton';
import AppModal from 'components/AppModal/AppModal';

import styles from './styles/AppModal.module.scss';

export const DeleteReviewModal: React.FC = () => {
  const router = useRouter();

  const { close } = useOverlayContext();

  const overlay = useOverlay<DeleteReviewOverlay>(
    'delete'
  ) as DeleteReviewOverlay;

  const campground = overlay?.campground || {};
  const review = overlay?.review || {};

  const [disabled, setDisabled] = useState(
    false as boolean | 'loading' | 'submitted'
  );

  const authenticationHeader = useAuthenticationHeader();

  if (!authenticationHeader) {
    return null;
  }

  const deleteReview = async () => {
    setDisabled('loading');
    await deleteRecord(review, {}, authenticationHeader);

    router.push(
      `/camping/${kebabCase(campground.regionName)}/${campground.slug}`
    );
    close();
    setDisabled('submitted');
  };

  return (
    <AppModal
      type="delete"
      padded={false}
      content={
        <div className={styles['delete-modal']}>
          <div className={styles['delete-modal__text']}>
            <h4 className={styles['delete-modal__headline']}>Delete Review</h4>
            <p className={styles['delete-modal__body']}>
              {`Are you sure you want to delete your review of ${campground.name}?`}
            </p>
          </div>
          <footer className={styles['delete-modal__actions']}>
            <AppButton
              type={'button'}
              className={styles['delete-modal__cancel']}
              label="Cancel"
              ghost={'dark'}
              borderless={true}
              onClick={close}
              dataEvent={`click_cancel_delete_review`}
            />
            <AppButton
              type={'submit'}
              className={styles['delete-modal__delete']}
              label="Delete"
              disabled={disabled}
              caution
              onClick={deleteReview}
              dataEvent={`click_submit_delete_review`}
            />
          </footer>
        </div>
      }
    />
  );
};

export default DeleteReviewModal;
