import React, { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

import {
  WEB_TO_APP_BANNER_SHOWN,
  WEB_TO_APP_BANNER_CLICK,
} from 'constants/amplitude';

import { hideCTAForHours } from 'utils/localStorage';

import useDynamicWebToAppLink from 'hooks/useDynamicWebToAppLink';
import { useOverlayContext } from 'hooks/useOverlayContext';

import AppButton from 'components/AppButton/AppButton';
import AppButtonLink from 'components/AppButton/AppButtonLink';
import AppDivider from 'components/AppDivider/AppDivider';
import AppIcon from 'components/AppIcon/AppIcon';

import AppModal from './AppModal';

import styles from './styles/SeeInAppModal.module.scss';

const SeeInAppModal: React.FC = () => {
  const { close } = useOverlayContext();

  const featureParam = 'see_in_app';

  const deepLinkUrl = useDynamicWebToAppLink({
    featureParam,
    includeCanonicalUrl: true,
    includeDesktopUrl: true,
  });

  useEffect(() => {
    amplitude.track(WEB_TO_APP_BANNER_SHOWN);
  }, []);

  const onContinue = (btnLabel: string) => {
    amplitude.track(WEB_TO_APP_BANNER_CLICK, {
      'click label': btnLabel,
    });
    // Set timer in localstorage to not show for 12 hours.
    hideCTAForHours(12, 'hide-see-in-app-until');
    close();
  };

  return (
    <AppModal
      type="see-in-app"
      padded={false}
      mobileBottomDrawerModal
      disableClose
      fullScreenMobile={false}
      content={
        <div className={styles['see-in-app-modal__content-container']}>
          <div className={styles['see-in-app-modal__title']}>
            See The Dyrt in...
          </div>
          <AppDivider className={styles['see-in-app-modal__divider']} />
          <div>
            <div className={styles['see-in-app-modal__row']}>
              <div className={styles['see-in-app-modal__section']}>
                <div className={styles['see-in-app-modal__logoWrapper']}>
                  <AppIcon
                    classNameSvg={[styles['see-in-app-modal__logo']]}
                    icon="logo"
                  />
                </div>
                <span className={styles['see-in-app-modal__label']}>
                  The Dyrt App
                </span>
              </div>
              <div className={styles['see-in-app-modal__buttonWrapper']}>
                <AppButtonLink
                  className={styles['see-in-app-modal__button']}
                  label={'Open'}
                  path={deepLinkUrl}
                  dataEvent={'see_in_app_open'}
                  onClick={() => onContinue('The Dyrt App')}
                />
              </div>
            </div>
            <div className={styles['see-in-app-modal__row']}>
              <div className={styles['see-in-app-modal__section']}>
                <div className={styles['see-in-app-modal__logoWrapper']}>
                  <AppIcon
                    classNameSvg={[styles['see-in-app-modal__logo--globe']]}
                    icon="globe"
                  />
                </div>
                <span className={styles['see-in-app-modal__label']}>
                  Browser
                </span>
              </div>
              <div className={styles['see-in-app-modal__buttonWrapper']}>
                <AppButton
                  className={styles['see-in-app-modal__button--grey']}
                  label={'Continue'}
                  type="button"
                  onClick={() => onContinue('Browser')}
                  dataEvent={'see_in_app_continue'}
                />
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};
export default SeeInAppModal;
