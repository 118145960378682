export interface ConnectionDetailsType {
  label: string;
  range: string;
  description: string;
}

export const connectionDetailsTypes: ConnectionDetailsType[] = [
  {
    label: 'Good',
    range: '10+Mbps',
    description:
      'Campers can make calls and send texts & emails, watch an HD movie, play multiplayer games, and more.',
  },
  {
    label: 'Fair',
    range: '3Mbps-10Mbps',
    description:
      'Campers can make calls, send texts & emails, video chat, stream video, and play online games.',
  },
  {
    label: 'Poor',
    range: '0Mbps-3Mbps',
    description:
      'Campers might be able check email, send texts, and make calls.',
  },
  {
    label: 'Available',
    range: '',
    description:
      'Campers have been able to connect here but we don’t know the quality of the connection.',
  },
  {
    label: 'Unknown',
    range: '',
    description: 'Connectivity is not known for this campground.',
  },
];
