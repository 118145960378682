export const CAMPGROUND_ACCESS_TYPES = [
  {
    attribute: 'accessDrive' as const,
    description: 'Park next to your site',
    icon: 'drive_in',
    id: 'drive_in',
    label: 'Drive-In',
  },
  {
    attribute: 'accessWalk' as const,
    description: 'Park in a lot, walk to your site.',
    icon: 'walk_in',
    id: 'walk_in',
    label: 'Walk-In',
  },
  {
    attribute: 'accessHike' as const,
    description: 'Backcountry sites.',
    icon: 'hike_in',
    id: 'hike_in',
    label: 'Hike-In',
  },
  {
    attribute: 'accessBoat' as const,
    description: 'Sites accessible by watercraft.',
    icon: 'boat_in',
    id: 'boat_in',
    label: 'Boat-In',
  },
];

export const CAMPGROUND_PARTNER_TYPES = [
  {
    id: 'premium' as const,
    title: 'Weekday Discount',
    description:
      'Offer PRO members 40% off their first night, Sunday–Thursday only and 10% off every other night except holidays. Guests must book a minimum of two nights.',
    label_icon: 'pro_pill',
    label_icon_category: 'pro',
  },
  {
    id: 'standard' as const,
    title: 'Standard Discount',
    description:
      'Offer PRO members 10% off each night of their stay except holidays.',
    label_icon: 'pro_pill',
    label_icon_category: 'pro',
  },
  {
    id: 'null' as const,
    title: 'No Discount',
    description: 'Your listing will have standard visibility.',
  },
];

export const CAMPGROUND_FEATURES_TYPES = [
  {
    attribute: 'river' as const,
    icon: 'river',
    id: 'river',
    label: 'River',
  },
  {
    attribute: 'lake' as const,
    icon: 'lake',
    id: 'lake',
    label: 'Lake',
  },
  {
    attribute: 'ocean' as const,
    icon: 'ocean',
    id: 'ocean',
    label: 'Ocean',
  },
];

export const SITE_TYPES = [
  {
    label: 'Tent Sites',
    icon: 'tent',
    value: 'tent' as const,
  },
  {
    label: 'RV Sites',
    icon: 'rv',
    value: 'rv' as const,
  },
  {
    label: 'Standard (Tent/RV)',
    icon: 'tent_rv',
    value: 'standard' as const,
  },
  {
    label: 'Dispersed',
    icon: 'dispersed',
    value: 'dispersed' as const,
  },
  {
    label: 'Group',
    icon: 'group',
    value: 'group' as const,
  },
  {
    label: 'Cabins',
    icon: 'cabin',
    value: 'cabin' as const,
  },
  {
    label: 'Tent Cabin',
    icon: 'tent_cabin',
    value: 'tent_cabin' as const,
  },
  {
    label: 'Yurts',
    icon: 'yurt',
    value: 'yurt' as const,
  },
  {
    label: 'Equestrian',
    icon: 'horse',
    value: 'horse' as const,
  },
] as const;

export const CAMPER_TYPES = {
  backpacker: {
    title: 'Backpacker',
    icon: 'hike-in',
    camperType: 'backpacker' as const,
    searchParams: {
      accommodations: 'dispersed,tent',
      access: 'drive-in,walk-in,hike-in',
    },
  },
  tent: {
    title: 'Tent',
    icon: 'tent-sites',
    camperType: 'tent' as const,
    searchParams: {
      accommodations: 'tent,group,standard',
      access: 'drive-in,walk-in,boat-in',
    },
  },
  rv: {
    title: 'RV',
    icon: 'rv',
    camperType: 'rv' as const,
    searchParams: {
      accommodations: 'standard,rv',
      access: 'drive-in',
    },
  },
  trailer: {
    title: 'Pull-behind',
    icon: 'pull-behind',
    camperType: 'trailer' as const,
    searchParams: {
      accommodations: 'standard,rv,group,tent',
      access: 'drive-in',
    },
  },
  cabin: {
    title: 'Cabin / Glamping',
    icon: 'cabin',
    camperType: 'cabin' as const,
    searchParams: {
      accommodations: 'cabin,yurt,tent_cabin',
    },
  },
  other: {
    title: 'Other',
    icon: 'dispersed',
    camperType: 'other' as const,
    searchParams: null,
  },
};

export const CAMPGROUND_VEHICLE_AMENITIES = [
  {
    label: 'Sanitary Dump' as const,
    icon: 'sanitary_dump_available' as const,
    attribute: 'sanitaryDump' as const,
  },
  {
    label: 'Sewer Hookups',
    icon: 'sewer_hookups_available',
    attribute: 'sewerHookups' as const,
  },
  {
    label: 'Water Hookups',
    icon: 'water_hookups_available',
    attribute: 'waterHookups' as const,
  },
  {
    label: 'Pull-Through Sites',
    icon: 'pull-through_driveway_available',
    attribute: 'drivewayPullThrough' as const,
  },
  {
    label: '30 Amp Hookups',
    icon: '30_amp_available',
    attribute: 'thirtyAmpHookups' as const,
  },
  {
    label: '50 Amp Hookups',
    icon: '50_amp_available',
    attribute: 'fiftyAmpHookups' as const,
  },
  {
    label: 'Big Rig Friendly',
    icon: 'bigrig_available',
    attribute: 'bigRigFriendly' as const,
  },
] as const;

export const CAMPGROUND_LOCATION_AMENITIES = [
  {
    label: 'Threaded Faucets',
    attribute: 'threaded',
    icon: 'water_hookups_available',
    categories: ['waterStation'],
  },
  {
    label: 'Un-threaded Faucets',
    attribute: 'nonThreaded',
    icon: 'water_hookups_available',
    categories: ['waterStation'],
  },
  {
    label: 'ADA Access',
    icon: 'ada_accessible_available',
    attribute: 'adaAccess' as const,
    categories: [
      'established',
      'overnightParking',
      'dumpStation',
      'waterStation',
    ],
  },
  {
    label: 'Alcohol Allowed',
    icon: 'alcohol_allowed',
    attribute: 'alcoholAllowed' as const,
    categories: ['established'],
  },
  {
    label: 'Drinking Water',
    icon: 'drinking_water_available',
    attribute: 'drinkingWater' as const,
    categories: [
      'established',
      'dispersed',
      'overnightParking',
      'dumpStation',
      'waterStation',
    ],
  },
  {
    label: 'Rinsing Water',
    icon: 'water_hookups_available',
    attribute: 'rinsingWater' as const,
    categories: ['dumpStation'],
  },
  {
    label: 'Fires Allowed',
    icon: 'fires_allowed',
    attribute: 'firesAllowed' as const,
    categories: ['established', 'dispersed', 'overnightParking'],
  },
  {
    label: 'Firewood Available',
    icon: 'firewood_available',
    attribute: 'firewood' as const,
    categories: [
      'established',
      'overnightParking',
      'dumpStation',
      'waterStation',
    ],
  },
  {
    label: 'Horse Corral ',
    icon: 'horse_allowed',
    attribute: 'horseCorral' as const,
    categories: ['established'],
  },
  {
    label: 'Laundry',
    icon: 'laundry_available',
    attribute: 'laundry' as const,
    categories: [
      'established',
      'overnightParking',
      'dumpStation',
      'waterStation',
    ],
  },
  {
    label: 'General Store' as const,
    icon: 'market_available' as const,
    attribute: 'market' as const,
    categories: [
      'established',
      'overnightParking',
      'dumpStation',
      'waterStation',
    ],
  },
  {
    label: 'Pets Allowed',
    icon: 'pets_allowed',
    attribute: 'petsAllowed' as const,
    categories: ['established', 'overnightParking'],
  },
  {
    label: 'Picnic Tables',
    icon: 'picnic_tables_available',
    attribute: 'picnicTable' as const,
    categories: [
      'established',
      'dispersed',
      'overnightParking',
      'dumpStation',
      'waterStation',
    ],
  },
  {
    label: 'Showers',
    icon: 'showers_available',
    attribute: 'showers' as const,
    categories: [
      'established',
      'overnightParking',
      'dumpStation',
      'waterStation',
    ],
  },
  {
    label: 'Toilets',
    icon: 'toilets_available',
    attribute: 'toilets' as const,
    categories: [
      'established',
      'dispersed',
      'overnightParking',
      'dumpStation',
      'waterStation',
    ],
  },

  {
    label: 'Trash',
    icon: 'trash_available',
    attribute: 'trash' as const,
    categories: [
      'established',
      'dispersed',
      'overnightParking',
      'dumpStation',
      'waterStation',
    ],
  },
  {
    label: 'WiFi',
    icon: 'wifi_available',
    attribute: 'wifi' as const,
    categories: [
      'established',
      'overnightParking',
      'dumpStation',
      'waterStation',
    ],
  },
] as const;

export const CAMPGROUND_AMENITIES = [
  {
    label: 'Market' as const,
    icon: 'market_available' as const,
    attribute: 'market' as const,
  },
  {
    label: 'ADA Access',
    icon: 'ada_accessible_available',
    attribute: 'adaAccess' as const,
  },
  {
    label: 'Trash',
    icon: 'trash_available',
    attribute: 'trash' as const,
  },
  {
    label: 'Picnic Table',
    icon: 'picnic_tables_available',
    attribute: 'picnicTable' as const,
  },
  {
    label: 'Firewood Available',
    icon: 'firewood_available',
    attribute: 'firewood' as const,
  },
  {
    label: 'Phone Service',
    icon: 'phone_service_available',
    attribute: 'mobileService' as const,
  },
  {
    label: 'Reservable',
    icon: 'reservable_available',
    attribute: 'reservable' as const,
  },
  {
    label: 'WiFi',
    icon: 'wifi_available',
    attribute: 'wifi' as const,
  },
  {
    label: 'Showers',
    icon: 'showers_available',
    attribute: 'showers' as const,
  },
  {
    label: 'Drinking Water',
    icon: 'drinking_water_available',
    attribute: 'drinkingWater' as const,
  },
  {
    label: 'Electric Hookups',
    icon: 'electrical_hookups_available',
    attribute: 'electricHookups' as const,
  },
  {
    label: 'Toilets',
    icon: 'toilets_available',
    attribute: 'toilets' as const,
  },
  {
    label: 'Alcohol',
    icon: 'alcohol_allowed',
    attribute: 'alcoholAllowed' as const,
  },
  {
    label: 'Pets',
    icon: 'pets_allowed',
    attribute: 'petsAllowed' as const,
  },
  {
    label: 'Fires',
    icon: 'fires_allowed',
    attribute: 'firesAllowed' as const,
  },
] as const;

export const RECREATION_AMENITIES: CheckboxConfigList = [
  {
    label: 'Basketball Court',
    attribute: 'basketballCourt' as const,
    description: 'Basketball court available',
  },
  {
    label: 'Beach',
    attribute: 'beach' as const,
    description: 'Beach available',
  },

  {
    label: 'Bike Rental',
    attribute: 'bikeRental' as const,
    description: 'Bike rental available',
  },
  {
    label: 'Boat Ramp',
    attribute: 'boatRamp' as const,
    description: 'Boat ramp available',
  },
  {
    label: 'Boat Rental',
    attribute: 'boatRental' as const,
    description: 'Boat rental available',
  },
  {
    label: 'Dog Run',
    attribute: 'dogRun' as const,
    description: 'Dog run available',
  },
  {
    label: 'Game Room',
    attribute: 'gameRoom' as const,
    description: 'Game room available',
  },
  {
    label: 'Gym',
    attribute: 'gym' as const,
    description: 'Gym available',
  },
  {
    label: 'Horseshoes',
    attribute: 'horseshoes' as const,
    description: 'Horseshoes available',
  },
  {
    label: 'Hot Tub',
    attribute: 'hotTub' as const,
    description: 'Hot tub available',
  },
  {
    label: 'Mini Golf',
    attribute: 'miniGolf' as const,
    description: 'Mini golf available',
  },
  {
    label: 'Pickleball',
    attribute: 'pickleball' as const,
    description: 'Pickleball available',
  },
  {
    label: 'Playground',
    attribute: 'playground' as const,
    description: 'Playground available',
  },
  {
    label: 'Swimming Pool',
    attribute: 'swimmingPool' as const,
    description: 'Swimming pool available',
  },
  {
    label: 'Tennis Court',
    attribute: 'tennisCourt' as const,
    description: 'Tennis court available',
  },
  {
    label: 'Volleyball Court',
    attribute: 'volleyballCourt' as const,
    description: 'Volleyball court available',
  },
];
export const CAMPGROUND_SITE_TYPES_AND_ACCOMMODATIONS = [
  {
    label: 'RV Sites',
    icon: 'rv',
    attribute: 'accommodationRv' as const,
  },
  {
    label: 'Tent Sites',
    icon: 'tent',
    attribute: 'accommodationTent' as const,
  },
  {
    label: 'Group Sites',
    icon: 'group',
    attribute: 'accommodationGroup' as const,
  },
  {
    label: 'Equestrian',
    icon: 'horse',
    attribute: 'accommodationHorse' as const,
  },
  {
    label: 'Cabins',
    icon: 'cabin',
    attribute: 'accommodationCabin' as const,
  },
] as const;

export const SEO_TOP10_AMENITIES = [
  {
    label: 'Pets',
    icon: 'pets_allowed',
    iconCategory: 'features',
    value: 'petsAllowed' as const,
    description: 'Pets allowed',
  },
  {
    label: 'Fires',
    icon: 'fires_allowed',
    iconCategory: 'features',
    value: 'firesAllowed' as const,
    description: 'Fires allowed',
  },
  {
    label: 'Electric Hookups',
    icon: 'electrical_hookups_available',
    iconCategory: 'features',
    value: 'electricHookups' as const,
    description: 'Electric hookups available',
  },
  {
    label: 'Phone Service',
    icon: 'phone_service_available',
    iconCategory: 'features',
    value: 'mobileService' as const,
    description: 'Phone service available',
  },
  {
    label: 'Reservable',
    icon: 'reservable_available',
    iconCategory: 'features',
    value: 'reservable' as const,
    description: 'Reservable',
  },
  {
    label: 'ADA Access',
    icon: 'ada_accessible_available',
    iconCategory: 'features',
    value: 'adaAccess' as const,
    description: 'ADA Access available',
  },
  {
    label: 'RVs',
    icon: 'rv',
    iconCategory: 'site-types',
    value: 'accommodationRv' as const,
    description: 'RV sites available',
  },
  {
    label: 'Tents',
    icon: 'tent',
    iconCategory: 'site-types',
    value: 'accommodationTent' as const,
    description: 'Tent sites available',
  },
  {
    label: 'Group',
    icon: 'group',
    iconCategory: 'site-types',
    value: 'accommodationGroup' as const,
    description: 'Group sites available',
  },
  {
    label: 'Equestrian',
    icon: 'horse',
    iconCategory: 'site-types',
    value: 'accommodationHorse' as const,
    description: 'Equestrian sites available',
  },
  {
    label: 'Cabins',
    icon: 'cabin',
    iconCategory: 'site-types',
    value: 'accommodationTentCabin' as const,
    description: 'Cabins available',
  },
  {
    label: 'Standard (Tent/RV)',
    icon: 'tent_rv',
    iconCategory: 'site-types',
    value: 'accommodationStandard' as const,
    description: 'Tent/RV sites available',
  },
  {
    label: 'Dispersed',
    icon: 'dispersed',
    iconCategory: 'site-types',
    value: 'accommodationDispersed' as const,
    description: 'Dispersed sites available',
  },

  {
    label: 'Tent Cabin',
    icon: 'tent_cabin',
    iconCategory: 'site-types',
    value: 'accommodationTentCabin' as const,
    description: 'Tent cabins available',
  },
  {
    label: 'Yurts',
    icon: 'yurt',
    iconCategory: 'site-types',
    value: 'accommodationYurt' as const,
    description: 'Yurts available',
  },
  {
    label: 'Glamping',
    icon: 'yurt',
    iconCategory: 'site-types',
    value: 'accommodationGlamping' as const,
    description: 'Glamping sites available',
  },
  {
    label: 'Market' as const,
    icon: 'market_available' as const,
    iconCategory: 'features',
    value: 'market' as const,
    description: 'Market available',
  },
  {
    label: 'Trash',
    icon: 'trash_available',
    iconCategory: 'features',
    value: 'trash' as const,
    description: 'Trash available',
  },
  {
    label: 'Picnic Table',
    icon: 'picnic_tables_available',
    iconCategory: 'features',
    value: 'picnicTable' as const,
    description: 'Picnic tables available',
  },
  {
    label: 'Firewood Available',
    icon: 'firewood_available',
    iconCategory: 'features',
    value: 'firewood' as const,
    description: 'Firewood available',
  },
  {
    label: 'WiFi',
    icon: 'wifi_available',
    iconCategory: 'features',
    value: 'wifi' as const,
    description: 'WiFi available',
  },
  {
    label: 'Showers',
    icon: 'showers_available',
    iconCategory: 'features',
    value: 'showers' as const,
    description: 'Showers available',
  },
  {
    label: 'Drinking Water',
    icon: 'drinking_water_available',
    iconCategory: 'features',
    value: 'drinkingWater' as const,
    description: 'Drinking water available',
  },
  {
    label: 'Toilets',
    icon: 'toilets_available',
    iconCategory: 'features',
    value: 'toilets' as const,
    description: 'Toilets available',
  },
  {
    label: 'Alcohol',
    icon: 'alcohol_allowed',
    iconCategory: 'features',
    value: 'alcoholAllowed' as const,
    description: 'Alcohol allowed',
  },
] as const;

export const CAMPGROUND_CHECK_IN_DAYS = [
  {
    attribute: 'checkinDisallowedOnMondays' as const,
    label: 'Monday',
  },
  {
    attribute: 'checkinDisallowedOnTuesdays' as const,
    label: 'Tuesday',
  },
  {
    attribute: 'checkinDisallowedOnWednesdays' as const,
    label: 'Wednesday',
  },
  {
    attribute: 'checkinDisallowedOnThursdays' as const,
    label: 'Thursday',
  },
  {
    attribute: 'checkinDisallowedOnFridays' as const,
    label: 'Friday',
  },
  {
    attribute: 'checkinDisallowedOnSaturdays' as const,
    label: 'Saturday',
  },
  {
    attribute: 'checkinDisallowedOnSundays' as const,
    label: 'Sunday',
  },
];

export const AMENITIES_ICON_MAPPING: Record<string, string> = {
  market: 'market_available',
  adaAccess: 'ada_accessible_available',
  trash: 'trash_available',
  picnicTable: 'picnic_tables_available',
  firewood: 'firewood_available',
  mobileService: 'phone_service_available',
  reservable: 'reservable_available',
  wifi: 'wifi_available',
  showers: 'showers_available',
  drinkingWater: 'drinking_water_available',
  electricHookups: 'electrical_hookups_available',
  toilets: 'toilets_available',
  alcoholAllowed: 'alcohol_allowed',
  petsAllowed: 'pets_allowed',
  firesAllowed: 'fires_allowed',
  sanitaryDump: 'sanitary_dump_available',
  sewerHookups: 'sewer_hookups_available',
  waterHookups: 'water_hookups_available',
};
