import React, { useContext, useEffect, useMemo } from 'react';

import { isServer } from 'utils/isServer';
import { nonNullable } from 'utils/typeAssertions';

import { useUser } from './currentUser';

/*
  @fastlySessionPageProps
    in recv_ember_next_session we use the session jwt to build session specific request headers that we can
    vary against. For pages that vary against one of these headers we must pass the header values as pageProps
    so we can build the correct UI server side. Generated by withFastlySessionPageProps
  @fastlyABTestPageProps
    We inject up to ten ab test headers in init_ab_test. These props are generated by withFastlyABTestPageProps
    and contain the parsed value the ab test headers
  @loading
    boolean indicating that the session is loading. Will always be false when pages receive fastlySessionPageProps
*/

interface FastlyRequestHeadersContextValue {
  fastlySessionPageProps: FastlySessionPageProps;
  fastlyABTestPageProps: Record<string, TestVariant | null>;
  loading: boolean;
  children?: React.ReactNode;
}

const FastlyRequestHeadersContext =
  React.createContext<FastlyRequestHeadersContextValue>({
    loading: true,
    fastlySessionPageProps: {} as FastlySessionPageProps,
    fastlyABTestPageProps: {} as Record<string, TestVariant>,
  });

export const useFastlyRequestHeadersContext = () =>
  useContext(FastlyRequestHeadersContext);

export const FastlyRequestHeadersProvider: React.FC<
  Partial<Omit<FastlyRequestHeadersContextValue, 'loading'>>
> = ({ children, fastlySessionPageProps, fastlyABTestPageProps }) => {
  const { user, loading } = useUser();

  useEffect(() => {
    if (isServer) return;

    if (fastlyABTestPageProps) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        exp: Object.values(fastlyABTestPageProps)
          .filter(nonNullable)
          .map((variant) => `${variant.id}.${variant.variant}`)
          .join('!'),
        testTags: Object.keys(fastlyABTestPageProps).map((variant) => variant),
      });
    } else {
      window.dataLayer.push({
        exp: undefined,
        testTages: undefined,
      });
    }
  }, [fastlyABTestPageProps]);

  const value = useMemo(() => {
    if (loading) {
      return {
        fastlySessionPageProps:
          fastlySessionPageProps || ({} as FastlySessionPageProps),
        loading: fastlySessionPageProps ? false : loading,
        fastlyABTestPageProps:
          fastlyABTestPageProps || ({} as Record<string, TestVariant>),
      };
    }

    /*
      Once the session is loaded override fastlySessionPageProps. This will avoid "stale" data
      if the session diverges from the headers. This will only happen if the correct vary headers
      are not added to the page response.
    */
    return {
      fastlySessionPageProps: {
        fastlyAuthState: user ? 'authenticated' : 'anonymous',
        fastlyIsAdmin: !!(user as UserModel)?.admin,
        fastlyIsManager: !!(user as UserModel)?.manager,
        fastlyIsPro: !!(user as UserModel)?.pro,
      } as FastlySessionPageProps,
      fastlyABTestPageProps:
        fastlyABTestPageProps || ({} as Record<string, TestVariant>),
      loading,
    };
  }, [fastlySessionPageProps, loading, user, fastlyABTestPageProps]);

  return (
    <FastlyRequestHeadersContext.Provider value={value}>
      {children}
    </FastlyRequestHeadersContext.Provider>
  );
};
