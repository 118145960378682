import camelCase from 'lodash.camelcase';
import kebabCase from 'lodash.kebabcase';

import { isRecord } from './typeAssertions';

export function camelConvert<
  T extends Record<string, unknown> = Record<string, unknown>
>(obj: Record<string, unknown>): T {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    let newValue;
    if (Array.isArray(value)) {
      newValue = value.map((arrItem) => {
        if (typeof arrItem === 'object' && arrItem !== null) {
          return camelConvert(arrItem);
        }
        return arrItem;
      });
    } else if (isRecord(value)) {
      newValue = camelConvert(value);
    } else {
      newValue = value;
    }
    return {
      ...acc,
      [camelCase(key)]: newValue,
    };
  }, {} as T);
}

export function kebabConvert<T extends Record<string, unknown>>(
  obj: T
): Record<string, unknown> {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    let newValue;
    try {
      if (Array.isArray(value)) {
        newValue = value.map((arrItem) => {
          if (typeof arrItem === 'object' && arrItem !== null) {
            return kebabConvert(arrItem);
          }
          return arrItem;
        });
      } else if (isRecord(value)) {
        newValue = kebabConvert(value);
      } else {
        newValue = value;
      }
      return {
        ...acc,
        [kebabCase(key)]: newValue,
      };
    } catch (e) {
      return acc;
    }
  }, {});
}

export function dasherize(text: string): string {
  return text.replace(/\s+/g, '-').toLowerCase();
}

export function camelToSnakeCase(text: string): string {
  return text.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function underscore(text: string): string {
  return text.replace(/\s+/g, '_').toLowerCase();
}

export const titleCase = (text: string): string => {
  const formattedText = text.replace(/([A-Z])/g, ' $1');
  return formattedText.charAt(0).toUpperCase() + formattedText.slice(1);
};

export const integerWord = (integer: number): string => {
  const words = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
  ];
  return words[integer] || String(integer);
};
