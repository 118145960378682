import managerPortalStateFromActiveClaim from 'utils/managerPortalStateFromActiveClaim';

export type ManagerAction =
  | {
      type: 'SET_MANAGER_ATTRIBUTE';
      payload: Partial<ManagerData>;
    }
  | {
      type: 'SET_ACTIVE_CLAIM_OR_CAMPGROUND';
      payload: ActiveClaimOrCampgroundPayload;
    };

export const managerReducer = (state: ManagerData, action: ManagerAction) => {
  switch (action.type) {
    case 'SET_MANAGER_ATTRIBUTE':
      return { ...state, ...action.payload };
      break;
    case 'SET_ACTIVE_CLAIM_OR_CAMPGROUND':
      // Allow setting updated claim and campground at the same time because changes on a claim can affect values on a campground.
      // We update the values in manager.claims and manager.campgrounds as well as activeClaim and activeCampground.
      const {
        payload: { activeClaim, activeCampground },
      } = action;

      const newState = { ...state };

      if (activeClaim) {
        newState.activeClaim = activeClaim;
        newState.claims = state.claims.map((claim) =>
          claim.id === activeClaim.id ? activeClaim : claim
        );
        newState.managerPortalState =
          managerPortalStateFromActiveClaim(activeClaim);
      }

      if (activeCampground) {
        newState.campgrounds = state.campgrounds.map((campground) =>
          campground.id === activeCampground.id ? activeCampground : campground
        );
        newState.activeCampground = activeCampground;
      }

      return newState;
    default:
      return state;
  }
};
