import React from 'react';
import classNames from 'classnames/bind';

import { underscore } from 'utils/caseConversion';

import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/AppInput.module.scss';

const cx = classNames.bind(styles);

export interface AppInputCheckboxProps {
  inputRef?:
    | React.RefCallback<HTMLInputElement>
    | React.MutableRefObject<HTMLInputElement>;
  isChecked?: boolean;
  className?: string;
  labelClassName?: string;
  error?: string | null;
  hideErrorMessage?: boolean;
  label?: string | JSX.Element;
  name: string;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  value?: string;
  icon?: string | null;
  iconCategory?: string;
  disabled?: boolean;
  description?: string | null;
  descriptionClassName?: string;
  dataEvent?: string;
  dataEventAction?: string;
  dataEventCategory?: string;
  dataEventLabel?: boolean;
  dataEventValue?: string;
  dataTestId?: string;
  labelLarge?: boolean;
  greyedOutBox?: boolean;
  labelFirst?: boolean; // label on left, checkbox on right
}

export const AppInputCheckbox: React.FC<AppInputCheckboxProps> = ({
  inputRef,
  isChecked,
  className,
  labelClassName,
  error,
  hideErrorMessage = false,
  label,
  name,
  onChange,
  value,
  iconCategory,
  icon,
  disabled,
  description,
  descriptionClassName,
  dataEvent,
  dataEventAction,
  dataEventCategory,
  dataEventLabel,
  dataEventValue,
  dataTestId,
  labelLarge,
  greyedOutBox,
  labelFirst,
}) => {
  const appInputCheckboxClassNames = cx({
    'input--has-icon': !!icon || labelFirst,
  });

  const appInputCheckboxLableClassNames = cx({
    'input__checkbox-label': true,
    'input__label--disabled': disabled,
    'input__label--is-large': labelLarge,
  });

  const appInputCheckboxStyledClassNames = cx({
    'input__checkbox-styled': true,
    'input__checkbox-styled-disabled': disabled,
    'input__checkbox-styled-greyedOut': greyedOutBox && disabled,
  });

  dataEvent = dataEvent || `${underscore(String(label || name))}_checkbox`;

  return (
    <div>
      <label
        className={`${styles['input__checkbox']} ${className} ${appInputCheckboxClassNames}`}
        data-testid={dataTestId}
      >
        <span className={styles['input__checkbox-container']}>
          <input
            ref={inputRef}
            className={styles['input__checkbox-actual']}
            type="checkbox"
            value={value}
            name={name}
            checked={isChecked || false}
            onChange={onChange}
            tabIndex={0}
            disabled={disabled}
            data-event={dataEvent}
            data-event-action={dataEventAction}
            data-event-category={dataEventCategory}
            data-event-label={dataEventLabel}
            data-event-value={dataEventValue}
          />
          <AppIcon
            classNameWrapper={appInputCheckboxStyledClassNames}
            classNameSvg={[styles['input__checkbox-styled-checkmark']]}
            icon="check"
          />
        </span>
        <span className={styles['input__label-container']}>
          {!!icon && (
            <AppIcon
              classNameWrapper={styles['input__label-icon-wrapper']}
              classNameSvg={[styles['input__label-icon']]}
              category={iconCategory}
              icon={icon}
            />
          )}
          <span
            className={`${appInputCheckboxLableClassNames} ${labelClassName}`}
          >
            {label}
          </span>
          {error && !hideErrorMessage && (
            <span className={styles['input__error-message']}>{error}</span>
          )}
        </span>
      </label>

      {!!description && (
        <p
          className={`${styles['input__description']} ${descriptionClassName}`}
        >
          {description}
        </p>
      )}
    </div>
  );
};

export default AppInputCheckbox;
