export const PRO_FEATURES = {
  maps: {
    category: 'Maps',
    title: 'Camp better with PRO Maps',
    desc: 'PRO maps help you find free camping, check cell service and stay connected offline',
    services: [
      {
        title: 'Offline Maps',
        desc: 'Search The Dyrt without cell service',
      },
      {
        title: 'Public Lands Maps',
        desc: 'Explore BLM and USFS maps that allow free dispersed camping',
      },
      {
        title: 'Cell Service Maps',
        desc: 'Find campgrounds with your cell service coverage',
      },
      {
        title: 'Road Trip Maps',
        desc: 'Find the best campgrounds along your route',
      },
    ],
  },
  discounts: {
    category: 'Discounts',
    title: 'Save more with PRO discounts',
    desc: 'Unlock exclusive campground discounts and never pay extra booking fees',
    services: [
      {
        title: 'Campground Discounts',
        desc: 'Save on nightly rates at over 1,000 campgrounds',
      },
      {
        title: 'No Extra Booking Fees',
        desc: 'Avoid extra fees when you book directly with The Dyrt',
      },
    ],
  },
};

export const PRO_FEATURES_IS_PRO = {
  maps: {
    category: 'Maps',
    title: 'Explore my PRO Maps benefits',
    desc: 'PRO maps help you find free camping, check cell service and stay connected offline',
    services: [
      {
        title: 'Offline Maps',
        desc: 'Search The Dyrt without cell service',
      },
      {
        title: 'Public Lands Maps',
        desc: 'Explore BLM and USFS maps that allow free dispersed camping',
      },
      {
        title: 'Cell Service Maps',
        desc: 'Find campgrounds with your cell service coverage',
      },
      {
        title: 'Road Trip Maps',
        desc: 'Find the best campgrounds along your route',
      },
    ],
  },
  discounts: {
    category: 'Discounts',
    title: 'My savings with PRO discounts',
    desc: "You've unlocked exclusive campground discounts and don't have to pay extra booking fees",
    services: [
      {
        title: 'Campground Discounts',
        desc: 'Save on nightly rates at over 1,000 campgrounds',
      },
      {
        title: 'No Extra Booking Fees',
        desc: 'Avoid extra fees when you book directly with The Dyrt',
      },
    ],
  },
};
