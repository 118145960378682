import React from 'react';

import styles from './styles/_ProCheckoutV2SignupFormHeader.module.scss';

export const _ProCheckoutV2SignupFormHeader: React.FC = () => {
  return (
    <div className={styles['form-header']}>
      <p className={styles['form-header__text']}>Unlock everything now.</p>
    </div>
  );
};

export default _ProCheckoutV2SignupFormHeader;
