import React from 'react';
import { useRouter } from 'next/router';
import * as amplitude from '@amplitude/analytics-browser';

import { PRO_CTA_CLICK, PAGE_NAMES } from 'constants/amplitude';
import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import AppButtonLink from 'components/AppButton/AppButtonLink';

import AppModal from '../../AppModal';

import styles from './styles/_ProUpgradeModalD.module.scss';

const ACTION_ID = 'pro-upgrade-modal-cta-click-modal-d';

const _ProUpgradeModalD: React.FC = () => {
  const router = useRouter();

  const sendProCTAClickEvent = () => {
    amplitude.track(PRO_CTA_CLICK, {
      'button name': 'Free 7-Day Trial',
      'page name': PAGE_NAMES[router.pathname as PageKeys],
    });
  };

  return (
    <AppModal
      type="pro-upgrade-modal-d"
      wrapperClassName={styles['pro-upgrade-modal-d-wrapper']}
      className={styles['pro-upgrade-modal-d']}
      content={
        <div className={styles['pro-upgrade-modal-d__content']}>
          <img
            className={styles['pro-upgrade-modal-d__logo']}
            src={`${PUBLIC_ASSETS_PATH}/assets/brand/Mark_Pro_Gradient.svg`}
            alt="pro upgrade modal brand logo"
          />
          <p className={styles['pro-upgrade-modal-d__title']}>
            Camp like a PRO
          </p>
          <p className={styles['pro-upgrade-modal-d__desc']}>
            Unlock both premium tools and discounts
          </p>
          <AppButtonLink
            className={styles['pro-upgrade-modal-d__cta']}
            label="Free 7-Day Trial"
            path={`/pro/checkout`}
            icon="right_arrow"
            dataEvent={ACTION_ID}
            onClick={sendProCTAClickEvent}
          />
          <p className={styles['pro-upgrade-modal-d__content-title']}>
            Experience better camping for less.
          </p>
          <p className={styles['pro-upgrade-modal-d__content-desc']}>
            Camp better with PRO maps. Save more with PRO campground discounts.
          </p>
        </div>
      }
    />
  );
};
export default _ProUpgradeModalD;
