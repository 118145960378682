import React from 'react';
import Link from 'next/link';

import { useWPContext } from 'contexts/wordPress';

import useMountEffect from 'hooks/useMountEffect';

const IS_DEVELOP = process.env.NODE_ENV === 'development';

export interface AppLink {
  href: string;
  className?: string;
  children: JSX.Element;
  prefetch?: boolean;
  external?: boolean;
}

// Keeps the Wordpress context from rendering next/links
export const AppLink = ({ href, children, prefetch, external }: AppLink) => {
  const wpContext = useWPContext();

  useMountEffect(() => {
    if (IS_DEVELOP && children.type !== 'a') {
      throw new Error('You must pass an a tag to AppLink');
    }
  });

  if (wpContext || external) {
    return React.cloneElement(children, {
      style: wpContext ? { textDecoration: 'none' } : undefined,
      href,
    });
  }
  return (
    <Link href={href} prefetch={prefetch} legacyBehavior>
      {children}
    </Link>
  );
};

export default AppLink;
