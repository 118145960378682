import 'styles/styles.scss';

import React from 'react';
import { SessionProvider } from 'next-auth/react';
import { Session } from 'next-auth';
import { AppProps } from 'next/app';
import Head from 'next/head';

import CampgroundsSavedToListProvider from 'contexts/campgroundsSavedToLists';
import { UserProvider } from 'contexts/currentUser';
import { FastlyRequestHeadersProvider } from 'contexts/fastlyRequestHeaders';
import GoogleAdContextProvider from 'contexts/googleAds';
import { ManagerProvider } from 'contexts/manager';
import MapStyleProvider from 'contexts/mapStyle';
import { NavDrawerContextProvider } from 'contexts/navDrawer';
import { OverlayProvider } from 'contexts/overlay';
import ReservationProvider from 'contexts/reservations';
import SearchProvider from 'contexts/search';
import WPProvider from 'contexts/wordPress';

import Analytics from 'components/Analytics/Analytics';
import AppOverlay from 'components/AppOverlay/AppOverlay';
import OneTapComponent from 'components/OneTapComponent/OneTapComponent';
import SentryComponent from 'components/SentryComponent/SentryComponent';

interface PageProps {
  fastlyABTestPageProps: Record<string, TestVariant | null>;
  fastlySessionPageProps: FastlySessionPageProps;
  session?: Session;
}

interface DyrtAppProps extends AppProps {
  pageProps: PageProps;
}

function MyApp({ Component, pageProps }: DyrtAppProps): JSX.Element {
  const { fastlyABTestPageProps, fastlySessionPageProps, session } = pageProps;

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        ></meta>
      </Head>
      <SessionProvider session={session}>
        <UserProvider>
          <FastlyRequestHeadersProvider
            fastlyABTestPageProps={fastlyABTestPageProps}
            fastlySessionPageProps={fastlySessionPageProps}
          >
            <ManagerProvider>
              <OverlayProvider>
                <CampgroundsSavedToListProvider>
                  <SearchProvider>
                    <AppOverlay />
                    <NavDrawerContextProvider>
                      <WPProvider value={false}>
                        <MapStyleProvider>
                          <ReservationProvider>
                            <GoogleAdContextProvider>
                              <Component {...pageProps} />
                            </GoogleAdContextProvider>
                            <OneTapComponent />
                            <Analytics />
                            <SentryComponent />
                          </ReservationProvider>
                        </MapStyleProvider>
                      </WPProvider>
                    </NavDrawerContextProvider>
                  </SearchProvider>
                </CampgroundsSavedToListProvider>
              </OverlayProvider>
            </ManagerProvider>
          </FastlyRequestHeadersProvider>
        </UserProvider>
      </SessionProvider>
    </>
  );
}

export default MyApp;
