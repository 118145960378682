import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import { formatDayOfWeekDate } from 'utils/dates';

import useLoadRecord from 'hooks/useLoadRecord';
import { useOverlay, useOverlayContext } from 'hooks/useOverlayContext';

import AppButton from 'components/AppButton/AppButton';
import AppModal from 'components/AppModal/AppModal';

import styles from './styles/InternalBookingModal.module.scss';

export interface InternalBookingModalProps {
  className?: string;
}

export const InternalBookingModal: React.FC<InternalBookingModalProps> = ({
  className,
}: InternalBookingModalProps) => {
  const [reservation, setReservation] = useState<ReservationModel | null>(null);
  const overlay = useOverlay<ManagerInternalBookingOverlay>(
    'manager-internal-booking'
  );
  const { setOverlay } = useOverlayContext<ManagerInternalBookingOverlay>();

  const { loadRecord } = useLoadRecord();
  const router = useRouter();
  const {
    query: { campground },
  } = router;
  const bdr = overlay?.blockedDateRange;

  const guestCount = reservation?.pricingInformation?.guestCount || 0;
  const petCount = reservation?.pricingInformation?.petCount || 0;
  const vehicleCount = reservation?.pricingInformation?.vehicleCount || 0;

  const startDate =
    reservation?.startDate && formatDayOfWeekDate(reservation.startDate);
  const endDate =
    reservation?.endDate && formatDayOfWeekDate(reservation.endDate);

  useEffect(() => {
    if (bdr?.reservationId) {
      loadRecord<ReservationModel>(
        'reservations',
        bdr?.reservationId,
        {},
        undefined,
        ({ data }) => setReservation(data || null)
      );
    }
  }, [bdr, loadRecord]);

  return (
    <AppModal
      type="manager-internal-booking"
      className={`${styles['internal-booking-modal']} ${className}`}
      padded={false}
      content={
        <>
          {reservation && (
            <>
              <div className={styles['internal-booking-modal__content']}>
                <h4 className={styles['internal-booking-modal__title']}>
                  Booking #{reservation.confirmationCode}
                </h4>
                <p className={styles['internal-booking-modal__dates']}>
                  {startDate} - {endDate}
                </p>
                <div className={styles['internal-booking-modal__guest-info']}>
                  <span>
                    {guestCount} {guestCount === 1 ? 'Guest' : 'Guests'}
                  </span>
                  <span>
                    {petCount} {petCount === 1 ? 'Pet' : 'Pets'}
                  </span>
                  <span>
                    {vehicleCount} {vehicleCount === 1 ? 'Vehicle' : 'Vehicles'}
                  </span>
                </div>
              </div>
              <div className={styles['internal-booking-modal__footer']}>
                <AppButton
                  type={'button'}
                  className={
                    styles['internal-booking-modal__view-booking-button']
                  }
                  label={'View Booking Details'}
                  onClick={() => {
                    router.push(
                      `/manager/campgrounds/${campground}/reservations/${reservation.id}/`
                    );
                    setOverlay(null);
                  }}
                  dataEvent={`click_manager_view_booking_details`}
                />
              </div>
            </>
          )}
        </>
      }
    />
  );
};

export default InternalBookingModal;
