import { useState, useCallback, useMemo } from 'react';

import { createRecord as createRecordUtil } from 'utils/JSONAPIAdapter';

import useAuthenticationHeader from './useAuthenticationHeaders';
import useHandleApiError from './useHandleApiError';

interface State {
  errors: string[] | null;
  loading: boolean;
}

const useCreateRecord = () => {
  const authenticationHeader = useAuthenticationHeader();

  const handleApiError = useHandleApiError();

  const [state, setState] = useState<State>({ loading: false, errors: null });

  const createRecord = useCallback(
    async function <T extends DyrtModel>(
      record: Partial<T>,
      relationships?: Record<string, JSONAPIRelationship>,
      onSuccess?: (data: T) => unknown | Promise<unknown>
    ) {
      setState({ loading: true, errors: null });

      try {
        const response = await createRecordUtil<T>(
          record,
          {},
          authenticationHeader,
          relationships
        );

        setState({
          loading: false,
          errors: null,
        });

        if (onSuccess) {
          onSuccess(response.data as T);
        }

        return { record: response.data, errors: null };
      } catch (err) {
        const errors = handleApiError(err);
        setState({ loading: false, errors });

        return { record: null, errors };
      }
    },
    [setState, authenticationHeader, handleApiError]
  );

  const clearErrors = useCallback(
    () => setState({ ...state, errors: null }),
    [state]
  );

  return useMemo(() => {
    return {
      createRecord,
      clearErrors,
      ...state,
    };
  }, [createRecord, state, clearErrors]);
};

export default useCreateRecord;
