import { DEFAULT_MAP_CENTER } from 'constants/map';

const API_BASE = process.env.NEXT_PUBLIC_API_BASE;

export async function fetchLocation(): Promise<UserLocation> {
  try {
    const data = await fetch(`${API_BASE?.split('/api')[0]}/edge/geoip`).then(
      (response) => {
        return response.json();
      }
    );

    data.lat = +data.lat;
    data.long = +data.long;
    data.userLocationFound = true;
    return data as UserLocation;
  } catch (e) {
    return DEFAULT_MAP_CENTER;
  }
}
