import React from 'react';

import AppFormDatePicker from 'components/AppForm/AppFormDatePicker';
import AppFormError from 'components/AppForm/AppFormError';
import AppFormInputCheckbox from 'components/AppForm/AppFormInputCheckbox';
import AppFormTextArea from 'components/AppForm/AppFormTextArea';

import styles from './styles/BlockedDateRangeModal.module.scss';

interface BlockedDateFormBodyProps {
  errors: string[] | null;
  allSites?: boolean;
}

const BlockedDateFormBody: React.FC<BlockedDateFormBodyProps> = ({
  errors,
  allSites,
}) => {
  return (
    <div className={styles['block__input-wrapper']}>
      <AppFormDatePicker
        className={styles['block__date']}
        name="startDate"
        label="Start Date"
        required
        validation={{
          required: 'A start date is required.',
        }}
        monthsShown={1}
      />
      <AppFormDatePicker
        className={styles['block__date']}
        name="endDate"
        label="End Date"
        required
        validation={{
          required: 'An end date is required.',
        }}
        monthsShown={1}
      />
      <AppFormTextArea
        label="Add a reminder about this block"
        name="description"
        placeholder="e.g. You already have a booking for these dates on another platform."
      />
      {allSites && (
        <AppFormInputCheckbox
          name="allSites"
          className={styles['sign-up__checkbox']}
          label={'Apply to all sites.'}
        />
      )}
      <AppFormError errors={errors} className={styles['block__date']} />

      <p className={styles['block__link']}>
        We’re here to help.{' '}
        <a
          href="https://support.thedyrt.com/hc/en-us"
          className={styles['block__link-help']}
        >
          Visit our FAQs
        </a>{' '}
        or{' '}
        <a
          className={styles['block__link-help']}
          href="mailto:support@thedyrt.com"
        >
          Contact Us
        </a>
        .
      </p>
    </div>
  );
};

export default BlockedDateFormBody;
