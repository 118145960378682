import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';

// fill an array from 1 to maxDays with InputOption type
export const getMaxDays = (maxDays: number): InputOption<'label', 'id'>[] => {
  const days = Array(maxDays)
    .fill(0)
    .map((_, i) => i + 1);

  return days.reduce((memo: InputOption<'label', 'id'>[], day: number) => {
    const dayFormatted = `${day}`.padStart(2, '0');

    memo.push({
      id: dayFormatted,
      label: `${day}`,
    });

    return memo;
  }, []);
};

// parse an ISO date string without a year in --mm-dd format
export const getMonthDay = (date: string | null): string[] => {
  if (!date) {
    return [];
  }

  return date.split('-').slice(2);
};

// Returns dates like '2021-12-05'
export const formatISOApiString = (date: Date | string) => {
  const parsed = date instanceof Date ? date : parseISO(date);
  return formatISO(parsed, { representation: 'date' });
};

// Returns dates like 'January 25, 2022' or 'Jan. 25, 2022'
export const formatReadableDate = (
  date: Date | string,
  abbreviateMonth?: boolean
) => {
  if (date) {
    const parsed = date instanceof Date ? date : parseISO(date);
    return abbreviateMonth
      ? format(parsed, 'MMM. d, yyyy')
      : format(parsed, 'MMMM d, yyyy');
  }
};

export const formatMonthYear = (date: Date | string | null) => {
  if (date) {
    const parsed = date instanceof Date ? date : parseISO(date);
    return format(parsed, 'MMMM yyyy');
  }
};

// Returns dates like "08/09/2021"
export const formatPaddedNumericDate = (date: Date | string) => {
  if (date) {
    const parsed = date instanceof Date ? date : parseISO(date);
    return format(parsed, 'MM/dd/yyyy');
  }
};

// Returns dates like "4/19/22"
export const formatShortDate = (date: Date | string) => {
  if (date) {
    const parsed = date instanceof Date ? date : parseISO(date);
    return format(parsed, 'M/d/yy');
  }
};

// Returns dates like "Wednesday, 08/09/2021"
export const formatDayOfWeekDate = (date: Date | string) => {
  if (date) {
    const parsed = date instanceof Date ? date : parseISO(date);
    return format(parsed, 'EEEE, MM/dd/yyyy');
  }
};

// Returns dates like 8/9/2021, 1:03 PM
export const formatDateWithTime = (date: Date | string) => {
  if (date) {
    const parsed = date instanceof Date ? date : parseISO(date);
    return format(parsed, 'M/d/yyyy, PP');
  }
};

// Returns dates like "Wed Aug 09, 2021"
export const formatJournalEntryDate = (date: Date | string) => {
  if (date) {
    const parsed = date instanceof Date ? date : parseISO(date);
    return format(parsed, 'EEE MMM dd, yyyy');
  }
};
export const convertDateToISODisplayString = (date: Date) =>
  format(date, 'MM/dd') as ISODisplayString;

export const convertISOApiStringToDate = (dateString: ISOApiString) =>
  parseISO(dateString);

export const convertISOApiStringsToDates = (
  dateStringsArray?: ISOApiString[]
) =>
  dateStringsArray?.map((dateString) => convertISOApiStringToDate(dateString));

export const convertDateToISOApiString = (date: Date) =>
  formatISO(date, { representation: 'date' }) as ISOApiString;

// Returns dates like "2/2/2011" read as MM/DD/YYYY without leading 0s
export const formatMonthDayYearSlash = (date: Date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear().toString().substr(2, 2);

  return `${month}/${day}/${year}`;
};
