import { useState, useCallback, useMemo } from 'react';

import { loadRecord as loadRecordUtil } from 'utils/JSONAPIAdapter';

import useAuthenticationHeader from './useAuthenticationHeaders';
import useHandleApiError from './useHandleApiError';

interface State {
  errors: string[] | null;
  loading: boolean;
}

const useLoadRecord = () => {
  const [state, setState] = useState<State>({
    loading: false,
    errors: null,
  });

  const authenticationHeader = useAuthenticationHeader();

  const handleApiError = useHandleApiError();

  const loadRecord = useCallback(
    async function <T extends DyrtModel, I extends DyrtModel = DyrtModel>(
      typeOrEndpoint: string,
      id: string,
      options: JSONAPIOptions = {},
      headers?: Record<string, string>,
      onSuccess?: (
        response: DeserializedJSONAPIResult<T, I>
      ) => Promise<unknown> | unknown,
      withRelated?: boolean
    ) {
      setState({
        loading: true,
        errors: null,
      });

      try {
        const response = await loadRecordUtil<T, I>(
          typeOrEndpoint,
          id,
          options,
          {
            ...headers,
            ...authenticationHeader,
          },
          undefined,
          withRelated
        );

        setState({ errors: null, loading: false });

        if (onSuccess) {
          onSuccess(response);
        }

        return response;
      } catch (err) {
        const errors = handleApiError(err);
        setState({
          loading: false,
          errors,
        });
      }
    },
    [setState, authenticationHeader, handleApiError]
  );

  return useMemo(() => {
    return {
      loadRecord,
      ...state,
    };
  }, [loadRecord, state]);
};

export default useLoadRecord;
