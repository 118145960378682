import React from "react";
import { useRouter } from 'next/router';

import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import AppButtonLink from 'components/AppButton/AppButtonLink';
import AppModal from 'components/AppModal/AppModal';

import styles from "./styles/SetupBookingsSuccessModal.module.scss";

export interface SetupBookingsSuccessModalProps {
  className?: string 
}

export const SetupBookingsSuccessModal: React.FC<SetupBookingsSuccessModalProps> = ({ className }: SetupBookingsSuccessModalProps) => {
  const router = useRouter();

  const {
    query: { campground },
  } = router;

  return (
    <AppModal
      type="setup-bookings-success"
      className={`${styles['setup-bookings-success']} ${className}`}
      disableClose
      padded={false}
      content={
        <>
          <div className={styles['setup-bookings-success__text']}>
            <h2 className={styles['setup-bookings-success__title']}>
              Great news! You’re eligible for commission-free bookings.
            </h2>
            <img
              className={styles['setup-bookings-success__image']}
              srcSet={`${PUBLIC_ASSETS_PATH}/assets/images/claim/Notebook_Illustration_1x.png, ${PUBLIC_ASSETS_PATH}/assets/images/claim/Notebook_Illustration_2x.png 2x`}
              src={`${PUBLIC_ASSETS_PATH}/assets/images/claim/Notebook_Illustration_1x.png`}
              alt="A notebook with a checklist of campgrounds and a pencil."
            />
            <p>Enable bookings by completing the steps in your Dashboard.</p>
          </div>

          <div className={styles['setup-bookings-success__footer']}>
            <AppButtonLink
              className={styles['setup-bookings-success__link']}
              path={`/manager/campgrounds/${campground}`}
              label="Got It"
              icon="arrow_right"
              dataEvent="Manager_Setup-Bookings_Success"
            />
          </div>
        </>
      }
    />
  );
};

export default SetupBookingsSuccessModal;