import React, { useState, useRef } from 'react';

import { copyText } from 'utils/clipboard';

import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/AppModal.module.scss';

export interface ShareLinksProps {
  textToShare?: string;
  urlToShare: string;
}

export const ShareLinks: React.FC<ShareLinksProps> = ({
  textToShare,
  urlToShare,
}: ShareLinksProps) => {
  const initialState = 'Copy';
  const [copySuccess, setState] = useState(initialState);
  const clearState = () => {
    setState(initialState);
  };

  const windowRef = useRef<Window | null>(null);
  const windowName = 'FacebookWindow';

  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${urlToShare}`;
  const twitterUrl = `https://twitter.com/intent/tweet?text=${textToShare}&url=${urlToShare}`;

  async function copyUrlToClipboard() {
    const error = copyText(urlToShare);
    const errorMessage = 'Error, copy not supported';
    const successMessage = 'Copied!';
    const message = error !== undefined ? errorMessage : successMessage;
    setState(message);
    setTimeout(() => {
      clearState();
    }, 2000);
  }

  const openPopup = () => {
    if (windowRef.current == null || windowRef.current?.closed) {
      const popupWidth = 700;
      const popupHeight = 400;
      const left = window.screenLeft + window.innerWidth / 2 - popupWidth / 2;
      const top = window.screenTop + 200;

      windowRef.current = window.open(
        facebookUrl,
        windowName,
        `resizable,scrollbars,status,width=${popupWidth},height=${popupHeight},top=${top},left=${left}`
      );
    } else {
      windowRef.current.focus();
    }
    return false;
  };

  return (
    <ul className={styles['share-links']}>
      <li className={styles['share-links__item']}>
        <a
          className={styles['share-links__link']}
          href={facebookUrl || '/'}
          target={windowName}
          rel="noreferrer"
          onClick={openPopup}
        >
          <div className={styles['share-links__icon-background']}>
            <AppIcon
              classNameSvg={[styles['share-links__icon']]}
              icon="facebook"
            />
          </div>
          <span className={styles['share-links__label']}>Facebook</span>
        </a>
      </li>
      <li className={styles['share-links__item']}>
        <a
          className={styles['share-links__link']}
          href={twitterUrl || '/'}
          target="_blank"
          rel="noreferrer"
        >
          <div className={styles['share-links__icon-background']}>
            <AppIcon
              classNameSvg={[styles['share-links__icon']]}
              icon="twitter"
            />
          </div>
          <span className={styles['share-links__label']}>Twitter</span>
        </a>
      </li>
      <li className={styles['share-links__item']}>
        <button
          className={styles['share-links__link__copy-button']}
          onClick={() => copyUrlToClipboard()}
          data-event="click_copy_share_to_clipboard"
        >
          <div className={styles['share-links__icon-background']}>
            <AppIcon classNameSvg={[styles['share-links__icon']]} icon="link" />
          </div>
          <span className={styles['share-links__label']}>{copySuccess}</span>
        </button>
      </li>
    </ul>
  );
};

export default ShareLinks;
