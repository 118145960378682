import React from 'react';

import useDeleteRecord from 'hooks/useDeleteRecord';
import { useOverlay, useOverlayContext } from 'hooks/useOverlayContext';
import useUpdateRecord from 'hooks/useUpdateRecord';

import AppButton from 'components/AppButton/AppButton';
import AppForm from 'components/AppForm/AppForm';
import AppModal from 'components/AppModal/AppModal';

import {
  useExternalToPicker,
  usePickerToExternal,
} from './hooks/useHandleRang';

import BlockedDateFormBody from './BlockedDateFormBody';

import styles from './styles/BlockedDateRangeModal.module.scss';

export interface EditBlockedDateRangeModalProps {
  className?: string;
}

export const EditBlockedDateRangeModal: React.FC<
  EditBlockedDateRangeModalProps
> = ({ className }) => {
  const { close } = useOverlayContext<ManagerEditBlockDateOverlay>();

  const overlay = useOverlay<ManagerEditBlockDateOverlay>(
    'manager-block-dates-edit'
  );

  const externalToPicker = useExternalToPicker();

  const pickerToExternal = usePickerToExternal();

  const { deleteRecord, loading: deleting } = useDeleteRecord();

  const {
    updateRecord,
    loading: updating,
    errors,
    clearErrors,
  } = useUpdateRecord();

  const deleteBlockedDates = async () => {
    if (overlay) {
      await deleteRecord(overlay.blockedDateRange, () => {
        overlay.onRemove(overlay.blockedDateRange);
        close();
      });
    }
  };

  const updateBlockedDates: AppFormSubmitHandler<BlockedDateRangeForm> = async (
    update
  ) => {
    await updateRecord<BlockedDateRangeModel>(
      pickerToExternal(update),
      {},
      (blockedDateRange) => {
        overlay?.onUpdate(blockedDateRange);
        close();
      }
    );
  };

  return (
    <AppModal
      type="manager-block-dates-edit"
      className={`${styles['edit']} ${className}`}
      padded={false}
      content={
        <>
          <div className={styles['block__header']}>
            <h3 className={styles['block__title']}>Edit Block</h3>
            <p>
              Sites will not be bookable on The Dyrt or your synced calendars
              for the dates you block.
            </p>
          </div>
          <AppForm<BlockedDateRangeForm>
            className={styles['edit__picker']}
            onSubmit={updateBlockedDates}
            formOptions={{
              defaultValues: externalToPicker(overlay.blockedDateRange),
            }}
          >
            <BlockedDateFormBody errors={errors} />
            <div className={styles['block__footer']}>
              <div className={styles['block__footer__actions']}>
                <AppButton
                  label="Delete Block"
                  disabled={deleting}
                  dataEvent="click-manager-edit-dates-modal-delete-block"
                  ghost="warning"
                  onClick={deleteBlockedDates}
                  type="button"
                />
                <AppButton
                  className={className}
                  label="Update Block"
                  dataEvent="click-manager-edit-dates-modal-update-block"
                  disabled={updating ? 'loading' : false}
                  type="submit"
                />
              </div>
            </div>
          </AppForm>
        </>
      }
      onClose={() => {
        clearErrors();
      }}
    />
  );
};

export default EditBlockedDateRangeModal;
