import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import * as amplitude from '@amplitude/analytics-browser';

import { PRO_CTA_CLICK } from 'constants/amplitude';
import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import { useUser } from 'contexts/currentUser';
import { useSearch } from 'contexts/search';

import useAmplitudePageName from 'hooks/useAmplitudePageName';
import useRouterMethod from 'hooks/useRouterMethod';

import AppButton from 'components/AppButton/AppButton';
import AppButtonLink from 'components/AppButton/AppButtonLink';

import _SidebarFiltersFilter from './_SidebarFiltersFilter';
import _SidebarFiltersSubgroup from './_SidebarFiltersSubgroup';

import styles from './styles/SearchCampgrounds.module.scss';

export interface GroupProps {
  className?: string;
  searchFilterGroup: SearchFilterGroup;
}

const extractFilterKeys = (
  group: SearchFilterGroup,
  ids: Array<keyof AppliedSearchFilter> = []
) => {
  group.content.map((item) => {
    if ('id' in item) {
      ids.push(item.id);
    } else {
      extractFilterKeys(item, ids);
    }
  });
  return ids;
};

const _SidebarFiltersGroup: React.FC<GroupProps> = ({
  className,
  searchFilterGroup,
}: GroupProps) => {
  const userState = useUser();
  const { push } = useRouterMethod();

  const { toggleProUpgradeSidebar } = useUser();

  const { searchFilters } = useSearch();

  const [filterGroupOpen, setFilterOpen] = useState(false);
  const [ratingsValue, setRatingsValue] = useState('');

  const filterKeys = extractFilterKeys(searchFilterGroup);

  const values = useWatch({ name: filterKeys });

  useEffect(() => {
    if (filterKeys[0] === 'rating') {
      const ratingOptions = searchFilters['rating']?.options;

      const displayValue =
        ratingOptions?.find(
          (opt: SearchFilterOption) => opt?.value === values[0]
        )?.displayName || '';

      setRatingsValue(displayValue.replace(/-/g, ' '));
    }
  }, [values, filterKeys, searchFilters]);

  const pageName = useAmplitudePageName();

  const getNumberOfActiveFilters = () => {
    return filterKeys.reduce((sum, key, index) => {
      const targetFilter = searchFilters[key];
      if (
        values[index] &&
        values[index] !== targetFilter.filterMeta.defaultValue
      ) {
        if (targetFilter.controlType === 'multi_select') {
          const value: string = values[index] || '';
          sum += value.split(',').length;
        } else {
          sum += 1;
        }
      }
      return sum;
    }, 0);
  };

  const numberOfActiveFilters = getNumberOfActiveFilters();

  const filterPaywallInput =
    searchFilterGroup.pro &&
    (searchFilterGroup.id === 'available_carriers' ||
      searchFilterGroup.id === 'free_or_dispersed' ||
      searchFilterGroup.id === 'pro_categories') &&
    !userState?.user?.pro;

  const filterPaywallButton =
    searchFilterGroup.pro &&
    (searchFilterGroup.id === 'available_carriers' ||
      searchFilterGroup.id === 'free_or_dispersed' ||
      searchFilterGroup.id === 'partner' ||
      searchFilterGroup.id === 'pro_categories') &&
    !userState?.user?.pro;

  const proFeatureDisabledFilters =
    (searchFilterGroup.id === 'free_or_dispersed' ||
      searchFilterGroup.id === 'available_carriers' ||
      searchFilterGroup.id === 'pro_categories') &&
    !userState?.user?.pro;

  useEffect(() => {
    const listItem = document.getElementById(searchFilterGroup.name);
    if (filterGroupOpen && listItem) {
      listItem.scrollIntoView({ behavior: 'smooth' });
    }
  }, [filterGroupOpen, searchFilterGroup]);

  const getUniqueLinkText = (id: string) => {
    switch (id) {
      case 'free_or_dispersed':
        return `Unlock 5000 Locations with PRO`;
        break;
      case 'partner':
        return `Unlock Up to 40% off with PRO`;
        break;
      case 'pro_categories':
        return `Unlock Parking, Dump, + Water`;
        break;

      default:
        return `Unlock ${searchFilterGroup.name} with PRO`;
        break;
    }
  };

  const handleDisabledFiltersClick = () => {
    if (
      proFeatureDisabledFilters &&
      searchFilterGroup.id === 'available_carriers'
    ) {
      toggleProUpgradeSidebar(true);
    } else if (
      proFeatureDisabledFilters &&
      searchFilterGroup.id === 'free_or_dispersed'
    ) {
      push('http://pro.thedyrt.com/join-now-free-camping');
    } else if (
      proFeatureDisabledFilters &&
      searchFilterGroup.id === 'pro_categories'
    ) {
      push('/pro/checkout');
    }
  };

  return (
    <ul
      className={`${className} ${styles['group']} ${styles['group--is-open']}`}
    >
      <li id={searchFilterGroup.id}>
        <button
          className={styles['group__list-button']}
          onClick={() => setFilterOpen(!filterGroupOpen)}
          type="button"
        >
          <p className={styles['group__list-text']}>
            {searchFilterGroup.pro && (
              <img
                className={styles['group__list-pro-badge']}
                src={`${PUBLIC_ASSETS_PATH}/assets/badges/pro.svg`}
                alt={`The Dyrt Pro`}
              />
            )}
            {searchFilterGroup.name}
            <span className={styles['group__list-active']}>
              {searchFilterGroup.name === 'Minimum Rating' ? (
                <>({ratingsValue})</>
              ) : (
                numberOfActiveFilters > 0 && <>({numberOfActiveFilters})</>
              )}
            </span>
          </p>
        </button>
        <div
          className={`${styles['group__content']} ${styles['group']} ${styles['group--is-open']}`}
        >
          {searchFilterGroup.content.map((subgroup, index) => {
            if (subgroup.type === 'search-filter-group') {
              return (
                <_SidebarFiltersSubgroup
                  key={index}
                  searchFilterGroup={subgroup}
                  searchFilters={searchFilters}
                />
              );
            } else if (subgroup.type === 'search-filters') {
              return (
                <div key={index} onClick={handleDisabledFiltersClick}>
                  <_SidebarFiltersFilter
                    searchFilter={searchFilters[subgroup.id]}
                    disabled={filterPaywallInput || false}
                    descriptionClassName={styles['group__description']}
                  />
                </div>
              );
            }
            return null;
          })}
          {filterPaywallButton && (
            <>
              {searchFilterGroup.name === 'Free Camping Collection' ? (
                <AppButtonLink
                  className={styles['group__pro-cta']}
                  label={getUniqueLinkText(searchFilterGroup.id)}
                  pro={'gradient'}
                  path={`http://pro.thedyrt.com/join-now-free-camping`}
                  onClick={() => {
                    amplitude.track(PRO_CTA_CLICK, {
                      'button name': 'Unlock 5000 Locations with PRO',
                      'page name': pageName,
                    });
                  }}
                  icon={`arrow_right`}
                  dataEvent={`click_pro_cta_filters_panel`}
                />
              ) : (
                <AppButton
                  className={styles['group__pro-cta']}
                  label={getUniqueLinkText(searchFilterGroup.id)}
                  pro={'gradient'}
                  type={'button'}
                  onClick={() => {
                    if (searchFilterGroup.name === 'Cell Service') {
                      toggleProUpgradeSidebar(true);
                    } else if (searchFilterGroup.id === 'pro_categories') {
                      push(`/pro/checkout`);
                    } else {
                      push(`/pro`);
                    }
                  }}
                  icon={`arrow_right`}
                  dataEvent={`click_pro_cta_filters_panel`}
                />
              )}
            </>
          )}
        </div>
      </li>
    </ul>
  );
};

export default _SidebarFiltersGroup;
