import React, {
  createContext,
  useCallback,
  useReducer,
  useMemo,
  useContext,
  useEffect,
} from 'react';
import { useRouter } from 'next/router';
import * as amplitude from '@amplitude/analytics-browser';
import isEmpty from 'lodash.isempty';

import { MAP_FILTER_OPEN } from 'constants/amplitude';
import { BOUNDING_FILTERS } from 'constants/search';

import { useUser } from 'contexts/currentUser';

import { buildValidFilter } from 'utils/search';
import { nonNullable } from 'utils/typeAssertions';

import useLoadRecord from 'hooks/useLoadRecord';
import useLoadRecords from 'hooks/useLoadRecords';
import { useOverlayContext } from 'hooks/useOverlayContext';
import useRouterMethod from 'hooks/useRouterMethod';

type SearchReducer = Omit<
  SearchContext,
  'attributeFilter' | 'boundingFilter' | 'updateSearchContext' | 'showFilters'
>;

const defaultSearchContext: SearchReducer = {
  activeCampground: null,
  activePanel: 'results',
  layersToggleShown: false,
  campgrounds: null,
  currentBbox: null,
  fullscreen: false,
  hoverCampground: null,
  campgroundSearchMeta: null,
  searchFilterGroups: [] as SearchFilterGroup[],
  searchFilters: {} as Record<SearchFilter['id'], SearchFilter>,
  searchLocation: null,
  searchThisAreaActive: false,
  updateOnMove: true,
  zoomLevel: 10,
  appliedSort: 'recommended',
  search: () => {},
};

const SearchContext = createContext<SearchContext>({
  ...defaultSearchContext,
  boundingFilter: null,
  attributeFilter: null,
  updateSearchContext: () => {},
  showFilters: () => {},
});

type SearchAction = {
  type: 'SET_SEARCH_ATTRIBUTE';
  payload: Partial<SearchContext>;
};

const searchReducer = (
  state: SearchReducer,
  action: SearchAction
): SearchReducer => {
  switch (action.type) {
    case 'SET_SEARCH_ATTRIBUTE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const SearchProvider: React.FC = ({ children }) => {
  const { loadRecords: loadSearchFilters, loading: loadingSearchFilters } =
    useLoadRecords();

  const { userLocation } = useUser();

  const { replace } = useRouterMethod();

  const { asPath } = useRouter();
  const { setOverlay } = useOverlayContext();

  const {
    query: { filters },
  } = useRouter();

  const {
    loadRecord: loadSearchFilterConfig,
    loading: loadingSearchFilterConfig,
  } = useLoadRecord();

  const [searchState, updateSearchContextState] = useReducer(
    searchReducer,
    defaultSearchContext
  );

  const { searchFilters } = searchState;

  const parsedURLFilters = useMemo(() => {
    if (filters && typeof filters === 'string') {
      try {
        return JSON.parse(filters) as AppliedSearchFilter;
      } catch (e) {
        console.error(e);
      }
    }

    return {};
  }, [filters]);

  const boundingFilter = useMemo(() => {
    const [boundingKey, boundingValue] =
      Object.entries(parsedURLFilters).find(
        ([key, value]) =>
          BOUNDING_FILTERS.includes(key as keyof AppliedBoundingFilter) &&
          nonNullable(value)
      ) || [];

    if (boundingKey) {
      return { [boundingKey]: boundingValue } as AppliedBoundingFilter;
    } else if (userLocation) {
      return {
        around: `${userLocation.long},${userLocation.lat}`,
      } as AppliedBoundingFilter;
    }
    null;
  }, [parsedURLFilters, userLocation]);

  const attributeFilter = useMemo(() => {
    if (!isEmpty(searchFilters)) {
      return Object.values(searchFilters).reduce((memo, filter) => {
        const key = filter.id;
        const value = parsedURLFilters[key];

        if (key === 'available' && value) {
          memo['start_date'] = parsedURLFilters['start_date'] || null;
          memo['end_date'] = parsedURLFilters['end_date'] || null;
          memo['available'] = value as string;
        } else if (
          (value || filter.filterMeta.defaultValue) &&
          !BOUNDING_FILTERS.includes(filter.id as keyof AppliedBoundingFilter)
        ) {
          // @ts-ignore
          memo[key] = value || filter.filterMeta.defaultValue;
        }

        return memo;
      }, {} as AppliedAttributeFilter) as AppliedAttributeFilter;
    }

    return null;
  }, [searchFilters, parsedURLFilters]);

  const updateSearchContext = useCallback(
    (update: Partial<SearchContext>) => {
      updateSearchContextState({
        type: 'SET_SEARCH_ATTRIBUTE',
        payload: update,
      });
    },
    [updateSearchContextState]
  );

  const search = useCallback(
    (newAttributeFilter = {}, newBoundingFilter = boundingFilter) => {
      const [path, qp = ''] = asPath.split('?');

      const searchParams = new URLSearchParams(qp);

      searchParams.set(
        'filters',
        JSON.stringify({
          ...buildValidFilter({
            ...attributeFilter,
            ...newAttributeFilter,
          }),
          ...newBoundingFilter,
        })
      );

      updateSearchContext({ activePanel: 'results' });
      replace(`${path}?${searchParams.toString()}`, undefined, {
        shallow: true,
      });
    },
    [replace, asPath, updateSearchContext, boundingFilter, attributeFilter]
  );

  const setSearchFilters = useCallback(async () => {
    loadSearchFilters<SearchFilter>('locations/filters', {}, {}, ({ data }) => {
      updateSearchContext({
        searchFilters: data.reduce((memo, filter) => {
          memo[filter.id] = filter;
          return memo;
        }, {} as Record<SearchFilter['id'], SearchFilter>),
      });
    });
  }, [updateSearchContext, loadSearchFilters]);

  const setSearchFilterConfig = useCallback(async () => {
    loadSearchFilterConfig<SearchFilterConfig>(
      'locations/filter-configs',
      '',
      {},
      undefined,
      ({ data }) => {
        updateSearchContext({
          searchFilterGroups: data.filtersPanel
            .filter((item) => !item.hidden)
            .map((item) => ({
              ...item,
              id: item.content[0].id,
            })),
        });
      }
    );
  }, [loadSearchFilterConfig, updateSearchContext]);

  const showFilters = useCallback(
    (scrollToFilterId: string) => {
      amplitude.track(MAP_FILTER_OPEN);

      if (window.innerWidth <= 900) {
        setOverlay({
          type: 'sidebar-filters',
        });
      } else {
        updateSearchContext({ activePanel: 'filters' });
      }
      if (scrollToFilterId) {
        setTimeout(() => {
          document.getElementById(scrollToFilterId)?.scrollIntoView({
            behavior: 'smooth',
          });
        }, 500);
      }
    },
    [setOverlay, updateSearchContext]
  );

  useEffect(() => {
    if (isEmpty(searchState.searchFilters) && !loadingSearchFilters) {
      setSearchFilters();
    }
  }, [searchState.searchFilters, setSearchFilters, loadingSearchFilters]);

  useEffect(() => {
    if (isEmpty(searchState.searchFilterGroups) && !loadingSearchFilterConfig) {
      setSearchFilterConfig();
    }
  }, [
    searchState.searchFilterGroups,
    setSearchFilterConfig,
    loadingSearchFilterConfig,
  ]);

  const searchContextValue = useMemo(() => {
    return {
      ...searchState,
      updateSearchContext,
      showFilters,
      boundingFilter,
      attributeFilter,
      search,
    } as SearchContext;
  }, [
    searchState,
    updateSearchContext,
    showFilters,
    boundingFilter,
    attributeFilter,
    search,
  ]);

  return (
    <SearchContext.Provider value={searchContextValue}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);

export default SearchProvider;
