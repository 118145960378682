import React from 'react';

import { useOverlay } from 'hooks/useOverlayContext';

import AppModal from 'components/AppModal/AppModal';

export const AppConfirmationModal: React.FC = () => {
  const overlay = useOverlay<AppConfirmationOverlay>(
    'confirmation'
  ) as AppConfirmationOverlay;

  const { title, content, footerButtons, ...rest } = overlay || {};

  return (
    <AppModal
      title={title}
      content={content}
      footerButtons={footerButtons}
      {...rest}
    />
  );
};

export default AppConfirmationModal;
