export const BOUNDING_FILTERS = [
  'region',
  'near',
  'admin_area',
  'around',
  'bbox',
  'origin',
  'trip_route',
  'tri_segment',
] as Array<keyof AppliedBoundingFilter>;

export const FILTERS_PANEL_ID = 'filters-panel-scroll-y';
export const SIDEBAR_WRAPPER_ID = 'sidebar-wrapper';
export const FILTERS_MODAL_WRAPPER_ID = 'filters-modal-wrapper';
