import React from 'react';
import { useRouter } from 'next/router';
import * as amplitude from '@amplitude/analytics-browser';

import { PAGE_NAMES, PRO_CTA_CLICK } from 'constants/amplitude';
import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import AppButtonLink from 'components/AppButton/AppButtonLink';
import _ProFeatureListItem from 'components/ProFeatures/ProFeaturesA/_ProFeatureListItem';
import { PRO_FEATURES } from 'components/ProFeatures/ProFeaturesA/assets/proFeatures';

import AppModal from '../../AppModal';

import styles from './styles/_ProUpgradeModalB.module.scss';

const ACTION_ID = 'pro-upgrade-modal-cta-click-modal-b';

const _ProUpgradeModalB: React.FC = () => {
  const router = useRouter();

  const sendProCTAClickEvent = () => {
    amplitude.track(PRO_CTA_CLICK, {
      'button name': 'Free 7-Day Trial',
      'page name': PAGE_NAMES[router.pathname as PageKeys],
    });
  };

  return (
    <AppModal
      type="pro-upgrade-modal-b"
      wrapperClassName={styles['pro-upgrade-modal-b-wrapper']}
      className={styles['pro-upgrade-modal-b']}
      content={
        <div className={styles['pro-upgrade-modal-b__content']}>
          <img
            className={styles['pro-upgrade-modal-b__logo']}
            src={`${PUBLIC_ASSETS_PATH}/assets/brand/Mark_Pro_Gradient.svg`}
            alt="pro upgrade modal brand logo"
          />
          <p className={styles['pro-upgrade-modal-b__title']}>
            Camp like a PRO
          </p>
          <p className={styles['pro-upgrade-modal-b__desc']}>
            Unlock both premium tools and discounts
          </p>
          <AppButtonLink
            className={styles['pro-upgrade-modal-b__cta']}
            label="Free 7-Day Trial"
            path={`/pro/checkout`}
            icon="right_arrow"
            dataEvent={ACTION_ID}
            onClick={sendProCTAClickEvent}
          />
          <div className={styles['pro-upgrade-modal-b__feature-wrapper']}>
            <div className={styles['pro-upgrade-modal-b__feature']}>
              <img
                className={styles['pro-upgrade-modal-b__pro-map-image']}
                src={`${PUBLIC_ASSETS_PATH}/assets/pro/pro-maps.png`}
                alt="pro upgrade modal pro map feature"
              />
              <div className={styles['pro-upgrade-modal-b__feature-content']}>
                <p className={styles['pro-upgrade-modal-b__feature-title']}>
                  Camp better with PRO Maps
                </p>
                <div className={styles['pro-upgrade-modal-b__feature-list']}>
                  {PRO_FEATURES.maps.services.map((item) => (
                    <_ProFeatureListItem
                      key={item.title}
                      className={
                        styles['pro-upgrade-modal-b__feature-listItem']
                      }
                      title={item.title}
                      desc={item.desc}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className={styles['pro-upgrade-modal-b__feature']}>
              <img
                className={styles['pro-upgrade-modal-b__pro-discount-image']}
                src={`${PUBLIC_ASSETS_PATH}/assets/pro/pro-discounts.png`}
                alt="pro upgrade modal pro discount feature"
              />
              <div className={styles['pro-upgrade-modal-b__feature-content']}>
                <p className={styles['pro-upgrade-modal-b__feature-title']}>
                  Save more with PRO Campground Discounts
                </p>
                <div className={styles['pro-upgrade-modal-b__feature-list']}>
                  {PRO_FEATURES.discounts.services.map((item) => (
                    <_ProFeatureListItem
                      key={item.title}
                      className={
                        styles['pro-upgrade-modal-b__feature-listItem']
                      }
                      title={item.title}
                      desc={item.desc}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};
export default _ProUpgradeModalB;
