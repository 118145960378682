import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames/bind';

import generateUniqueId from 'utils/uniqueId';

import useAttachAmplitudeClickEvents from 'hooks/useAttachAmplitudeClickEvents';
import { useOverlay, useOverlayContext } from 'hooks/useOverlayContext';

import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/AppModal.module.scss';

const cx = classNames.bind(styles);

export interface AppModalProps {
  type: OverlayType;
  className?: string;
  wrapperClassName?: string;
  titleClassName?: string;
  disableClose?: boolean;
  onClose?(e: ModalCloseEvent): void;
  content: JSX.Element;
  footerButtons?: JSX.Element | null;
  footerButtonsCenter?: boolean;
  footerButtonsStackedMobile?: boolean;
  footerPadded?: boolean;
  padded?: boolean;
  doublePadded?: boolean;
  title?: string;
  fullScreenMobile?: boolean;
  mobileBottomDrawerModal?: boolean;
  articleId?: string;
}

export const AppModal = ({
  type,
  className = '',
  wrapperClassName = '',
  titleClassName = '',
  disableClose,
  onClose = () => {},
  content,
  footerButtons,
  footerButtonsCenter,
  footerButtonsStackedMobile,
  footerPadded,
  padded = true,
  doublePadded,
  title,
  fullScreenMobile = true,
  mobileBottomDrawerModal,
  articleId = '',
}: AppModalProps) => {
  const { close } = useOverlayContext();

  const { isActive = false } = useOverlay(type) || {};

  const modalRef = useRef<HTMLDivElement>(null);

  const onCloseCallback = useCallback(
    (e: ModalCloseEvent) => {
      if (!disableClose) {
        close();
      }

      onClose(e);
    },
    [close, onClose, disableClose]
  );

  const modalClick = useCallback(
    (e: ModalCloseEvent) => {
      if (e.target === modalRef.current) {
        onCloseCallback(e);
      }
    },
    [onCloseCallback]
  );

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', modalClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', modalClick);
    };
  }, [modalClick]);

  const id = useMemo(() => generateUniqueId(`modal-${type}`), [type]);
  useAttachAmplitudeClickEvents(`#${id}`);

  const modalContainerClassNames = cx({
    'modal-container': mobileBottomDrawerModal ? false : true,
    'modal-container--mobile-bottom-drawer': mobileBottomDrawerModal,
    'modal-container--is-active': isActive,
    'modal-container--full-screen-mobile': fullScreenMobile,
  });

  const modalClassNames = cx('modal', {
    'modal--padded': padded,
    'modal--padded-double': doublePadded,
    'modal--with-footer': !!footerButtons,
    'modal--full-screen-mobile': fullScreenMobile,
    'modal--mobile-bottom-drawer': mobileBottomDrawerModal,
  });

  const footerClassNames = cx({
    'modal__buttons-bar': true,
    'modal__buttons-bar--centered': footerButtonsCenter,
    'modal__buttons-bar--stackedMobile': footerButtonsStackedMobile,
    'modal__buttons-bar--padded': footerPadded,
  });

  const CloseButton = !disableClose ? (
    <span
      className={styles['modal__close']}
      role="button"
      onClick={onCloseCallback}
      data-testid={`click_close_modal`}
    >
      <AppIcon
        classNameSvg={[styles['modal__x']]}
        icon="close_x"
        dataEvent={`click_close_modal`}
      />
    </span>
  ) : null;

  return (
    <div
      ref={modalRef}
      className={`${modalContainerClassNames} ${wrapperClassName}`}
      id={id}
    >
      <article
        id={articleId}
        className={`${modalClassNames} ${className} ${
          fullScreenMobile ? 'modal--full-screen-mobile' : ''
        }`}
        data-testid={`${type ? `${type}-` : ''}modal-body`}
      >
        <header className={styles['modal__header']}>
          {title && (
            <h2 className={`${styles['modal__title']} ${titleClassName}`}>
              {title}
            </h2>
          )}
          {!disableClose && CloseButton}
        </header>
        <div className={styles['modal__content']}>{content}</div>
        {footerButtons && (
          <footer className={footerClassNames}>{footerButtons}</footer>
        )}
      </article>
    </div>
  );
};

export default AppModal;
