import React from 'react';

import { useOverlay } from 'hooks/useOverlayContext';

import ManagerReservationsModal from './ManagerReservationsModal';

const _Modify = () => {
  const overlay = useOverlay<ManagerReservationOverlay>(
    'manager-reservation-modify'
  );
  const { reservation } = overlay || {};

  return (
    <ManagerReservationsModal
      title="Modify Bookings"
      description="Need to change the details of this booking? The Dyrt Customer Support team is here to help."
      buttonDataEvent={`click_modify_reservation_${reservation?.id}`}
      type="manager-reservation-modify"
    />
  );
};

export default _Modify;
