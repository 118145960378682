import React from 'react';

import useCreateRecord from 'hooks/useCreateRecord';
import { useOverlay, useOverlayContext } from 'hooks/useOverlayContext';

import AppButton from 'components/AppButton/AppButton';
import AppForm from 'components/AppForm/AppForm';
import AppModal from 'components/AppModal/AppModal';

import {
  useExternalToPicker,
  usePickerToExternal,
} from './hooks/useHandleRang';

import BlockedDateFormBody from './BlockedDateFormBody';

import styles from './styles/BlockedDateRangeModal.module.scss';

export interface ManagerCalendarBlockDatesModalProps {
  className?: string;
}

export const ManagerCalendarBlockDatesModal: React.FC<
  ManagerCalendarBlockDatesModalProps
> = ({ className }: ManagerCalendarBlockDatesModalProps) => {
  const overlay = useOverlay<ManagerCreateBlockDatesOverlay>(
    'manager-block-dates-create'
  );
  const { close } = useOverlayContext<ManagerCreateBlockDatesOverlay>();

  const {
    createRecord,
    loading: creating,
    errors,
    clearErrors,
  } = useCreateRecord();

  const externalToPicker = useExternalToPicker();

  const pickerToExternal = usePickerToExternal();

  const createBlockedDates: AppFormSubmitHandler<BlockedDateRangeForm> = async (
    formValues
  ) => {
    if (overlay) {
      const { allSites } = formValues;

      await createRecord<BlockedDateRangeModel>(
        pickerToExternal({
          ...formValues,
          unavailableType: 'closed',
          type: 'blocked-date-ranges',
        }),
        {
          blockable: {
            data: {
              type: allSites ? 'campgrounds' : 'campground-accommodations',
              id: allSites
                ? overlay.accommodation.campgroundId
                : overlay.accommodation.id,
            },
          },
        },
        (blockedDateRange) => {
          overlay.onCreate(blockedDateRange);
          close();
        }
      );
    }
  };

  return (
    <AppModal
      type="manager-block-dates-create"
      className={`${styles['block']} ${className}`}
      padded={false}
      content={
        <>
          <div className={styles['block__header']}>
            <h3 className={styles['block__title']}>Block Dates</h3>
            <p>
              Sites will not be bookable on The Dyrt or your synced calendars
              for the dates you block.
            </p>
          </div>

          <AppForm<BlockedDateRangeForm>
            className={styles['block__picker']}
            onSubmit={createBlockedDates}
            formOptions={{
              defaultValues: externalToPicker({
                startDate: overlay?.startDate,
                endDate: overlay?.endDate,
              }),
            }}
          >
            <BlockedDateFormBody errors={errors} allSites />
            <div className={styles['block__footer']}>
              <div className={styles['block__footer__actions']}>
                <AppButton
                  label="Cancel"
                  dataEvent="click-manager-block-dates-modal-cancel"
                  ghost="warning"
                  borderless
                  onClick={close}
                  type="button"
                />
                <AppButton
                  className={className}
                  label="Save"
                  dataEvent="click-manager-block-dates-modal-save"
                  disabled={creating ? 'loading' : false}
                  type="submit"
                />
              </div>
            </div>
          </AppForm>
        </>
      }
      onClose={() => {
        clearErrors();
      }}
    />
  );
};

export default ManagerCalendarBlockDatesModal;
