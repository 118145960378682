import React from 'react';
import classNames from 'classnames/bind';

import { underscore } from 'utils/caseConversion';

import AppIcon from 'components/AppIcon/AppIcon';
import AppInputRadio, {
  AppInputRadioProps,
} from 'components/AppInput/AppInputRadio';

import styles from './styles/AppInput.module.scss';

const cx = classNames.bind(styles);

export interface AppBoxedRadioProps extends AppInputRadioProps {
  icon?: string | null;
  iconSize?: string;
  category?: string;
  sublabel?: string;
  label_icon?: string;
  label_icon_category?: string;
  position?: string;
  content?: JSX.Element;
  labelClassName?: string;
  subLabelClassName?: string;
  dataTestId?: string;
  dataEvent?: string;
}

export const AppBoxedRadio: React.FC<AppBoxedRadioProps> = ({
  inputRef,
  className,
  label,
  labelClassName,
  sublabel,
  subLabelClassName,
  onChange,
  isChecked,
  icon,
  iconSize,
  category,
  value,
  name,
  label_icon,
  label_icon_category,
  position,
  content,
  disabled,
  dataTestId,
  dataEvent,
  ...inputProps
}) => {
  const classNames = cx({
    boxed: true,
    'boxed--is-selected': isChecked,
    'boxed--align-top': position === 'top',
    'boxed--is-disabled': disabled,
  });

  const appIconClassNames = cx({
    boxed__icon: true,
    'boxed__icon--sm': iconSize === 'small',
    'boxed__icon--lg': iconSize === 'large',
  });

  const appIconSvgClassNames = cx({
    'boxed__icon-svg--lg': iconSize === 'large',
  });

  const labelClassNames = cx({
    boxed__label: true,
    'boxed__label--is-selected': isChecked,
  });

  const labelContainerClassNames = cx({
    boxed__container: true,
    'boxed__container--has-sublabel': sublabel,
  });

  dataEvent = dataEvent || `${underscore(String(label))}_radio_box`;

  return (
    <label className={`${classNames} ${className}`}>
      <div className={labelContainerClassNames}>
        <AppInputRadio
          inputRef={inputRef}
          name={name}
          value={value}
          isChecked={isChecked}
          onChange={onChange}
          disabled={disabled}
          data-testid={dataTestId}
          dataEvent={dataEvent}
          {...inputProps}
        />
        <div className={styles['boxed__label-container']}>
          <span className={`${labelClassNames} ${labelClassName}`}>
            {!!label_icon && (
              <AppIcon
                category={label_icon_category}
                icon={label_icon}
                classNameWrapper={styles['boxed__label-icon']}
              />
            )}
            {label}
          </span>
          {sublabel && (
            <span className={`${styles['m-t-xs']}} ${subLabelClassName}`}>
              {sublabel}
            </span>
          )}
          {content}
        </div>
      </div>
      {!!icon && (
        <AppIcon
          category={category}
          icon={icon}
          classNameWrapper={appIconClassNames}
          classNameSvg={[appIconSvgClassNames]}
        />
      )}
    </label>
  );
};

export default AppBoxedRadio;
