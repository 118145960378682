import React from 'react';

import { formatJournalEntryDate } from 'utils/dates';

import { useOverlay } from 'hooks/useOverlayContext';

import AppMarkdown from 'components/AppMarkdown/AppMarkdown';
import AppModal from 'components/AppModal/AppModal';

import styles from './styles/ExternalBookingModal.module.scss';

export interface ExternalBookingModalProps {
  className?: string;
}

export const ExternalBookingModal: React.FC<ExternalBookingModalProps> = ({
  className,
}: ExternalBookingModalProps) => {
  const overlay = useOverlay<ManagerExternalBookingOverlay>(
    'manager-external-booking'
  );

  const bdr = overlay?.blockedDateRange;

  const startDate = bdr && formatJournalEntryDate(bdr?.startDate);
  const endDate = bdr && formatJournalEntryDate(bdr?.endDate);

  return (
    <AppModal
      type="manager-external-booking"
      className={`${styles['external-booking-modal']} ${className}`}
      padded={false}
      content={
        <>
          <h4 className={styles['external-booking-modal__title']}>
            Linked Calendar Event
          </h4>
          <p className={styles['external-booking-modal__message']}>
            This event is generated from a linked calendar and can only be
            edited via that calendar’s platform.
          </p>
          <div className={styles['external-booking-modal__booking-info']}>
            <span className={styles['external-booking-modal__dates']}>
              {startDate} - {endDate}
            </span>
            <span className={styles['external-booking-modal__calendar-name']}>
              {bdr?.summary?.replace('From calendar: ', '')}
            </span>
            <AppMarkdown content={bdr?.description || ''} />
          </div>
          <p className={styles['external-booking-modal__help']}>
            We&apos;re here to help.{' '}
            <a
              href="https://support.thedyrt.com/hc/en-us"
              className={styles['external-booking-modal__help-link']}
            >
              Visit our FAQs
            </a>{' '}
            or{' '}
            <a
              className={styles['external-booking-modal__help-link']}
              href="mailto:support@thedyrt.com"
            >
              Contact Us
            </a>
            .
          </p>
        </>
      }
    />
  );
};

export default ExternalBookingModal;
