// White Rock, KS
export const DEFAULT_MAP_CENTER: UserLocation = {
  lat: 39.8333333,
  long: -98.585522,
  country: 'US',
  city: 'White Rock',
  region: 'ks',
  userLocationFound: false,
} as UserLocation;

export const MAP_LAYERS = [
  {
    id: 'BLM' as const,
    label: 'BLM',
    body: 'Free dispersed camping on most Bureau of Land Management land.',
    link: 'https://www.blm.gov/programs/recreation/camping',
    restrictions: 'Free dispersed camping allowed on most BLM lands.',
  },
  {
    id: 'USFS' as const,
    label: 'USFS',
    body: 'U.S. Forest Service free dispersed camping available in some areas.',
    link: 'https://www.fs.usda.gov/',
    restrictions: 'Free dispersed camping available.',
  },
  {
    id: 'NPS' as const,
    label: 'NPS',
    body: 'National Park Service backcountry camping requires a permit.',
    link: 'https://www.nps.gov/subjects/camping/backcountry-camping.htm',
    restrictions: 'Backcountry camping requires permit.',
  },
];

export const CELL_SERVICE = [
  {
    id: 'att' as const,
    label: 'AT&T',
  },
  {
    id: 'tmobile' as const,
    label: 'T-Mobile',
  },
  {
    id: 'verizon' as const,
    label: 'Verizon',
  },
];

export const MAP_LAYER_IDS = [...MAP_LAYERS, ...CELL_SERVICE].map(
  (layer) => layer.id
);

export const DEFAULT_DOT_COLOR = '#397c70';
export const FREE_CAMPING_DOT_COLOR = '#93462A';
