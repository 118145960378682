import React, { useCallback, useState } from 'react';
import classNames from 'classnames/bind';

import { useCampgroundsSavedToLists } from 'contexts/campgroundsSavedToLists';

import useDeleteRecord from 'hooks/useDeleteRecord';

import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/AppModal.module.scss';

const cx = classNames.bind(styles);

export interface Props {
  list: ListModel;
  campgroundId: string;
  fetchUserLists: () => Promise<void>;
  createListItem: (list: ListModel) => Promise<ListItemModel>;
}

const _SaveList: React.FC<Props> = ({
  list,
  campgroundId,
  fetchUserLists,
  createListItem,
}) => {
  const campgroundsLabel =
    list.items.length === 1 ? 'Campground' : 'Campgrounds';

  const onList =
    list.items.filter((item) => item.campgroundId == campgroundId).length > 0;

  const classesSavedIcon = cx({
    list__icon: true,
    'list__icon--saved': onList,
  });

  const { deleteRecord } = useDeleteRecord();

  const [loading, setLoading] = useState(false);

  const { syncCampgroundsSavedToLists } = useCampgroundsSavedToLists();

  const toggleItem = useCallback(async () => {
    if (!loading) {
      setLoading(true);
      const itemsToDelete = list.items.filter(
        (item) => item.campgroundId == campgroundId
      );
      if (itemsToDelete?.length) {
        await Promise.all(
          itemsToDelete.map(async (item) => await deleteRecord(item))
        );
        await fetchUserLists();
        await syncCampgroundsSavedToLists();
      } else {
        await createListItem(list);
      }

      setLoading(false);
    }
  }, [
    campgroundId,
    createListItem,
    deleteRecord,
    fetchUserLists,
    list,
    loading,
    syncCampgroundsSavedToLists,
  ]);

  return (
    <li className={styles['list']}>
      <button
        className={styles['list__button']}
        onClick={toggleItem}
        data-event="click_toggle_item_saved"
        type="button"
        disabled={loading}
      >
        <div className={styles['list__title-section']}>
          <h2 className={styles['list__title']}>{list.title}</h2>
          <span className={styles['list__item-count']}>
            {list.items?.length}
            <span className={styles['list__item-count__label']}>
              {campgroundsLabel}
            </span>
          </span>
        </div>
        {loading ? (
          <AppIcon
            classNameWrapper={styles['list__icon--loading']}
            icon="spinner"
          />
        ) : (
          <AppIcon
            classNameSvg={classesSavedIcon.split(' ')}
            icon={onList ? 'heart' : 'heart_outlined'}
          />
        )}
      </button>
    </li>
  );
};

export default _SaveList;
