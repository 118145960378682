import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import * as amplitude from '@amplitude/analytics-browser';
import isEmpty from 'lodash.isempty';

import { FILTER_SELECT } from 'constants/amplitude';

import AppInputCheckbox from 'components/AppInput/AppInputCheckbox';

interface Props<L extends string, V extends string> {
  options: InputOption<L, V>[];
  valueAccessor: V;
  labelAccessor: L;
  labelLarge?: boolean;
  name: string;
  className?: string;
  labelClassName?: string;
  disabled?: boolean;
  dataEventCategory?: string;
  dataEventAction?: string;
  dataEventLabel?: boolean;
  bulletedDescription?: boolean;
  descriptionClassName?: string;
  greyedOutBox?: boolean;
}

function AppFormMultiSelect<L extends string, V extends string>({
  options,
  valueAccessor,
  labelAccessor,
  labelLarge,
  name,
  labelClassName,
  descriptionClassName,
  disabled,
  bulletedDescription,
  greyedOutBox,
}: Props<L, V>) {
  const { setValue, watch } = useFormContext();

  const value: string | null = watch(name) || null;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const values: string[] = value ? value.split(',') : [];

    const clickedOption = e.target.name;
    const clickedOptionValue = e.target.checked;

    amplitude.track(FILTER_SELECT, {
      'filter name': clickedOption,
      'filter value': clickedOptionValue,
    });

    const update = (
      values.find((value) => value === clickedOption)
        ? values.filter((value) => value !== e.target.name)
        : [...values, clickedOption]
    ).join(',');

    setValue(name, isEmpty(update) ? null : update, {
      shouldDirty: true,
    });
  };

  const getIconName = useCallback(
    (option: InputOption<L, V>, name: string) => {
      if (name === 'features') {
        if (option[valueAccessor]?.includes('allowed')) {
          return option[valueAccessor];
        } else if (option[valueAccessor] === 'picnic_table') {
          return 'picnic_tables_available';
        } else if (option[valueAccessor] === 'ada_access') {
          return 'ada_accessible_available';
        }
        return `${option[valueAccessor]}_available`;
      } else {
        return option.icon;
      }
    },
    [valueAccessor]
  );

  return (
    <>
      {options.map((option: InputOption<L, V>) => {
        const isChecked = value
          ? !!value.split(',').find((value) => value === option[valueAccessor])
          : false;

        const iconName = getIconName(option, name);

        const dataAction =
          name === option[valueAccessor]
            ? name
            : `${name}_${option[valueAccessor]}`;

        return (
          <AppInputCheckbox
            key={option[valueAccessor]}
            label={option[labelAccessor]}
            labelLarge={labelLarge}
            name={option[valueAccessor]}
            onChange={onChange}
            isChecked={isChecked}
            iconCategory={name}
            icon={iconName}
            disabled={disabled}
            className={'m-t-md'}
            labelClassName={labelClassName}
            description={bulletedDescription ? null : option.description}
            descriptionClassName={descriptionClassName}
            dataEventCategory="Web Filters"
            dataEventAction={`change_checkbox_${dataAction}`}
            dataEventLabel={isChecked}
            greyedOutBox={greyedOutBox}
            labelFirst
          />
        );
      })}
    </>
  );
}

export default AppFormMultiSelect;
