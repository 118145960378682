import React, { useContext, useMemo, useCallback, useReducer } from 'react';

import managerPortalStateFromActiveClaim from 'utils/managerPortalStateFromActiveClaim';

import useFetchClaims from 'hooks/useFetchClaims';

import { managerReducer } from './managerReducer';

const defaultManager = {
  campgrounds: [],
  claims: [],
  rates: [],
  accommodations: [],
  loading: true,
};

const ManagerContext = React.createContext<ManagerContextValue>({
  manager: { ...defaultManager },
  setManager: (managerData: ManagerData) => {
    managerData;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateClaims: (campgroundSlug) => new Promise(() => undefined),
  setActiveCampgroundOrClaim: () => {},
});

ManagerContext.displayName = 'ManagerContext';

export const useManager = (): ManagerContextValue => useContext(ManagerContext);

export const ManagerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [manager, dispatch] = useReducer(managerReducer, { ...defaultManager });

  const fetchClaims = useFetchClaims();

  const updateClaims = useCallback(
    async (campgroundSlug?: string) => {
      dispatch({
        type: 'SET_MANAGER_ATTRIBUTE',
        payload: { loading: true },
      });
      const { campgrounds, claims, activeCampground, activeClaim } =
        (await fetchClaims(campgroundSlug)) || {};

      dispatch({
        type: 'SET_MANAGER_ATTRIBUTE',
        payload: {
          campgrounds,
          claims,
          managerPortalState: managerPortalStateFromActiveClaim(activeClaim),
          activeCampground: activeCampground,
          activeClaim: activeClaim,
          loading: false,
        },
      });
      return { activeCampground, activeClaim, campgrounds: campgrounds || [] };
    },
    [fetchClaims]
  );

  const managerContextValue = useMemo(
    () => ({
      manager,
      setManager: (managerData: Partial<ManagerData>) =>
        dispatch({ type: 'SET_MANAGER_ATTRIBUTE', payload: managerData }),
      updateClaims,
      setActiveCampgroundOrClaim: ({
        activeCampground,
        activeClaim,
      }: ActiveClaimOrCampgroundPayload) =>
        dispatch({
          type: 'SET_ACTIVE_CLAIM_OR_CAMPGROUND',
          payload: { activeCampground, activeClaim },
        }),
    }),
    [manager, updateClaims]
  );

  return (
    <ManagerContext.Provider value={managerContextValue}>
      {children}
    </ManagerContext.Provider>
  );
};
