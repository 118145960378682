import React from 'react';

import { useManager } from 'contexts/manager';

import useMountEffect from 'hooks/useMountEffect';
import { useOverlayContext } from 'hooks/useOverlayContext';
import useUpdateRecord from 'hooks/useUpdateRecord';

import AppButton from 'components/AppButton/AppButton';
import AppIcon from 'components/AppIcon/AppIcon';
import AppModal from 'components/AppModal/AppModal';

import styles from './styles/AppModal.module.scss';

const ModalContent: React.FC = () => {
  const { close } = useOverlayContext();
  const { setActiveCampgroundOrClaim, manager } = useManager();
  const { updateRecord, loading } = useUpdateRecord();

  useMountEffect(() => {
    const triggerContact = async () => {
      await updateRecord(
        {
          id: manager?.activeClaim?.id,
          type: 'campground-claims',
          bookingIntention: 'claim_and_bookings',
        },
        undefined,
        (claim) => {
          setActiveCampgroundOrClaim({ activeClaim: claim });
        }
      );
    };
    triggerContact();
  });

  return (
    <>
      {loading && <AppIcon icon="spinner" />}
      {manager?.activeClaim?.bookingIntention === 'claim_and_bookings' && (
        <p>
          Our support team will reach out shortly via your email or phone number
          on file.
        </p>
      )}
      <footer className={styles['modal-footer']}>
        <AppButton
          type={'button'}
          dataEvent="click_onboarding_bookings_requested"
          label="OK"
          name="close"
          onClick={close}
        />
      </footer>
    </>
  );
};

const ContactBookingModal: React.FC = () => {
  return (
    <AppModal
      type="contact-booking"
      title="Standby for 1:1 Bookings Setup"
      content={<ModalContent />}
    />
  );
};
export default ContactBookingModal;
