import React from "react";
import { useRouter } from 'next/router';

import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import AppButtonLink from 'components/AppButton/AppButtonLink';
import AppModal from 'components/AppModal/AppModal';

import styles from "./styles/SetupBookingsStandbyModal.module.scss";

export interface SetupBookingsStandbyModalProps {
  className?: string 
}

export const SetupBookingsStandbyModal: React.FC<SetupBookingsStandbyModalProps> = ({ className }: SetupBookingsStandbyModalProps) => {
  const router = useRouter();

  const {
    query: { campground },
  } = router;

  return (
    <AppModal
      type="setup-bookings-standby"
      className={`${styles['setup-bookings-standby']} ${className}`}
      disableClose
      padded={false}
      content={
        <>
          <div className={styles['setup-bookings-standby__text']}>
            <h2 className={styles['setup-bookings-standby__title']}>
              Standby for 1:1 Bookings Support
            </h2>
            <img
              className={styles['setup-bookings-standby__image']}
              srcSet={`${PUBLIC_ASSETS_PATH}/assets/images/claim/Notebook_Illustration_1x.png, ${PUBLIC_ASSETS_PATH}/assets/images/claim/Notebook_Illustration_2x.png 2x`}
              src={`${PUBLIC_ASSETS_PATH}/assets/images/claim/Notebook_Illustration_1x.png`}
              alt="A notebook with a checklist of campgrounds and a pencil."
            />
            <p>
              Thanks for the info! We will reach out within 2 business days with
              integration sync and setup information.
            </p>
          </div>

          <div className={styles['setup-bookings-standby__footer']}>
            <AppButtonLink
              className={styles['setup-bookings-success__link']}
              path={`/manager/campgrounds/${campground}`}
              label="Got It"
              icon="arrow_right"
              dataEvent="Manager_Setup-Bookings_Success"
            />
          </div>
        </>
      }
    />
  );
};

export default SetupBookingsStandbyModal;