import React, { useContext } from 'react';

const WPContext = React.createContext(false);

export const useWPContext = () => useContext(WPContext);

const WPProvider: React.FC<{ value: boolean; children: React.ReactNode }> = ({
  value = false,
  children,
}) => {
  return <WPContext.Provider value={value}>{children}</WPContext.Provider>;
};

export default WPProvider;
