import { useState, useCallback, useMemo } from 'react';

import { loadRecords as loadRecordsUtil } from 'utils/JSONAPIAdapter';

import useAuthenticationHeader from './useAuthenticationHeaders';
import useHandleApiError from './useHandleApiError';

interface State {
  errors: string[] | null;
  loading: boolean;
  called: boolean;
}

const useLoadRecords = (initialLoadingState = false) => {
  const [state, setState] = useState<State>({
    loading: initialLoadingState,
    errors: null,
    called: false,
  });

  const authenticationHeader = useAuthenticationHeader();

  const handleApiError = useHandleApiError();

  const loadRecords = useCallback(
    async function <T extends DyrtModel, I extends DyrtModel = DyrtModel>(
      typeOrEndpoint: string,
      options: JSONAPIOptions = {},
      headers: Record<string, string> = {} as Record<string, string>,
      onSuccess?: (
        response: DeserializedJSONAPIResults<T, I>
      ) => Promise<unknown> | unknown,
      signal?: AbortSignal,
      apiBase?: string,
      withRelated?: boolean
    ) {
      setState({
        loading: true,
        errors: null,
        called: true,
      });

      try {
        const response = await loadRecordsUtil<T, I>(
          typeOrEndpoint,
          options,
          {
            ...headers,
            ...authenticationHeader,
          },
          signal,
          apiBase,
          withRelated
        );

        setState({ errors: null, loading: false, called: true });

        if (onSuccess) {
          onSuccess(response);
        } else {
          return response;
        }
      } catch (err) {
        setState({
          loading: false,
          errors: handleApiError(err),
          called: true,
        });
      }
    },
    [setState, authenticationHeader, handleApiError]
  );

  return useMemo(() => {
    return {
      loadRecords,
      ...state,
    };
  }, [loadRecords, state]);
};

export default useLoadRecords;
