import React from 'react';

import useAppFormInput from 'hooks/useFormInput';

import AppInputPassword, {
  AppInputPasswordProps,
} from 'components/AppInput/AppInputPassword';

type AppFormInputPasswordProps<T> = FormInputProps<T> &
  Omit<AppInputPasswordProps, 'onChange'>;

function AppFormInputPassword<T = Record<string, unknown>>(
  props: AppFormInputPasswordProps<T>
): React.ReactElement {
  return useAppFormInput(AppInputPassword, props);
}

export default AppFormInputPassword;
