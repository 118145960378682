import React, { RefObject } from 'react';
import classNames from 'classnames/bind';

import {
  DEFAULT_DUMP_IMAGE,
  DEFAULT_IMAGE,
  DEFAULT_PARKING_IMAGE,
  DEFAULT_WATER_IMAGE,
} from 'constants/fastlyImage';

import {
  getFastlySrc,
  getFastlySrcset,
  getFastlySrcsetForSource,
} from 'utils/fastlyImage';

import styles from './styles/AppPhoto.module.scss';

const cx = classNames.bind(styles);

export interface AppPhotoProps {
  src: string | null | 'placeholder';
  alt: string;
  className?: string;
  sizes?: string;
  options?: FastlyImageOptions;
  onLoad?(): void;
  imageRef?: RefObject<HTMLImageElement>;
  width?: string;
  height?: string;
  lazyLoad?: boolean;
  locationType?: string | null;
  sourceConfigs?: PictureSourceConfigForPhoto[];
}

export const AppPhoto: React.FC<AppPhotoProps> = ({
  src: url,
  alt,
  className = '',
  sizes,
  options,
  onLoad,
  imageRef,
  width,
  height,
  sourceConfigs,
  lazyLoad = true,
  locationType,
}: AppPhotoProps) => {
  const src = getFastlySrc(url, options || {}, locationType);
  const srcSet = getFastlySrcset(url, options || {}, sizes, locationType);
  const imgClasses = cx({
    app_photo: true,
    'app_photo--is-default':
      src === DEFAULT_IMAGE ||
      src === DEFAULT_DUMP_IMAGE ||
      src === DEFAULT_WATER_IMAGE ||
      src === DEFAULT_PARKING_IMAGE,
  });

  const isPlaceholder = typeof url !== 'string' || url === 'placeholder';

  const imgTag = (
    <img
      ref={imageRef}
      src={src}
      srcSet={srcSet}
      sizes={sizes}
      alt={alt}
      aria-label={alt}
      className={`${imgClasses} ${className}`}
      loading={lazyLoad ? 'lazy' : 'eager'}
      onLoad={() => {
        onLoad && onLoad();
      }}
      width={width ? `${width}px` : ''}
      height={height ? `${height}px` : ''}
    />
  );

  if (!sourceConfigs?.length || isPlaceholder) return imgTag;

  return (
    <picture>
      {sourceConfigs.map(
        ({ media, width, height, sizes, fastlyOptions }, index) => (
          <source
            key={index}
            media={media}
            srcSet={getFastlySrcsetForSource(url, width, height, fastlyOptions)}
            width={width}
            height={height}
            sizes={sizes}
          />
        )
      )}
      {imgTag}
    </picture>
  );
};

export default AppPhoto;
