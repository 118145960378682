import React from 'react';

import AppModal from 'components/AppModal/AppModal';

import { connectionDetailsTypes } from './assets/connection-details';
import Item from './_Item';

import styles from './styles/CampgroundConnectionDetailsModal.module.scss';

export interface CampgroundConnectionDetailsModalProps {
  className?: string;
}

export const CampgroundConnectionDetailsModal: React.FC<
  CampgroundConnectionDetailsModalProps
> = () => {
  return (
    <AppModal
      type="stay-connected"
      title="Connection Details"
      content={
        <div>
          <p>
            We rate connection speeds from campers like you who submit speed
            tests with Field Reports.
          </p>
          <ul className={styles['connection-details__list']}>
            {connectionDetailsTypes.map((type) => (
              <li
                key={type.label}
                className={styles['connection-details__list-item']}
              >
                <Item type={type} />
              </li>
            ))}
          </ul>
        </div>
      }
    />
  );
};

export default CampgroundConnectionDetailsModal;
