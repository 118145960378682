import isEmpty from 'lodash.isempty';

type OverlayState = { overlays: Array<OverlayTypes> };

type OverlayAction =
  | ShowOverlayAction
  | {
      type: 'DISMISS_OVERLAY';
    }
  | {
      type: 'INITIATE_DISMISS_OVERLAY';
    }
  | { type: 'CLEAR_OVERLAYS' };

type ShowOverlayAction = {
  type: 'SHOW_OVERLAY' | 'INITIATE_SHOW_OVERLAY';
  payload: OverlayTypes;
};

export const overlayReducer = (state: OverlayState, action: OverlayAction) => {
  const newOverlays = [...state.overlays];

  switch (action.type) {
    case 'INITIATE_SHOW_OVERLAY':
      if (state.overlays) {
        return {
          ...state,
          overlays: [...state.overlays, { ...action.payload, isActive: false }],
        };
      } else {
        return {
          ...state,
          overlays: [{ ...action.payload, isActive: false }],
        };
      }

    case 'SHOW_OVERLAY':
      const newOverlay = {
        ...newOverlays.pop(),
        isActive: true,
      } as OverlayTypes;

      return {
        ...state,
        overlays: [...newOverlays, newOverlay],
      };

    case 'INITIATE_DISMISS_OVERLAY':
      const poppedOverlay = {
        ...newOverlays.pop(),
        isActive: false,
      } as OverlayTypes;

      return { ...state, overlays: [...newOverlays, poppedOverlay] };
    case 'DISMISS_OVERLAY':
      if (state.overlays && !isEmpty(state.overlays)) {
        const newOverlays = state.overlays.slice(0, -1);
        return { ...state, overlays: newOverlays };
      } else {
        return { overlays: [] };
      }
    case 'CLEAR_OVERLAYS': {
      return { ...state, overlays: [] };
    }
    default:
      return { ...state };
  }
};
