import React from 'react';

import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import AppButtonLink from 'components/AppButton/AppButtonLink';

import AppModal from './AppModal';

import styles from './styles/AppModal.module.scss';

const CampgroundGatingModal: React.FC = () => {
  return (
    <AppModal
      type="add"
      wrapperClassName={styles['campground-gating-modal-wrapper']}
      className={styles['campground-gating-modal']}
      content={
        <div className={styles['campground-gating-modal__content-container']}>
          <div className={styles['campground-gating-modal__content']}>
            <section
              className={
                styles['campground-gating-modal__content-camper-container']
              }
            >
              <img
                className={styles['campground-gating-modal__content-image']}
                src={`${PUBLIC_ASSETS_PATH}/assets/images/camper_illu.png`}
                alt={`Are you a camper image`}
              />
              <div className={styles['campground-gating-modal__content-text']}>
                <p
                  className={
                    styles['campground-gating-modal__content-description']
                  }
                >
                  Are you a camper?
                </p>
                <p
                  className={
                    styles['campground-gating-modal__content-sub-description']
                  }
                >
                  Share a location you&apos;ve stayed at.
                </p>
                <AppButtonLink
                  className={styles['about-values-section__link']}
                  label="Add a Location"
                  path={`/add`}
                  icon="arrow_right"
                  dataEvent={`click_learn_more_lnt`}
                />
              </div>
            </section>
            <div
              className={styles['campground-gating-modal__content-divider']}
            />
            <section>
              <img
                className={styles['campground-gating-modal__content-image']}
                src={`${PUBLIC_ASSETS_PATH}/assets/images/property_illu.png`}
                alt={`Are you an owner image`}
              />
              <div className={styles['campground-gating-modal__content-text']}>
                <p
                  className={
                    styles['campground-gating-modal__content-description']
                  }
                >
                  Are you a property manager?
                </p>
                <p
                  className={
                    styles['campground-gating-modal__content-sub-description']
                  }
                >
                  Add your property to help your business.
                </p>
                <AppButtonLink
                  className={styles['about-values-section__link']}
                  label="Claim a Property"
                  ghost
                  path="/claim"
                  icon="arrow_right"
                  dataEvent={`click_learn_more_lnt`}
                />
              </div>
            </section>
          </div>
        </div>
      }
    />
  );
};
export default CampgroundGatingModal;
