import React from 'react';
import { useRouter } from 'next/router';
import * as amplitude from '@amplitude/analytics-browser';

import { PRO_CTA_CLICK, PAGE_NAMES } from 'constants/amplitude';
import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import AppButtonLink from 'components/AppButton/AppButtonLink';
import AppModal from 'components/AppModal/AppModal';
import { PRO_FEATURES } from 'components/ProFeatures/ProFeaturesA/assets/proFeatures';

import _ProModalFeatureListItem from './_ProModalFeatureListItem';

import styles from './styles/_ProUpgradeModalC.module.scss';

const ACTION_ID = 'pro-upgrade-modal-cta-click-modal-C';

const _ProUpgradeModalB: React.FC = () => {
  const router = useRouter();

  const sendProCTAClickEvent = () => {
    amplitude.track(PRO_CTA_CLICK, {
      'button name': 'Free 7-Day Trial',
      'page name': PAGE_NAMES[router.pathname as PageKeys],
    });
  };

  return (
    <AppModal
      type="pro-upgrade-modal-c"
      wrapperClassName={styles['pro-upgrade-modal-c-wrapper']}
      className={styles['pro-upgrade-modal-c']}
      content={
        <div className={styles['pro-upgrade-modal-c__content']}>
          <img
            className={styles['pro-upgrade-modal-c__logo']}
            src={`${PUBLIC_ASSETS_PATH}/assets/brand/Mark_Pro_Gradient.svg`}
            alt="pro upgrade modal brand logo"
          />
          <p className={styles['pro-upgrade-modal-c__title']}>
            Camp like a PRO
          </p>
          <p className={styles['pro-upgrade-modal-c__desc']}>
            Unlock both premium tools and discounts
          </p>
          <AppButtonLink
            className={styles['pro-upgrade-modal-c__cta']}
            label="Free 7-Day Trial"
            path={`/pro/checkout`}
            icon="right_arrow"
            dataEvent={ACTION_ID}
            onClick={sendProCTAClickEvent}
          />
          <div className={styles['pro-upgrade-modal-c__feature-wrapper']}>
            <div className={styles['pro-upgrade-modal-c__feature']}>
              <img
                className={styles['pro-upgrade-modal-c__pro-map-image']}
                src={`${PUBLIC_ASSETS_PATH}/assets/pro/pro-maps.png`}
                alt="pro upgrade modal pro map feature"
              />
              <div className={styles['pro-upgrade-modal-c__feature-content']}>
                <p className={styles['pro-upgrade-modal-c__feature-title']}>
                  Camp better with PRO Maps
                </p>
                <div className={styles['pro-upgrade-modal-c__feature-list']}>
                  {PRO_FEATURES.maps.services.map((item) => (
                    <_ProModalFeatureListItem
                      key={item.title}
                      className={
                        styles['pro-upgrade-modal-c__feature-listItem']
                      }
                      title={item.title}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className={styles['pro-upgrade-modal-c__feature']}>
              <img
                className={styles['pro-upgrade-modal-c__pro-discount-image']}
                src={`${PUBLIC_ASSETS_PATH}/assets/pro/pro-discounts.png`}
                alt="pro upgrade modal pro discount feature"
              />
              <div className={styles['pro-upgrade-modal-c__feature-content']}>
                <p className={styles['pro-upgrade-modal-c__feature-title']}>
                  Save more with PRO Campground Discounts
                </p>
                <div className={styles['pro-upgrade-modal-c__feature-list']}>
                  {PRO_FEATURES.discounts.services.map((item) => (
                    <_ProModalFeatureListItem
                      key={item.title}
                      className={
                        styles['pro-upgrade-modal-c__feature-listItem']
                      }
                      title={item.title}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};
export default _ProUpgradeModalB;
