import React from 'react';
import classNames from 'classnames/bind';

import { ConnectionDetailsType } from './assets/connection-details';

import styles from './styles/CampgroundConnectionDetailsModal.module.scss';

const cx = classNames.bind(styles);

interface ItemProps {
  type: ConnectionDetailsType;
}

const Item: React.FC<ItemProps> = ({ type }: ItemProps) => {
  const dotClassNames = cx({
    item__dot: true,
    'item__dot--can-display':
      type.label !== 'Unknown' && type.label !== 'Available',
    'item__dot--green': type.label === 'Good',
    'item__dot--yellow': type.label === 'Fair',
    'item__dot--red': type.label === 'Poor',
  });

  const rangeClassNames = cx({
    item__range: true,
    'item__range--can-display': type.range,
  });

  return (
    <div className={styles['item']}>
      <div className={styles['item__label-wrapper']}>
        <span className={`${styles['item__dot']} ${dotClassNames}`}></span>
        <span className={styles['item__label']}>{type.label}</span>
        <span className={`${styles['item__range']} ${rangeClassNames}`}>
          - {type.range}
        </span>
      </div>
      <p className={styles['item__description']}>{type.description}</p>
    </div>
  );
};

export default Item;
