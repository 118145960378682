import React from 'react';
import { useWatch } from 'react-hook-form';

import useAppFormInput from 'hooks/useFormInput';

import AppBoxedRadio, {
  AppBoxedRadioProps,
} from 'components/AppInput/AppBoxedRadio';

type AppFormBoxedRadioProps<T> = FormInputProps<T> &
  Omit<AppBoxedRadioProps, 'onChange' | 'isChecked'>;

function AppFormBoxedRadio<T = Record<string, unknown>>(
  props: AppFormBoxedRadioProps<T>
): React.ReactElement {
  let value = useWatch({ name: props.name });

  if (typeof value === 'boolean') value = value.toString();

  return useAppFormInput<AppBoxedRadioProps, { isChecked: boolean }>(
    AppBoxedRadio,
    props,
    () => ({
      isChecked: value === props.value,
    })
  );
}

export default AppFormBoxedRadio;
