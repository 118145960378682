import parseISO from 'date-fns/parseISO';

import { formatISOApiString } from 'utils/dates';

export const useExternalToPicker = () => {
  return (dateRange: Partial<BlockedDateRangeModel>): BlockedDateRangeForm => {
    const { startDate, endDate, ...converted } = dateRange;

    if (startDate) {
      (converted as BlockedDateRangeForm).startDate = parseISO(startDate);
    }

    if (endDate) {
      (converted as BlockedDateRangeForm).endDate = parseISO(endDate);
    }

    return converted;
  };
};

export const usePickerToExternal = () => {
  return (update: BlockedDateRangeForm): BlockedDateRangeModel => {
    if (update.endDate) {
      update.endDate = formatISOApiString(update.endDate);
    }

    if (update.startDate) {
      update.startDate = formatISOApiString(update.startDate);
    }

    return update as BlockedDateRangeModel;
  };
};
