import React from 'react';

import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import { useOverlayContext } from 'hooks/useOverlayContext';

import AppButton from 'components/AppButton/AppButton';
import AppModal from 'components/AppModal/AppModal';

import formStyles from '../styles/AppFormCollection.module.scss';

import styles from './styles/ManagerSiteForm.module.scss';

const SitesAboutContent: React.FC = () => (
  <>
    <section className={formStyles.section}>
      <div
        className={`${formStyles['section__row']} ${styles['modal-content-block']}`}
      >
        <div
          className={`${formStyles['section__column']} ${formStyles['section__column--is-1-4']}`}
        >
          <img
            className={styles['modal-content-block-icon']}
            src={`${PUBLIC_ASSETS_PATH}/assets/images/site_batch.svg`}
            alt="Three tents, each in its own small circle"
          />
        </div>
        <div
          className={`${formStyles['section__column']} ${formStyles['section__column--is-3-4']}`}
        >
          <h3 className={styles['modal-content-block-title']}>
            Specific Sites (Batch)
          </h3>
          <p>Identical amenities and specific locations.</p>
          <p className={styles['modal-content-block-highlight']}>
            Makes it easy for you to create similar, location-specific inventory
            in one action.
          </p>
          <p>
            Great for small properties, like private land or campgrounds with
            less than 20 sites.
          </p>
        </div>
      </div>
    </section>
    <div
      className={`${formStyles['section__row']} ${styles['modal-content-block']}`}
    >
      <div
        className={`${formStyles['section__column']} ${formStyles['section__column--is-1-4']}  m-t-2xl`}
      >
        <img
          className={styles['modal-content-block-icon']}
          src={`${PUBLIC_ASSETS_PATH}/assets/images/site_block.svg`}
          alt="Three tents in one large circle"
        />
      </div>
      <div
        className={`${formStyles['section__column']} ${formStyles['section__column--is-3-4']}  m-t-2xl`}
      >
        <h3 className={styles['modal-content-block-title']}>
          General Site-Types (Block)
        </h3>
        <p>Similar amenities. No specific locations.</p>
        <p className={styles['modal-content-block-highlight']}>
          Makes it easy to create large number of similar sites that you will
          later assign to campers.
        </p>
        <p>
          Great for large properties, like campgrounds with more than 20 sites.
        </p>
      </div>
    </div>
  </>
);

const SitesAboutFooter: React.FC = () => {
  const { close } = useOverlayContext();
  return (
    <AppButton
      type={'button'}
      dataEvent="site_wizard_info_close"
      label="Got it"
      name="gotit"
      onClick={close}
    />
  );
};

const SitesAboutModal: React.FC = () => {
  return (
    <AppModal
      type="sites-about"
      title="Multiple Sites"
      titleClassName={styles['header-center']}
      content={<SitesAboutContent />}
      footerButtons={<SitesAboutFooter />}
    />
  );
};
export default SitesAboutModal;
