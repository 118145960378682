import React, { useContext, useState, useMemo, useRef } from 'react';

export const DatesPopoverContext = React.createContext<DatesPopoverValue>({
  dates: [null, null],
  setDates: () => {},
  close: { current: null },
  revert: () => {},
});

export const useDatesPopoverContext = () => useContext(DatesPopoverContext);

export const DatesPopoverContextProvider: React.FC<{
  startDate: Date | null;
  endDate: Date | null;
  onDateSelected?: (dates: [Date | null, Date | null]) => void;
  children: React.ReactNode;
}> = ({ children, startDate, endDate, onDateSelected }) => {
  const [dates, setDates] = useState<[Date | null, Date | null]>([
    startDate,
    endDate,
  ]);

  const close = useRef<null | (() => void)>(null);

  const value = useMemo(() => {
    return {
      dates,
      setDates: (dates: [Date | null, Date | null]) => {
        setDates(dates);
        if (onDateSelected) {
          onDateSelected(dates);
        }
      },
      close,
      revert: () => {
        setDates([startDate, endDate]);
      },
    };
  }, [dates, setDates, onDateSelected, startDate, endDate]);

  return (
    <DatesPopoverContext.Provider value={value}>
      {children}
    </DatesPopoverContext.Provider>
  );
};
