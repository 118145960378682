import React, { useContext, useEffect, useState } from 'react';
import Router from 'next/router';
import debounce from 'lodash.debounce';

export const NavDrawerContext = React.createContext<NavDrawerContextValue>({
  isNavOpen: false,
  setIsNavOpen: () => {},
});

export const useNavDrawerContext = () => useContext(NavDrawerContext);

export const NavDrawerContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    const handleRouteChange = () => {
      setIsNavOpen(false);
      document.body.classList.remove('has-overlay');
    };
    Router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      Router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 900) {
        setIsNavOpen(false);
        document.body.classList.remove('has-overlay');
      }
    };
    const debouncedHandleResize = debounce(handleResize, 500);

    window.addEventListener('resize', debouncedHandleResize, { passive: true });

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [setIsNavOpen]);

  return (
    <NavDrawerContext.Provider value={{ isNavOpen, setIsNavOpen }}>
      {children}
    </NavDrawerContext.Provider>
  );
};
