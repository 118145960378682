import React, { useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
import Router from 'next/router';

import { AB_NEARBY_TEST } from 'constants/abTesting';

import { useUser } from 'contexts/currentUser';
import { useFastlyRequestHeadersContext } from 'contexts/fastlyRequestHeaders';

import { isServer } from 'utils/isServer';
import {
  checkExistingCoupon,
  stashCoupon,
  checkUTMParams,
} from 'utils/localStorage';
import { localStorageIsAllowed } from 'utils/localStorage';

import useAttachAmplitudeClickEvents from 'hooks/useAttachAmplitudeClickEvents';
import useCleanRoute from 'hooks/useCleanRoute';
import useLogAmplitudePageViewWithVitals from 'hooks/useLogAmplitudePageViewWithVitals';
import useMountEffect from 'hooks/useMountEffect';

// paths that disallow the Forethought widget
const NO_WIDGET_PATHS = [
  '/pro',
  '/search',
  '/manager',
  '/special-offer',
  '/checkout',
];

function updateLocalStorage() {
  if (isServer || !localStorageIsAllowed()) return;
  const params = new URLSearchParams(window.location.search);
  checkUTMParams(params);
  checkExistingCoupon();
  const newCoupon = params.get('coupon');
  if (newCoupon) {
    stashCoupon(newCoupon);
  }
}

function updateForethoughtVisibility(path: string) {
  const body = document.body;
  if (
    path.length > 1 &&
    NO_WIDGET_PATHS.some((somePath) => path.startsWith(somePath))
  ) {
    body.classList.add('forethought-chat--hidden');
  } else {
    body.classList.remove('forethought-chat--hidden');
  }
}

const Analytics: React.FC = () => {
  const { user } = useUser();

  const cleanRoute = useCleanRoute();

  const { fastlyABTestPageProps } = useFastlyRequestHeadersContext();

  const splitTestVariant = useRef<TestVariant | null>(
    fastlyABTestPageProps[AB_NEARBY_TEST]
  );

  useEffect(() => {
    splitTestVariant.current = fastlyABTestPageProps[AB_NEARBY_TEST];
  }, [fastlyABTestPageProps, splitTestVariant]);

  useEffect(() => {
    if (isServer) return;

    const handleRouteChange = (url: string) => {
      window.dataLayer = window.dataLayer || [];
      if (splitTestVariant.current) {
        window.dataLayer.push({
          splitTestVariant: splitTestVariant.current.variant,
        });
      }

      window.dataLayer.push({
        event: 'pageview',
        page: url,
      });
    };

    // initial pageview
    handleRouteChange(window.location.href);

    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  useMountEffect(() => {
    TagManager.initialize({ gtmId: String(process.env.NEXT_PUBLIC_GTM_ID) });

    updateLocalStorage();
  });

  useAttachAmplitudeClickEvents('body');

  useEffect(() => {
    // Push to Sentry
    if (user) {
      const { id, pro, admin } = user;

      // push to GTM
      window.dataLayer.push({
        event: 'identify_user',
        user: { id, pro, admin },
      });
    }
  }, [user]);

  useEffect(() => {
    updateForethoughtVisibility(cleanRoute);
  }, [cleanRoute]);

  useLogAmplitudePageViewWithVitals();

  return null;
};

export default Analytics;
