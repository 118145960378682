import { useCallback } from 'react';

import { POPOVER_TO_MODAL_THRESHOLD } from 'constants/ui';

import { isServer } from 'utils/isServer';

export const useModalOrPopover = () => {
  return useCallback(() => {
    if (!isServer) {
      return window.innerWidth < POPOVER_TO_MODAL_THRESHOLD;
    }
    return false;
  }, []);
};
