import React, { useEffect, useRef } from 'react';
import { useWatch } from 'react-hook-form';
import * as amplitude from '@amplitude/analytics-browser';

import { FILTER_SELECT } from 'constants/amplitude';

import useAppFormInput from 'hooks/useFormInput';

import AppInputRadio, {
  AppInputRadioProps,
} from 'components/AppInput/AppInputRadio';

type AppFormRadioProps<T> = FormInputProps<T> &
  Omit<AppInputRadioProps, 'onChange' | 'isChecked'> & { useCase?: string };

function AppFormRadio<T = Record<string, unknown>>(
  props: AppFormRadioProps<T>
): React.ReactElement {
  const value = useWatch({ name: props.name });
  const didMountRef = useRef(false);

  const dataEventLabel = value === props.value;
  const dataEventAction = `change_radio_${props.name}_${props.value}`;

  useEffect(() => {
    if (props.useCase === 'filter-select' && didMountRef.current) {
      if (value === props.value) {
        amplitude.track(FILTER_SELECT, {
          'filter name': props.name,
          'filter value': value,
        });
      }
    }

    didMountRef.current = true;
  }, [value, props.value, props.name, props.useCase]);

  return useAppFormInput<AppInputRadioProps, { isChecked: boolean }>(
    AppInputRadio,
    {
      ...props,
      dataEventCategory: 'Web Filters',
      dataEventAction,
      dataEventLabel,
    },
    () => ({
      isChecked: value === props.value,
    })
  );
}

export default AppFormRadio;
