import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';

import { useWPContext } from 'contexts/wordPress';

const HOST = process.env.NEXT_PUBLIC_SITE_BASE;

const useAuthRedirect = (
  redirect?: boolean,
  redirectUrl?: string
): { callbackUrl?: string; redirect: boolean } => {
  const router = useRouter();
  if (!redirect) {
    return { redirect: false };
  }
  const redirectBase =
    redirectUrl ||
    (Array.isArray(router.query.redirect)
      ? router.query.redirect[0]
      : router.query.redirect) ||
    '/';
  return {
    redirect: true,
    callbackUrl: redirectBase,
  };
};

export const useRelativeAuthLinks = (): {
  signInLink: string;
  signUpLink: string;
} => {
  const pathname = usePathname();

  const isWordPress = useWPContext();

  const queryString = isWordPress ? '' : `?redirect=${HOST}${pathname}`;

  return {
    signInLink: `/signin${queryString}`,
    signUpLink: `/signup${queryString}`,
  };
};

export default useAuthRedirect;
