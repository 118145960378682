import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useValidateJsError } from './useValidateJsError';

function useAppFormInput<
  P extends FormInputProps,
  A extends Record<string, unknown> = Record<string, unknown>
>(
  Component: React.FC<P>,
  props: Omit<P, 'inputRef' | 'error' | 'onChange' | 'onBlur'> & {
    validation?: Record<string, unknown>;
  },
  watchProps?: () => A
): JSX.Element {
  const { register, formState } = useFormContext();
  const { name, validation, validatejs } = props;

  const { validationObject, validateJsError } = useValidateJsError(
    name,
    validatejs || null,
    validation || {}
  );

  const { ref: inputRef, ...registerProps } = register(name, validationObject);

  const { errors } = formState || {};

  let additionalProps = {} as A;

  if (watchProps) {
    additionalProps = watchProps();
  }

  return (
    // @ts-ignore
    <Component
      inputRef={inputRef}
      error={validateJsError ? validateJsError : errors[name]?.message}
      {...props}
      {...registerProps}
      {...additionalProps}
    />
  );
}

export default useAppFormInput;
