import React from 'react';

import styles from './styles/_ProFeatureListItem.module.scss';

export interface _ProFeatureListItemProps {
  className?: string;
  title: string;
  desc: string;
}

export const _ProFeatureListItem: React.FC<_ProFeatureListItemProps> = ({
  className,
  title,
  desc,
}: _ProFeatureListItemProps) => {
  return (
    <div className={`${styles['pro-feature']} ${className}`}>
      <p className={styles['pro-feature__title']}>{title}</p>
      <p className={styles['pro-feature__desc']}>{desc}</p>
    </div>
  );
};

export default _ProFeatureListItem;
