import React from 'react';

import styles from './styles/AppDivider.module.scss';

export interface AppDividerProps {
  className?: string;
}
export const AppDivider: React.FC<AppDividerProps> = ({ className }) => {
  return <hr className={`${styles.divider} ${className}`} />;
};

export default AppDivider;
