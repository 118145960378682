import { useRef } from 'react';
import { useRouter } from 'next/router';
import type { NextRouter } from 'next/router';

export default function useRouterMethod() {
  const router = useRouter();
  const routerRef = useRef<NextRouter>(router);

  routerRef.current = router;

  const wrappedRouter = useRef<Pick<NextRouter, 'push' | 'replace'>>({
    push: (path, as, options) => routerRef.current.push(path, as, options),
    replace: (path, as, options) =>
      routerRef.current.replace(path, as, options),
  });

  return wrappedRouter.current;
}
