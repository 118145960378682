import React, { useCallback } from 'react';
import { useRouter } from 'next/router';

import { useUser } from 'contexts/currentUser';

import useCreateRecord from 'hooks/useCreateRecord';
import { useOverlay } from 'hooks/useOverlayContext';

import AppButtonLink from 'components/AppButton/AppButtonLink';
import AppForm from 'components/AppForm/AppForm';
import AppFormError from 'components/AppForm/AppFormError';
import AppFormTextArea from 'components/AppForm/AppFormTextArea';
import AppModal from 'components/AppModal/AppModal';

import _ManagerReservationsModalButton from './_ManagerReservationsModalButton';

import styles from './styles/ManagerReservationsModal.module.scss';

export interface ManagerReservationsModalProps {
  title: string;
  description: string;
  inputLabel?: string;
  placeholder?: string;
  buttonLabel?: string;
  buttonDataEvent: string;
  type: ManagerReservationModalType;
}

export const ManagerReservationsModal: React.FC<
  ManagerReservationsModalProps
> = ({
  title,
  description,
  inputLabel,
  placeholder,
  buttonLabel,
  buttonDataEvent,
  type,
}: ManagerReservationsModalProps) => {
  const { user } = useUser();

  const {
    query: { campground },
    push,
  } = useRouter();

  const overlay = useOverlay<ManagerReservationOverlay>(type);

  const { reservation } = overlay || {};

  const navigateToReservations = useCallback(
    () => push(`/manager/campgrounds/${campground}/reservations`),
    [campground, push]
  );

  const { errors: noteError, createRecord: createNoteRecord } =
    useCreateRecord();

  const { errors: approveError, createRecord: approveCreateRecord } =
    useCreateRecord();

  const { errors: declineError, createRecord: declineCreateRecord } =
    useCreateRecord();

  const onSubmit = useCallback(
    async (formState) => {
      const { note } = formState;

      if (note.trim()) {
        await createNoteRecord({
          type: 'confirmation-notes',
          body: note.trim(),
          reservationId: reservation?.id,
          userId: user?.id,
        });
      }

      if (type === 'manager-reservation-approve') {
        await approveCreateRecord(
          {
            type: 'reservation-confirmations',
            reservationId: reservation?.id,
          },
          undefined,
          navigateToReservations
        );
      } else if (type === 'manager-reservation-decline') {
        await declineCreateRecord(
          {
            type: 'reservation-declines',
            reservationId: reservation?.id,
          },
          undefined,
          navigateToReservations
        );
      }
    },
    [
      approveCreateRecord,
      createNoteRecord,
      declineCreateRecord,
      navigateToReservations,
      reservation?.id,
      type,
      user?.id,
    ]
  );

  const isReservationModification = type === 'manager-reservation-modify';

  return (
    <AppModal
      type={type}
      padded={false}
      content={
        <AppForm<{ note?: string }> onSubmit={onSubmit}>
          <>
            <div className={styles['reservations-modal']}>
              <div className={styles['reservations-modal__text']}>
                <h4 className={styles['reservations-modal__headline']}>
                  {title}
                </h4>
                <p className={styles['reservations-modal__body']}>
                  {description}
                </p>
                {!isReservationModification && (
                  <AppFormTextArea
                    className={styles['reservations-modal__text-area']}
                    name="note"
                    label={inputLabel}
                    placeholder={placeholder}
                  />
                )}
              </div>
              <AppFormError errors={approveError} />
              <AppFormError errors={declineError} />
              <AppFormError errors={noteError} />
            </div>
            <footer className={styles['reservations-modal__actions']}>
              {isReservationModification ? (
                <AppButtonLink
                  className={styles['reservations-modal__button']}
                  label="Contact Support"
                  dataEvent={buttonDataEvent}
                  path="mailto:support@thedyrt.com"
                  target="_blank"
                />
              ) : (
                !!buttonLabel && (
                  <_ManagerReservationsModalButton
                    buttonLabel={buttonLabel}
                    buttonDataEvent={buttonDataEvent}
                  />
                )
              )}
            </footer>
          </>
        </AppForm>
      }
    />
  );
};

export default ManagerReservationsModal;
