import { camelConvert } from 'utils/caseConversion';

const API = process.env.NEXT_PUBLIC_API_BASE;

export async function fetchRecommended(lat: number, lon: number) {
  const url = new URL(`${API}/autocomplete/campgrounds`);
  if (lat && lon) {
    url.searchParams.set('lat', `${lat}`);
    url.searchParams.set('lon', `${lon}`);
  }
  url.searchParams.set('recommended', '1');

  try {
    const data = await fetch(url.toString()).then((response) => {
      return response.json();
    });

    return data.map(camelConvert) as CampgroundAutocomplete[];
  } catch {
    return [] as CampgroundAutocomplete[];
  }
}

export async function search(
  query: string,
  autocompleteType: AutocompleteType = 'unified',
  searchParams: Record<string, string> = {},
  headers: Record<string, string> = {}
) {
  try {
    const url = new URL(`${API}/autocomplete/${autocompleteType}`);
    url.searchParams.set('q', query);
    for (const [paramName, paramValue] of Object.entries(searchParams)) {
      url.searchParams.set(paramName, paramValue);
    }

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
    }).then(
      (response) => response.text(),
      (error) => error.text()
    );

    if (response) {
      const data = JSON.parse(response);
      return data.map(camelConvert) as UnifiedAutocomplete[];
    }
  } catch {
    return [] as UnifiedAutocomplete[];
  }
}

export function buildValidFilter<
  F extends AppliedSearchFilter | AppliedAttributeFilter
>(filter: F) {
  return Object.entries(filter).reduce((memo, [key, value]) => {
    if (value !== null && value !== undefined && value !== '') {
      // @ts-ignore
      memo[key as keyof F] = value;
    }
    return memo;
  }, {} as F);
}

export const getIcon = (result: UnifiedAutocomplete) => {
  if (result.type === 'place') {
    switch (result.placeType) {
      case 'city':
        return 'location_pin';
      case 'admin_area':
        return 'tree';
      case 'state':
        return result.region;
      default:
        return 'location_pin';
        break;
    }
  }
  return 'pin';
};

export const roundedBbox = (map: mapboxgl.Map): BoundingBox => {
  const bounds = map.getBounds().toArray();
  const [[SWLon, SWLat], [NELon, NELat]] = bounds;

  return `${parseFloat(SWLon.toFixed(3))},${parseFloat(
    SWLat.toFixed(3)
  )},${parseFloat(NELon.toFixed(3))},${parseFloat(NELat.toFixed(3))}`;
};
