import React, { useRef, useState, useEffect } from 'react';
import {
  FieldValues,
  useFormContext,
  UseFormGetValues,
  UseFormReset,
  useWatch,
} from 'react-hook-form';
import debounce from 'lodash.debounce';

import { BOUNDING_FILTERS } from 'constants/search';

import { useSearch } from 'contexts/search';

import { buildValidFilter } from 'utils/search';

import useLoadRecords from 'hooks/useLoadRecords';

import AppButton from 'components/AppButton/AppButton';

import styles from './styles/SearchCampgrounds.module.scss';

interface Props {
  reset: UseFormReset<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
}

const _SidebarFiltersSubmit: React.FC<Props> = React.memo(({ reset }) => {
  const { loading, loadRecords } = useLoadRecords();

  const [recordsCount, setRecordsCount] = useState(0);

  const { boundingFilter, searchFilters } = useSearch();

  const values: Partial<AppliedSearchFilter> =
    useWatch() as Partial<AppliedSearchFilter>;

  const debouncedLoadRecords = useRef(
    debounce(
      async (
        boundingFilter: AppliedBoundingFilter,
        values: AppliedAttributeFilter
      ) => {
        loadRecords<LocationSearchResultModel>(
          'locations/search-results',
          {
            filter: {
              search: buildValidFilter({
                ...boundingFilter,
                ...values,
              }),
            },
          },
          undefined,
          (response) => {
            setRecordsCount(Math.min(response.meta?.recordCount || 0, 1000));
          }
        );
      },
      250
    )
  );

  useEffect(() => {
    if (boundingFilter) {
      debouncedLoadRecords.current(boundingFilter, values);
    }
  }, [boundingFilter, values]);

  return (
    <div className={styles['filters-panel__bottom-bar']}>
      <AppButton
        className={styles['filter-panel__clear']}
        label="Clear All"
        type="button"
        onClick={() => {
          reset(
            Object.values(searchFilters).reduce((memo, filter) => {
              if (
                !BOUNDING_FILTERS.includes(
                  filter.id as keyof AppliedBoundingFilter
                ) &&
                filter.id !== 'start_date' &&
                filter.id !== 'end_date' &&
                filter.id !== 'available'
              ) {
                const defaultValue = filter.filterMeta.defaultValue;

                // @ts-ignore
                memo[filter.id] = defaultValue ? defaultValue : null;
              }

              return memo;
            }, {} as AppliedAttributeFilter)
          );
        }}
        dataEvent="click_search_clear-all-filters"
        ghost
        borderless
      />
      <AppButton
        className={styles['filter-panel__view']}
        label={`View ${recordsCount} Results`}
        type="submit"
        dataEvent="click_search_clear-all-filters"
        disabled={loading ? 'loading' : undefined}
      />
    </div>
  );
});

_SidebarFiltersSubmit.displayName = '_SidebarFiltersSubmit';

// TODO remove wrapper when 49703 lands
const _SidebarFiltersSubmitWrapper = () => {
  const { reset, getValues } = useFormContext();
  return <_SidebarFiltersSubmit reset={reset} getValues={getValues} />;
};

export default _SidebarFiltersSubmitWrapper;
