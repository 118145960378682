import React, { useState } from 'react';
import Image from 'next/image';

import {
  campgroundUrl,
  campgroundCreateReviewUrl,
} from 'utils/campgroundOrLocationUrl';

import { useOverlay } from 'hooks/useOverlayContext';
import useRouterMethod from 'hooks/useRouterMethod';

import AppButtonLink from 'components/AppButton/AppButtonLink';
import AppStarRating from 'components/AppStarRating/AppStarRating';

import AppModal from './AppModal';

import styles from './styles/CampgroundAddedModal.module.scss';

const PUBLIC = process.env.NEXT_PUBLIC_ASSETS;

const CampgroundAddedModal: React.FC = () => {
  const { campground } = useOverlay<CampgroundAddedOverlay>(
    'listing-success-camper'
  );
  const [rating, setRating] = useState(0);
  const [ratingClicked, setRatingClicked] = useState(false);
  const { push } = useRouterMethod();

  const handleRatingClick = (rating: number) => {
    setRating(rating);
    setRatingClicked(true);
    push(`${campgroundCreateReviewUrl(campground)}?initialRating=${rating}`);
  };

  return (
    <AppModal
      type="listing-success-camper"
      content={
        <div className={styles['modal']}>
          <Image
            src={`${PUBLIC}/assets/images/binoculars_illus@2x.jpg`}
            width={200}
            height={185}
            alt=""
          />
          <h2 className={styles.modal__title}>Rate this location</h2>
          <AppStarRating
            rating={rating}
            onClick={handleRatingClick}
            className={styles.modal__stars}
            size="xxl"
            onMouseOver={(rating) => {
              if (!ratingClicked) setRating(rating);
            }}
            onMouseLeave={() => {
              if (!ratingClicked) setRating(0);
            }}
          />
          <p className={styles.modal__text}>
            Thank you for adding {campground.name}! Now earn points and prizes
            by leaving a review.
          </p>
        </div>
      }
      footerButtons={
        <AppButtonLink
          label="Go to Location Listing"
          path={campgroundUrl(campground)}
          dataEvent="success_modal_go_to_listing"
          fullWidth
          disabled={ratingClicked}
        />
      }
      fullScreenMobile={false}
      disableClose
    />
  );
};
export default CampgroundAddedModal;
