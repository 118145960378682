import React from 'react';

import { useOverlay, useOverlayContext } from 'hooks/useOverlayContext';

import ReportForm from './_ReportForm';

import AppModal from './AppModal';

import styles from './styles/AppModal.module.scss';

const AppReportModal: React.FC = () => {
  const overlay = useOverlay<ReportOverlay>('report');
  const { close } = useOverlayContext();

  const { subject } = overlay || {};

  return (
    <AppModal
      type="report"
      padded={false}
      className={styles['modal__report-modal']}
      content={<ReportForm subject={subject} close={close} />}
    />
  );
};

export default AppReportModal;
