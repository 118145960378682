import React from 'react';
import { useFormContext } from 'react-hook-form';

import AppButton from 'components/AppButton/AppButton';

import styles from './styles/ManagerReservationsModal.module.scss';

interface Props {
  buttonDataEvent: string;
  buttonLabel: string;
}

const _ManagerReservationsModalButton = ({
  buttonDataEvent,
  buttonLabel,
}: Props) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <AppButton
      className={styles['reservations-modal__button']}
      label={buttonLabel}
      dataEvent={buttonDataEvent}
      disabled={isSubmitting ? 'loading' : false}
      type="submit"
    />
  );
};

export default _ManagerReservationsModalButton;
